import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { User } from '../../interfaces/user';
import { baseUrl } from '../base-service';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${baseUrl}/users` }),
  endpoints: (builder) => ({
    updateUser: builder.mutation<
      void,
      Partial<User> & Pick<User, 'id'>
    >({
      query(body) {
        return {
          url: `/${body.id}`,
          method: 'PATCH',
          body: body
        };
      },
    }),
    deleteUser: builder.mutation<void, number>({
      query(id) {
        return {
          url: `/${id}`,
          method: 'DELETE',
        };
      },
    }),
    getUserById: builder.query<User, number>({
      query: (id) => `/${id}`,
    }),
    getUser: builder.query<User[], void>({
      query: (_) => ``,
    }),
  }),
});

export const {
  useUpdateUserMutation,
  useGetUserByIdQuery,
  useGetUserQuery,
  useDeleteUserMutation,
} = userApi;