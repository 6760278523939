import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog } from '../../components/dialog';
import { useAppSelector } from '../../hooks';
import { usePasswordChangeMutation } from '../../services/cognito/cognito-service';
import { PasswordChangeRequest } from '../../services/cognito/interfaces/password';
import PasswordChangeView from './password-change-view';

export default function PasswordChangeContainer() {
  const passwordStore = useAppSelector(({ password }) => password);

  const [api, { isLoading }] = usePasswordChangeMutation();
  const [open, setOpen] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const history = useHistory();

  const handleSubmit = (data: Omit<PasswordChangeRequest, 'email'>) => {
    setHasError(false);
    api({ ...data, email: passwordStore.email })
      .unwrap()
      .catch((e) => {
        console.error(e);
        setHasError(true);
      })
      .finally(() => {
        setOpen(true);
      });
  };

  const handleOk = () => {
    if (!hasError) {
      history.push('/');
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog
        variant={hasError ? 'error' : 'success'}
        open={open}
        onOk={handleOk}
      />
      <PasswordChangeView handleSubmit={handleSubmit} isLoading={isLoading} />
    </>
  );
}
