import * as yup from 'yup';
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator';
import { CrudFormModel } from '../../../components/form-v2/models/form-model';
import { Option } from '../../../interfaces/options';

export class OptionAddModel extends CrudFormModel {
  id: number | undefined;

  @FormField({
    label: 'Descrição',
    placeholder: 'Insira uma descrição',
    schema: yup.string().required(),
  })
  description: string = '';

  // @FormField({
  //   label: 'Valor',
  //   placeholder: 'Insira um valor',
  //   schema: yup.number().required(),
  // })
  value?: string | number;

  constructor(props?: Option) {
    super();
    if (!props) return;
    this.id = props.id;
    this.description = props.description;
    this.value = props.value;
  }
}
