import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AppointmentResponse } from '../../interfaces/appointment';
import { CreateDoctor, CreateDoctorRequest, Doctor, DoctorResponse } from '../../interfaces/doctor';
import { baseUrl } from '../base-service';


export const doctorApi = createApi({
  reducerPath: 'doctorApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${baseUrl}/doctors` }),
  endpoints: (builder) => ({
    createDoctor: builder.mutation<Doctor, CreateDoctorRequest>({
      query(body) {
        return {
          url: '/',
          method: 'POST',
          body: body,
        };
      },
    }),
    updateDoctor: builder.mutation<
      void,
      CreateDoctor
    >({
      query(body) {
        const request = {
          user: {
            firstName: body.firstName,
            lastName: body.lastName,
            document: body.document,
            phone: body.phone,
            email: body.email
          },
          specialtyIds: body.specialtyIds
        }
        return {
          url: `/${body.id}`,
          method: 'PATCH',
          body: request,
        };
      },
    }),
    deleteDoctor: builder.mutation<void, number>({
      query(id) {
        return {
          url: `/${id}`,
          method: 'DELETE',
        };
      },
    }),
    getDoctorById: builder.query<DoctorResponse, number>({
      query: (id) => `/${id}`,
    }),
    getDoctor: builder.query<DoctorResponse[], void>({
      query: (_) => ``,
    }),
    getDoctorScheduled: builder.query<AppointmentResponse[], number>({
      query: (id) => `${id}/scheduled`,
    }),
  }),
});

export const {
  useCreateDoctorMutation,
  useUpdateDoctorMutation,
  useGetDoctorByIdQuery,
  useGetDoctorQuery,
  useLazyGetDoctorQuery,
  useDeleteDoctorMutation,
  useGetDoctorScheduledQuery,
  useLazyGetDoctorScheduledQuery
} = doctorApi;