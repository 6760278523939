import Box from '@mui/material/Box';
import { getOptionDescriptionById } from '../../helpers/helpers';
import { useGetModalitiesQuery } from '../../services/options/options-service';
import CrudDataTableBadge from '../crud-data-table-badge/crud-data-table-badge';

export interface ModalityBadgeProps {
  modalityId: number;
}

export default function ModalityBadge(props: ModalityBadgeProps) {
  const { data } = useGetModalitiesQuery();

  const renderBadge = ({badgeColor, fontColor}: { badgeColor: string, fontColor: string }) => {
    return (
      <CrudDataTableBadge color={badgeColor} sx={{ fontSize: '12px', fontWeight: 'bold', color: `${fontColor} !important` }}>
        {data && getOptionDescriptionById(data, props.modalityId)}
      </CrudDataTableBadge>
    );
  };

  const renderColor = () => {
    const defaultBadgeColor = '#3F8BB5';
    const defaultFontColor = '#000'
    /**
      (   ) Alto suspeito (24h úteis) - e2536b
      (   ) Suspeito (3 dias) - ffff00
      (   ) Screening (30 dias) - 6608db
      (   ) Exames e laudos (2 dias) - bf5ae0
      (   ) Retorno anual screening - 1fd6ca
     */
    switch (props.modalityId) {
      case 1:
        return { badgeColor: '#77A553',fontColor: defaultFontColor};
      case 2:
        return { badgeColor: '#b9d69d',fontColor: defaultFontColor};
      case 3:
        return { badgeColor: '#aeeaff',fontColor: defaultFontColor};
      case 4:
        return { badgeColor: '#dbe073',fontColor: defaultFontColor};
      case 5:
        return { badgeColor: '#7fb5d3',fontColor: defaultFontColor};
      default:
        return { badgeColor: defaultBadgeColor,fontColor: defaultFontColor};
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}>
      {renderBadge(renderColor())}
    </Box>
  );
}
