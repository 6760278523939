import { Box, Paper, Typography } from '@mui/material';
import { Breadcrumbs } from '../breadcrumbs';
import Dialog, { DialogProps } from '../dialog/dialog';
import FormV2 from '../form-v2/form-v2';
import { CrudFormModel } from '../form-v2/models/form-model';
import { Spinner } from '../spinner';

export interface CrudFormProps {
  model?: CrudFormModel;
  title: string;
  handleSubmit: (data: any) => void;
  submitLabel?: string;
  dialogProps: DialogProps;
  isLoading: boolean;
  backPagePath?: string;
  rejectLabel?: string;
  handleReject?: (data: any) => void;
  goBack?: boolean
}

export default function CrudForm({
  model,
  handleSubmit,
  submitLabel,
  title,
  dialogProps,
  isLoading,
  backPagePath,
  goBack
}: CrudFormProps) {
  return (
    <Paper
      sx={{
        display: 'flex',
        height: 'calc(100% - 68px)',
        padding: '26px 46px',
        flexDirection: 'column',
        overflow: 'auto',
      }}>
      {isLoading && <Spinner />}
      <Dialog {...dialogProps} />
      <Breadcrumbs
        goBack={goBack}
        items={[
          {
            label: 'Voltar',
            link: backPagePath,
          },
        ]}
      />
      <Typography
        variant='h4'
        component={Box}
        sx={{ pt: 2, color: (theme) => theme.palette.primary.main }}>
        <b>{title}</b>
      </Typography>
      {model && (
        <FormV2
          model={model}
          submit={handleSubmit}
          submitLabel={submitLabel ?? 'Salvar'}
        />
      )}
    </Paper>
  );
}
