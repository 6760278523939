import { FormField } from "../../../components/form-v2/decorators/form-field-decorator";
import { FormModel } from "../../../components/form-v2/models/form-model";
import * as yup from 'yup';
import { PasswordResetRequest } from "../../../services/cognito/interfaces/password";

export class PasswordResetModel extends FormModel implements Partial<PasswordResetRequest> {

  @FormField({
    label: 'Email',
    placeholder: 'Email',
    schema: yup.string().email().required(),
    type: 'email',
  })
  public email: string | undefined;
}
