import { Route, Switch } from 'react-router-dom';
import { routes } from './data/routes';
import IRoute from './interfaces/route';

export const publicRoutes = routes.filter((x) => x.isPublic);

export function PublicRoutes() {
  const mapWithKeys = (routes: IRoute[]) =>
    routes.map((item, index) => {
      return <Route {...item.routeProps} key={index} />;
    });

  return (
    <Switch>
      {mapWithKeys(publicRoutes)}
      {/* <Redirect from={'**'} to={'/'} /> */}
    </Switch>
  );
}
