import * as yup from 'yup';
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator';
import { CrudFormModel } from '../../../components/form-v2/models/form-model';
import { useClinicDoctors, useDoctorOptions } from '../../../hooks/options/use-doctor-options';
import { useGetDoctorsByProfileQuery } from '../../../hooks/use-get-doctors';
import { AppointmentRequest } from '../../../interfaces/appointment';
import { Patient } from '../../../interfaces/patient';
import { useGetAppointmentTypesQuery, useGetModalitiesQuery, useGetSpecialtiesQuery, useGetCidsQuery, useGetExamsQuery } from '../../../services/options/options-service';

export class ConsultAddModel extends CrudFormModel implements Readonly<AppointmentRequest>{
  id: number | undefined;

  @FormField({
    label: 'Paciente',
    placeholder: 'Selecione o paciente',
    schema: yup.string().required(),
    disabled: true
  })
  patientName: string = ''

  patient: number = 0;

  @FormField({
    label: 'Modalidade',
    placeholder: 'Seleciona uma modalidade',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetModalitiesQuery()

      return { data: data ?? [], isLoading, isError }
    },
    schema: yup.number().required(),
    type: 'select',
  })
  modality: number = 1

  @FormField({
    label: 'Tipo',
    placeholder: 'Seleciona um tipo',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetAppointmentTypesQuery()

      return { data: data ?? [], isLoading, isError }
    },
    schema: yup.number().required(),
    type: 'select',
  })
  type: number = 1

  @FormField({
    label: 'Cid',
    placeholder: 'Seleciona o Cid',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetCidsQuery()

      return { data: data ?? [], isLoading, isError }
    },
    schema: yup.number().required(),
    type: 'select',
  })
  cid: number = 1

  @FormField({
    label: 'Especialidades',
    placeholder: 'Selecione as especialidades',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetSpecialtiesQuery()

      return { data: data ?? [], isLoading, isError }
    },
    type: 'select-multiple',
    hidden: (formValues: any) => {
      if (!formValues) return false
      return formValues.type === 2
    }
  })
  specialties: number[] = [];

  @FormField({
    label: 'Exames',
    placeholder: 'Selecione o exame',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetExamsQuery()

      return { data: data ?? [], isLoading, isError }
    },
    type: 'select-multiple',
    hidden: (formValues: any) => {
      if (!formValues) return false
      return formValues.type !== 2
    }
  })
  exams: number[] = [];

  user: number

  @FormField({
    label: 'Médico',
    placeholder: 'Selecione um médico para encaminhamento',
    schema: yup.number().required(),
    type: 'select',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetDoctorsByProfileQuery()

      const toOptions = data?.map(x => ({ id: x.id, value: x.id, description: `${x.user.firstName} ${x.user.lastName} - ${x.user.document}` })) ?? []
      return { data: toOptions, isLoading, isError }
    },
    hidden: (formValues: any) => {
      if (!formValues) return false
      return formValues.type !== 3
    }
  })
  doctor: number = 0

  constructor(user: number, patient?: Patient, props?: AppointmentRequest) {
    super()
    this.user = user

    if (!patient) return
    this.patient = patient.id!
    this.patientName = patient.firstName! + ` ` + patient.lastName!
    if (!props) return
  }
}

