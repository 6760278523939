import moment from "moment";

export function getDateFromISOorDate(value: string | Date) {
  let formattedValue: Date;
  if (typeof value == 'string') {
    formattedValue = moment(value).toDate();
  } else {
    formattedValue = value;
  }

  return formattedValue
}