import React from 'react';
import { CrudBehaviorRouteProps } from '../../components/crud-behavior/crud-behavior';
import {
  useCreateExamMutation,
  useDeleteExamMutation,
  useLazyGetExamsQuery,
  useLazyGetExamByIdQuery,
  useUpdateExamMutation,
} from '../../services/options/options-service';
import { OptionsPage } from '../options';
import { OptionsProvider } from '../options/context/OptionsContext';

export default function (props: CrudBehaviorRouteProps) {
const [fetch, fetchState] = useLazyGetExamsQuery();
  const [fetchById, fetchByIdState] = useLazyGetExamByIdQuery();
  const [deleteApi, deleteState] = useDeleteExamMutation();
  const [update, updateState] = useUpdateExamMutation();
  const [add, addState] = useCreateExamMutation();

  

  return (
    <>
      <OptionsProvider
        settings={{
          title: 'Gestão de Exames',
          backPath: '/exams-types',
          fetch: () => fetch(),
          fetchState: fetchState,
          fetchById: (id) => fetchById(id),
          fetchByIdState: fetchByIdState,
          deleteApi: (id) => deleteApi(id),
          deleteState: deleteState,
          add: (payload) => add(payload),
          addState: addState,
          update: (payload) => update(payload),
          updateState: updateState,
        }}>
        <OptionsPage {...props} />
      </OptionsProvider>
    </>
  );
}
