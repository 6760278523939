import { useEffect, useState } from 'react';
import { CrudBehaviorRouteProps } from '../../../../components/crud-behavior/crud-behavior';
import { DialogProps } from '../../../../components/dialog/dialog';
import history from '../../../../components/routes/history';
import { useAppSelector } from '../../../../hooks';
import { useGetConsultByIdQuery } from '../../../../services/consult/consult-service';
import { useLazyGetPatientByIdQuery } from '../../../../services/patient/patient-service';
import { HistoryEditModel } from '../../model/history-edit-model';
import HistoryFormView from '../history-form-view/history-form-view';

function HistoryEditFormContainer(props: CrudBehaviorRouteProps) {
  const userId = useAppSelector(({ auth }) => auth.userId);
  const [patientsApi, { isLoading: patientIsLoading, data: patientData }] =
    useLazyGetPatientByIdQuery();

  const {
    data: fetchData,
    isLoading: fetchIsLoading,
    isError: fetchIsError,
    refetch,
  } = useGetConsultByIdQuery(Number(props.match.params.id));

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });
  const [model, setModel] = useState<HistoryEditModel | undefined>(undefined);

  useEffect(() => { 
    refetch()
  }, [])

  useEffect(() => {
    if (!fetchIsError) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: 'error',
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [fetchIsError]);

  useEffect(() => {
    if (fetchData) {
      patientsApi(fetchData.patientId);
    }
  }, [fetchData, userId, patientsApi]);

  useEffect(() => {
    if (patientData) {
      setModel(new HistoryEditModel(userId ?? 0, patientData, fetchData));
    }
  }, [patientData, patientIsLoading, userId, fetchData]);

  return (
    <>
      <HistoryFormView
        isLoading={fetchIsLoading}
        dialogProps={dialogProps}
        title={`Detalhes do agendamento`}
        model={model}
        handleSubmit={() => {
          history.push('/history');
        }}
        submitLabel='Voltar'
      />
    </>
  );
}

export default HistoryEditFormContainer;
