import React from 'react';
import FormV2 from '../../components/form-v2/form-v2';
import OnboardingScaffold from '../../components/onboarding-scaffold/onboarding-scaffold';
import { SpacerV } from '../../components/spacer/spacer';
import { PasswordChangeRequest } from '../../services/cognito/interfaces/password';
import { PasswordChangeModel } from './model/password-change-model';

interface PasswordChangeProps {
  handleSubmit: (value: PasswordChangeRequest) => void;
  isLoading: boolean;
}

const PasswordChangeView: React.FunctionComponent<PasswordChangeProps> = ({
  handleSubmit,
  isLoading,
}) => {
  return (
    <OnboardingScaffold
      title={<b>Configure uma nova senha</b>}
      isLoading={isLoading}
      subtitle={
        'Sua senha precisa ter pelo menos 8 caracteres entre letras e números'
      }>
      <SpacerV size={10} />

      <FormV2 submit={handleSubmit} model={new PasswordChangeModel()}></FormV2>
    </OnboardingScaffold>
  );
};

export default PasswordChangeView;
