import { QueryStatus } from '@reduxjs/toolkit/dist/query/react';
import moment from 'moment-timezone';
import { AppointmentResponse, AppointmentWithRemainingTime } from '../interfaces/appointment';
import { Option } from '../interfaces/options';
import { WithUser } from '../interfaces/user';
import * as _ from 'lodash';

export const defaultTimeZone = 'America/Sao_Paulo';

export const setLocalStorage = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value));
export const getLocalStorage = <T>(key: string): T => (localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)!) : undefined);

const escapeRegExp = (value: string): string => value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const filterArrayByTerm = (data: any[], value: string) => {
  if (!data) return [];
  if (!value) return data;
  const searchRegex = new RegExp(escapeRegExp(value.toLocaleLowerCase()), 'i');
  const filteredRows = data.filter((row: any) => {
    return Object.keys(row).some((field: any) => {
      return searchRegex.test(row[field]?.toString().toLowerCase());
    });
  });
  return filteredRows;
};

export const extractIdFromPath = (pathName: string) => {
  const args = pathName.split('/');

  const lastPathArg = args[args.length - 1];
  const isNotNumber = isNaN(+lastPathArg);
  const isNotNew = lastPathArg !== 'new';
  if (isNotNumber && isNotNew) return '';

  return args[args.length - 1];
};

export const isQueryCompleted = (queryStatus: QueryStatus) => {
  return queryStatus === QueryStatus.fulfilled || queryStatus === QueryStatus.rejected;
};

export function getOptionDescriptionById(values: Option[], id: number) {
  return values.find((el) => el.id === id)?.description || '';
}

export function getOptionIdByValue(values: Option[], value: string) {
  return values.find((el) => el.value === value)?.id || 0;
}

export function getOptionById(values: Option[], id: number) {
  return values.find((el) => el.id === id);
}

export function userToPlainProps(obj: WithUser) {
  const { user, ...objData } = obj;
  return { ...user, ...objData };
}

export function fullName(obj: { firstName: string; lastName: string } | undefined) {
  if (!obj) return '';
  return `${obj.firstName} ${obj.lastName}`;
}

export function flattenObject(ob: any) {
  return Object.keys(ob).reduce(function (toReturn: any, k) {
    if (Object.prototype.toString.call(ob[k]) === '[object Date]') {
      toReturn[k] = ob[k].toString();
    } else if (typeof ob[k] === 'object' && ob[k]) {
      var flatObject = flattenObject(ob[k]);
      Object.keys(flatObject).forEach(function (k2) {
        toReturn[k + '.' + k2] = flatObject[k2];
      });
    } else {
      toReturn[k] = ob[k];
    }

    return Object.keys(toReturn).map(function (_) {
      return toReturn[_];
    });
  }, {});
}

export function formattedDateTime(isoDateString: string): string {
  return moment(isoDateString).tz(defaultTimeZone).format('DD/MM/yyyy [às] HH:mm');
}

export function getRemainingTime(row: any) {
  const deadlineDate = moment(row.createdAt).add(row.modality.value, 'day');

  return deadlineDate.diff(moment());
}

export function orderByModalityThenByRequestDate(response: AppointmentResponse[]): AppointmentWithRemainingTime[] {
  if (!response) return [];

  const mapped = response.map((x) => {
    const remaningDiff = getRemainingTime(x);
    const duration = moment.duration(remaningDiff) as any;

    const remainingTimeLabel = duration.format('DD[d] hh[h]');
    return {
      ...x,
      remainingTime: getRemainingTime(x),
      remainingTimeLabel,
    };
  });
  return _.orderBy(mapped, ['rema', 'typeId', 'modality.value'], ['asc', 'desc', 'asc']);
  // return _.orderBy(mapped, ['remainingTime', 'typeId'], ['asc', 'desc'])
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function removeEmptyProps(any: any) {
  let ret = {} as any;
  Object.keys(any).forEach((key: string) => {
    if (any[key]) {
      ret[key] = any[key];
    }
  });
  return ret;
}
