import { useMemo, useState } from "react";
import { useAuth } from "../hooks";
import { useLazyGetClinicDoctorsQuery } from "../services/clinic/clinic-service";
import { useLazyGetDoctorQuery } from "../services/doctor/doctor-service";
import { UserProfile } from "../store/auth";

export function useGetDoctorsByProfileQuery() {
  const [auth] = useAuth()
  const [clinicApi, clinicQuery] = useLazyGetClinicDoctorsQuery()
  const [adminApi, adminQuery] = useLazyGetDoctorQuery()
  const [needRefetch, setNeedRefetch] = useState(false)


  const memoizedCallback = useMemo(() => {
    if (auth.profile === UserProfile.CLINIC || auth.profile === UserProfile.DOCTOR) {
      if (clinicQuery.isUninitialized || needRefetch) {
        clinicApi(auth.clinic!)
        setNeedRefetch(false)
      }
      return clinicQuery
    }

    if (adminQuery.isUninitialized || needRefetch) {
      adminApi()
      setNeedRefetch(false)
    }
    return adminQuery
  }, [auth.profile, clinicQuery, adminQuery, needRefetch])


  const refetch = () => [
    setNeedRefetch(true)
  ]

  return { ...memoizedCallback, refetch }
}