import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import { usePassword } from "./use-password";

export default function Password(props: TextFieldProps) {

  const { showPassword, togglePassword } = usePassword();

  return <TextField  {...props}
    margin='normal'
    type={showPassword ? 'text' : 'password'}
    InputProps={{
      endAdornment: (
        <InputAdornment position='end'>
          <IconButton
            aria-label='toggle password visibility'
            onClick={() => togglePassword(!showPassword)}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
    }} />
}