import { FormField } from "../../../components/form-v2/decorators/form-field-decorator";
import { FormModel } from "../../../components/form-v2/models/form-model";
import * as yup from 'yup';
import { PasswordChangeRequest } from "../../../services/cognito/interfaces/password";

let passwordValue: string | undefined = ''

export class PasswordChangeModel extends FormModel implements Partial<PasswordChangeRequest> {
  @FormField({
    label: 'Nova Senha',
    placeholder: 'insira a nova senha',
    schema: yup.string().required().min(8).test('password', 'As senhas devem ser iguais', function (value) {
      passwordValue = value
      return !!value
    }),
    type: 'password',
  })
  public password: string | undefined;

  @FormField({
    label: 'Confirmar Senha',
    placeholder: 'insira novamente a nova senha',
    schema: yup.string().test('passwordConfirmation', 'As senhas devem ser iguais', function (value) {
      return passwordValue === value
    }),
    type: 'password',
  })
  public passwordConfirmation: string | undefined;

  @FormField({
    label: 'Código de segurança',
    placeholder: 'Insira o código de segurança recebido no seu email',
    schema: yup.string().required().min(6),
    mask: '999999',
    type: 'text',
  })
  public confirmationCode: string | undefined;
}
