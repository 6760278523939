import { GridRenderCellParams } from '@mui/x-data-grid';
import { CrudDataTable } from '../../../../components/crud-data-table';
import Dialog from '../../../../components/dialog/dialog';
import { CrudPageView, TypedGridColumns } from '../../../../interfaces/crud';
import { AppointmentResponse as Consult, AppointmentWithRemainingTime } from '../../../../interfaces/appointment';
import { ModalityBadge } from '../../../../components/modality-badge';
import { AppointmentTypeBadge } from '../../../../components/appointment-type-badge';
require('moment-duration-format');

export default function ConsultCrudPageView({
  isLoading,
  data,
  onDeleteIconClick,
  onEditIconClick,
  onRowClick,
  showDialog,
  dialogVariant,
  onCloseDialog,
  isLab,
  pageTitle,
  showActions,
}: CrudPageView<Consult> & { isLab: boolean; pageTitle: string }) {
  const columns: TypedGridColumns<AppointmentWithRemainingTime>[] = [
    {
      field: 'patientId',
      headerName: 'Nome',
      flex: 1,
      renderCell: ({ row }) => {
        return `${row.patient?.user?.firstName} ${row.patient?.user?.lastName}`;
      },
    },
    {
      field: isLab ? 'examId' : 'specialtyId',
      headerName: isLab ? 'Exame' : 'Especialidade',
      flex: 1,
      renderCell: ({ row }) => {
        const value = row.exam?.description ?? row.specialty?.description;
        return value ?? '';
      },
    },
    {
      field: 'remainingTimeLabel',
      headerName: 'Tempo (Dia/Hora)',
      flex: 1,
      renderCell: ({ row }) => {
        return <>{row.remainingTimeLabel}</>;
      },
    },
    {
      field: 'cidId',
      headerName: 'Cid',
      flex: 1,
      renderCell: ({ row }) => row.cid?.description ?? '',
    },
    {
      field: 'typeId',
      headerName: 'Origem',
      flex: 1,
      renderCell: (cell) => <AppointmentTypeBadge appointmentTypeId={cell.row.typeId} />,
    },
    {
      field: 'modalityId',
      headerName: 'Urgência',
      flex: 1,
      renderCell: (cell: GridRenderCellParams) => {
        return <ModalityBadge modalityId={cell.row.modalityId} />;
      },
    },
  ];

  return (
    <>
      <Dialog open={showDialog} variant={dialogVariant} onClose={onCloseDialog} />
      <CrudDataTable
        title={pageTitle}
        data={data}
        isLoading={isLoading}
        columns={columns}
        pageSize={15}
        handleRowClick={onRowClick}
        handleDeleteIconClick={onDeleteIconClick}
        handleEditIconClick={onEditIconClick}
        hideAddBtn={true}
        showActions={showActions}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'modalityId',
                sort: 'asc',
              },
            ],
          },
        }}
      />
    </>
  );
}
