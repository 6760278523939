import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router';

export interface CardActionProps {
  imageSrc: string;
  title: string;
  subtitle: string;
  link: string;
}

export default function CardAction(props: CardActionProps) {
  const history = useHistory();

  const { title, subtitle, link, imageSrc } = props;

  const handleItemClick = () => {
    history.push(link);
  };

  return (
    <Box
      onClick={() => handleItemClick()}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        cursor: 'pointer',
      }}>
      <Paper
        elevation={2}
        sx={{
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'center',
          p: 3,
          minHeight: '210px',
        }}>
        <img src={imageSrc} alt='text' width={200} />
      </Paper>
      <Box
        sx={{
          my: 2,
          textAlign: 'center',
        }}>
        <Typography variant='h5' color='primary' sx={{ mb: 1 }}>
          {title}
        </Typography>
        <Typography
          variant='body2'
          sx={{ color: (theme) => theme.palette.grey[600] }}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}
