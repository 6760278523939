import { withCrudBehavior } from '../../components/crud-behavior/crud-behavior';
import { ConsultAddForm } from './components/consult-add-form';
import { ConsultEditForm } from './components/consult-edit-form';
import { ConsultList } from './components/consult-list';

const ConsultPage = withCrudBehavior({
  entityCreateFormComponent: ConsultAddForm,
  entityEditFormComponent: ConsultEditForm,
  entityListComponent: ConsultList,
});

export default ConsultPage;
