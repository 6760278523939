import { Grid, GridSize } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import './form-validators';

export type SetFormState = (fieldName: string, value: string) => void;

export interface IFormField {
  renderFunc: (key: string, setFormState: SetFormState) => any;
}

interface FormProps<T> {
  formName: string;
  fields: IFormField[];
  submit: (form: T) => void;
  submitButtonText?: string;
  colSize?: GridSize;
}

export default function Form<T>(props: FormProps<T>) {
  const { formName, fields, submit, submitButtonText, colSize } = props;
  const [formData, setFormData] = useState<T>({} as T);

  const handleSubmit = (
    event: React.FormEvent<HTMLFormElement> | React.FormEvent
  ) => {
    event.preventDefault();
    submit(formData);
  };

  const handleFieldChange: SetFormState = (
    fieldName: string,
    value: string
  ) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} name={formName}>
      <Grid container>
        {fields.map((field, i) => (
          <Grid item xs={colSize || 12} key={i}>
            {field.renderFunc(`${i}`, handleFieldChange)}
          </Grid>
        ))}
      </Grid>

      <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2 }}>
        {submitButtonText || 'Enviar'}
      </Button>
    </ValidatorForm>
  );
}
