import React from 'react';
import { FormV2 } from '../../components/form-v2';
import { OnboardingScaffold } from '../../components/onboarding-scaffold';
import { SpacerV } from '../../components/spacer/spacer';
import { PasswordResetRequest } from '../../services/cognito/interfaces/password';
import { PasswordResetModel } from './model/password-reset-model';

interface PasswordResetProps {
  handleSubmit: (value: PasswordResetRequest) => void;
  isLoading: boolean;
}

export default function PasswordResetView({
  isLoading,
  handleSubmit,
}: PasswordResetProps) {
  return (
    <OnboardingScaffold
      title={<b>Recuperar senha</b>}
      isLoading={isLoading}
      subtitle={
        'Insira seu endereço de email e dentro de alguns instantes você receberá instruções para recuperação de senha. Por motivos de segurança, nós não enviamos sua senha por email. Você será redirecionado(a) para configurar uma nova senha.'
      }
      breadcrumb={[
        {
          label: 'Login',
          link: '/',
        },
      ]}>
      <SpacerV size={4} />

      <FormV2
        model={new PasswordResetModel()}
        submitLabel='Recuperar senha'
        submit={handleSubmit}
      />
    </OnboardingScaffold>
  );
}
