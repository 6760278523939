import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { Inactive } from '../../pages/inactive';
import { inactivePath } from '../../store/auth';
import { PrivateRoute } from '../private-route';
import { PrivateRouteProps } from '../private-route/private-route';
import { routes } from './data/routes';
import IRoute from './interfaces/route';

export function DashboardRoutes() {
  const [auth] = useAuth();

  const mapWithKeys = (routes: IRoute[]) => {
    return routes
      .filter((x) => {
        const hasAccess = x.accessControl
          ? x.accessControl(auth.profile!)
          : true;
        return !x.isPublic && hasAccess;
      })
      .map((item, index) => {
        return (
          <PrivateRoute
            {...(item.routeProps as PrivateRouteProps)}
            key={index}
          />
        );
      });
  };

  return (
    <Switch>
      {mapWithKeys(routes)}
      <Route exact path={inactivePath} component={Inactive} />
      <Redirect from={'**'} to={'/home'} />
    </Switch>
  );
}
