import * as yup from 'yup';
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator';
import { CrudFormModel } from '../../../components/form-v2/models/form-model';
import { AppointmentEditResquest, AppointmentResponse } from '../../../interfaces/appointment';
import { Patient } from '../../../interfaces/patient';
import {
  useGetAppointmentTypesQuery,
  useGetCidsQuery,
  useGetExamsQuery,
  useGetModalitiesQuery,
  useGetSpecialtiesQuery,
} from '../../../services/options/options-service';
import { useGetDoctorsByProfileQuery } from '../../../hooks/use-get-doctors';
import { ProcedureStatus, StatusLabelMap } from './scheduled.interfaces';
import { Option } from '../../../interfaces/options';

export class ScheduledEditModel extends CrudFormModel implements AppointmentEditResquest {
  id: number | undefined;

  @FormField({
    label: 'Paciente',
    placeholder: 'Selecione o paciente',
    schema: yup.string().required(),
    disabled: true,
  })
  patientName: string = '';

  patient: number = 0;

  @FormField({
    label: 'Modalidade',
    placeholder: 'Seleciona uma modalidade',
    disabled: true,
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetModalitiesQuery();

      return { data: data ?? [], isLoading, isError };
    },
    schema: yup.number().required(),
    type: 'select',
  })
  modality: number = 1;

  @FormField({
    label: 'Tipo',
    placeholder: 'Seleciona um tipo',
    disabled: true,
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetAppointmentTypesQuery();

      return { data: data ?? [], isLoading, isError };
    },
    schema: yup.number().required(),
    type: 'select',
  })
  type: number = 1;

  @FormField({
    label: 'Cid',
    placeholder: 'Seleciona o Cid',
    disabled: true,
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetCidsQuery();

      return { data: data ?? [], isLoading, isError };
    },
    schema: yup.number().required(),
    type: 'select',
  })
  cid: number = 1;

  @FormField({
    label: 'Exames',
    placeholder: 'Selecione o exame',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetExamsQuery();

      return { data: data ?? [], isLoading, isError };
    },
    type: 'select',
    disabled: true,
    hidden: (formValues: any) => {
      if (!formValues) return false;
      return formValues.type !== 2;
    },
  })
  exam: number = 0;

  @FormField({
    label: 'Especialidades',
    placeholder: 'Selecione as especialidades',
    disabled: true,
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetSpecialtiesQuery();

      return { data: data ?? [], isLoading, isError };
    },
    type: 'select',
    hidden: (formValues: any) => {
      if (!formValues) return false;
      return formValues.type === 2;
    },
  })
  specialty: number = 0;

  user: number;

  @FormField({
    label: 'Clinica',
    placeholder: 'Clinica de atendimento',
    disabled: true,
    hidden: (formValues: any) => {
      if (!formValues) return false;

      console.log('formValues', formValues, !!formValues.clinicName);
      return !formValues.clinicName;
    },
  })
  clinicName: string | undefined;

  @FormField({
    label: 'Médico',
    placeholder: 'Selecione um médico para atendimento',
    schema: yup.number().required(),
    type: 'select',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetDoctorsByProfileQuery();

      const toOptions =
        data?.map((x) => ({ id: x.id, value: x.id, description: `${x.user.firstName} ${x.user.lastName} - ${x.user.document}` })) ?? [];
      return { data: toOptions, isLoading, isError };
    },
    disabled: (formValues: any) => {
      if (!formValues) return false;
      // quando for encaminhamento a opçao é travada
      return formValues.type === 3;
    },
    hidden: (formValues: any) => {
      if (!formValues) return false;
      return formValues.type === 2; //** exame */
    },
  })
  doctor: number;

  @FormField({
    label: 'Laboratório',
    placeholder: 'Laboratório de atendimento',
    disabled: true,
    hidden: (formValues: any) => {
      if (!formValues) return false;
      return !formValues.labName;
    },
  })
  labName: string | undefined;

  @FormField({
    label: 'Data',
    placeholder: 'Insira a data e hora de atentimento',
    schema: yup.string().required(),
    type: 'date-time',
  })
  date: string;

  laboratory: number | undefined;

  @FormField({
    label: 'Status',
    placeholder: 'Insira o status do agendamento',
    schema: yup.number().required(),
    type: 'select',
    options: getOptions([ProcedureStatus.Completed, ProcedureStatus.Canceled]),
  })
  completed: boolean | number;

  @FormField({
    label: 'Liberação do laudo',
    placeholder: 'Insira a data de liberação do laudo',
    schema: yup.string().required(),
    type: 'date-time',
    hidden: (formValues: any) => {
      if (!formValues) return false;
      return !(formValues.completed === ProcedureStatus.Completed && formValues.type === 2); // só aparece quando o status for concluido e tipo exam (2)
    },
  })
  releaseDate: string;

  // precisa passar essa prop para enviar corretamente o sms
  scheduled: boolean = true;

  @FormField({
    label: 'Cid2',
    placeholder: 'Selecione o novo Cid',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetCidsQuery();

      return { data: data ?? [], isLoading, isError };
    },
    schema: yup.number().required(),
    type: 'select',
  })
  cid2: number = 1;

  constructor(user: number, patient: Patient, props?: AppointmentResponse) {
    super();
    this.user = user;
    // patient data
    this.patient = patient.id!;
    this.patientName = patient.firstName! + ` ` + patient.lastName!;

    // appointment data
    this.id = props?.id ?? 0;
    this.specialty = props?.specialtyId ?? 0;
    this.cid = props?.cidId ?? 0;
    this.type = props?.typeId ?? 0;
    this.modality = props?.modalityId ?? 0;
    this.doctor = props?.doctorId ?? 0 ?? 0;
    this.date = props?.date ?? '';
    this.releaseDate = props?.releaseDate ?? '';
    this.exam = props?.examId ?? 0;
    this.laboratory = props?.laboratoryId ?? 0;
    this.completed = props?.completed ?? false ? 1 : 0;
    this.clinicName = props?.doctor?.clinics && props?.doctor?.clinics?.length ? props.doctor.clinics[0].user.firstName : undefined;
    this.labName = props?.laboratory?.user?.firstName;
  }
}

function getOptions(procedureStatus: ProcedureStatus[]): Option[] {
  return procedureStatus.map((procedure) => ({
    id: procedure,
    value: procedure,
    description: StatusLabelMap[procedure],
  }));
}
