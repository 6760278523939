import { useAuth } from '../../hooks';
import { useEffect } from 'react';
import { clearAuth } from '../../store/auth';
// import { useLogoutMutation } from '../../services/cognito/cognito-service';

export default function Signout() {
  const dispatch = useAuth()[1];

  // const [api] = useLogoutMutation();

  useEffect(() => {
    // api()
    dispatch(clearAuth());
  }, [dispatch]);

  return <>Logging out...</>;
}
