import { QueryStatus } from '@reduxjs/toolkit/dist/query/react';
import { useEffect, useState } from 'react';
import { DialogVariantType } from '../../../../components/dialog/dialog';
import { useAuth } from '../../../../hooks';
import { useGetDoctorsByProfileQuery } from '../../../../hooks/use-get-doctors';
import { useDeleteDoctorMutation } from '../../../../services/doctor/doctor-service';
import DoctorCrudPageView from './doctor-list';

export default function DoctorListContainer() {
  const [auth] = useAuth();
  const getQueryState = useGetDoctorsByProfileQuery();

  const [deleteApi, deleteMutationState] = useDeleteDoctorMutation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogVariant, setDialogVariant] =
    useState<DialogVariantType>('success');

  const isMutationCompleted =
    deleteMutationState.status === QueryStatus.fulfilled ||
    deleteMutationState.status === QueryStatus.rejected;

  useEffect(() => {
    getQueryState.refetch();
  }, []);

  useEffect(() => {
    if (isMutationCompleted) {
      setDialogVariant(deleteMutationState.isSuccess ? 'success' : 'error');
      setShowDialog(true);

      if (deleteMutationState.error) {
        console.error(
          'endpointName',
          deleteMutationState.endpointName,
          'originalArgs',
          deleteMutationState.originalArgs,
          '',
          deleteMutationState.error
        );
      }
    }
  }, [
    isMutationCompleted,
    deleteMutationState.isSuccess,
    deleteMutationState.error,
    deleteMutationState.endpointName,
    deleteMutationState.originalArgs,
  ]);

  const isLoading = () =>
    getQueryState.isLoading || deleteMutationState.isLoading;

  const onDelete = (id: number) => {
    deleteApi(id).then(() => {
      getQueryState.refetch();
    });
  };

  return (
    <DoctorCrudPageView
      onCloseDialog={() => setShowDialog(false)}
      showDialog={showDialog}
      dialogVariant={dialogVariant}
      isLoading={isLoading()}
      data={getQueryState.data ?? []}
      onDeleteIconClick={onDelete}
    />
  );
}
