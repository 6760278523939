import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AppointmentResponse } from '../../interfaces/appointment';
import { Patient, PatientResponse } from '../../interfaces/patient';
import { baseUrl } from '../base-service';

type LaboratoryId = number

export const laboratoryApi = createApi({
  reducerPath: 'laboratoryApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${baseUrl}/lab` }),
  endpoints: (builder) => ({
    getLaboratoryPatients: builder.query<Patient[], LaboratoryId>({
      query: (id) => `/${id}/patients`,
      transformResponse: (response: PatientResponse[]) => {
        const mapped = response.map(x => {
          const { user, doctor, ...otherData } = x
          return {
            ...user,
            ...otherData,
            doctorId: doctor?.id
          } as Patient
        })
        return mapped
      }
    }),
    getLaboratoryAppointments: builder.query<AppointmentResponse[], { labId: number, scheduled?: boolean }>({
      query: ({ labId, scheduled }) => ({ url: `/${labId}/appointments`, params: { scheduled: scheduled ?? false } }),
    }),
  }),
});


export const {
  useGetLaboratoryAppointmentsQuery,
  useGetLaboratoryPatientsQuery,
  useLazyGetLaboratoryPatientsQuery,
  useLazyGetLaboratoryAppointmentsQuery
} = laboratoryApi;
