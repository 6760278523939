import { withCrudBehavior } from '../../components/crud-behavior/crud-behavior';
import { AccessEditForm } from './components/access-edit-form';
import { AccessList } from './components/access-list';

export const accessPageTitle = 'Acessos';

const AccessPage = withCrudBehavior({
  entityEditFormComponent: AccessEditForm,
  entityListComponent: AccessList,
});

export default AccessPage;
