import { DataGrid, DataGridProps, GridColumns, GridRowData } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { Pagination } from './components/pagination';
import { QuickSearchToolbar } from './components/quick-search-toolbar';
import Box from '@mui/material/Box';
import { Button, IconButton } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { QuickSearchToolbarProps } from './components/quick-search-toolbar/quick-search-toolbar';
import { NoRowsOverlay } from './components/no-rows-overlay';
import { Dialog } from '../dialog';
import { useCrudDataTable } from './crud-data-table-hooks';
import { ReactNode } from 'react';
import { Breadcrumbs } from '../breadcrumbs';
import { BreadcrumbItem } from '../breadcrumbs/breadcrumbs';

type CrudDataTableProps = {
  title: string;
  data: GridRowData[];
  columns: GridColumns;
  pageSize: number;
  showActions?: boolean;
  isLoading: boolean;
  handleRowClick?: (row: GridRowData) => void;
  handleEditIconClick?: (row: GridRowData) => void;
  handleDeleteIconClick?: (id: number) => void;
  handleAddBtnClick?: () => void;
  hideAddBtn?: boolean;
  customActions?: ((row: GridRowData) => ReactNode)[];
  breadcrumbs?: BreadcrumbItem[];
  hideRemoveBtn?: boolean;
} & Omit<DataGridProps, 'rows'>;

let tempRow: GridRowData = {};

export default function CrudDataTable(props: CrudDataTableProps) {
  const {
    data,
    pageSize,
    title,
    isLoading,
    handleRowClick,
    handleEditIconClick,
    handleDeleteIconClick,
    hideAddBtn,
    customActions,
    breadcrumbs,
    ...rest
  } = props;

  const { styles, history, searchText, setSearchText, rows, showDialog, setShowDialog } = useCrudDataTable(data);

  const columns = appendActions(props.columns);

  const onRowClick = (row: GridRowData) => {
    if (handleRowClick) return handleRowClick(row);
  };

  const onDelete = () => {
    if (handleDeleteIconClick) handleDeleteIconClick(tempRow.id);
    tempRow = {};
  };

  const onDeleteIconClick = (row: GridRowData) => {
    tempRow = row;
    setShowDialog(true);
  };

  const onEditIconClick = (row: GridRowData) => {
    if (handleEditIconClick) return handleEditIconClick(row);

    history.push(`${history.location.pathname}/${row.id}`);
  };

  const onAddClick = () => {
    history.push(`${history.location.pathname}/new`);
  };

  function appendActions(columns: GridColumns) {
    return [
      ...columns,
      {
        field: 'actions',
        headerName: '',
        description: 'actions',
        sortable: false,
        width: 130,
        renderCell: (row: GridRowData) => (
          <Box sx={{ fontSize: '3px' }}>
            {customActions && customActions.map((x) => x(row))}
            {props.showActions && (
              <>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    return onEditIconClick(row);
                  }}
                  aria-label="edit"
                  className={styles.actionBtn}
                >
                  <CreateIcon className={styles.actionBtnIcon} />
                </IconButton>
                {!props.hideRemoveBtn && (
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      return onDeleteIconClick(row);
                    }}
                    aria-label="delete"
                    className={styles.actionBtn}
                  >
                    <DeleteIcon className={styles.actionBtnIcon} />
                  </IconButton>
                )}
              </>
            )}
          </Box>
        ),
      },
    ];
  }

  return (
    <Paper className={styles.root} elevation={3}>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)} onOk={() => onDelete()} />
      {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
      <DataGrid
        {...rest}
        className={styles.grid}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        loading={isLoading}
        editMode={'row'}
        disableSelectionOnClick
        onRowClick={(args) => onRowClick(args)}
        components={{
          Toolbar: QuickSearchToolbar,
          Pagination: Pagination,
          NoRowsOverlay: NoRowsOverlay,
        }}
        componentsProps={{
          toolbar: {
            title: title,
            value: searchText,
            onChange: (event) => setSearchText(event.target.value),
            clearSearch: () => setSearchText(''),
          } as QuickSearchToolbarProps,
        }}

      />
      {!hideAddBtn && (
        <Button variant="contained" className={styles.addBtn} onClick={() => onAddClick()}>
          Novo
        </Button>
      )}
    </Paper>
  );
}
