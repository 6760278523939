import { Route, RouteComponentProps, Switch } from 'react-router-dom';

interface CrudBehaviorMatchParams {
  id: string;
  [key: string]: string
}
export type CrudBehaviorRouteProps =
  RouteComponentProps<CrudBehaviorMatchParams>;
interface CrudBehaviorComponentsProps {
  entityListComponent: () => JSX.Element;
  entityEditFormComponent?: (props: CrudBehaviorRouteProps) => JSX.Element;
  entityCreateFormComponent?: (props: CrudBehaviorRouteProps) => JSX.Element;
}

export default function CrudBehavior({
  entityListComponent,
  entityEditFormComponent,
  entityCreateFormComponent,
  match,
}: CrudBehaviorComponentsProps & CrudBehaviorRouteProps) {
  return (
    <Switch>
      <Route path={match.path} exact component={entityListComponent}></Route>
      {entityCreateFormComponent && (
        <Route
          path={`${match.path}/new`}
          exact
          component={entityCreateFormComponent}></Route>
      )}
      <Route
        path={`${match.path}/:id`}
        exact
        component={entityEditFormComponent}></Route>
      {/* <Redirect to={'/home'} /> */}
    </Switch>
  );
}

export const withCrudBehavior = ({
  entityListComponent,
  entityEditFormComponent,
  entityCreateFormComponent,
}: CrudBehaviorComponentsProps) => {
  return (props: CrudBehaviorRouteProps) => {
    return (
      <CrudBehavior
        {...props}
        entityListComponent={entityListComponent}
        entityEditFormComponent={entityEditFormComponent}
        entityCreateFormComponent={entityCreateFormComponent}
      />
    );
  };
};
