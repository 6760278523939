import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import { AppointmentResponse } from '../../interfaces/appointment';
import { CreateTreatment, Treatment } from '../../interfaces/treatment';
import { baseUrl } from '../base-service';


export const treatmentApi = createApi({
  reducerPath: 'treatmentApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${baseUrl}/treatments` }),
  endpoints: (builder) => ({
    createTreatment: builder.mutation<Treatment, CreateTreatment>({
      query(body) {
        return {
          url: '/',
          method: 'POST',
          body: body,
        };
      },
    }),
    updateTreatment: builder.mutation<
      void,
      Partial<Treatment>
    >({
      query(body) {
        const request = {
          ...body,
          date: moment(body.date).toISOString()
        }
        return {
          url: `/${body.id}`,
          method: 'PATCH',
          body: request,
        };
      },
    }),
    deleteTreatment: builder.mutation<void, number>({
      query(id) {
        return {
          url: `/${id}`,
          method: 'DELETE',
        };
      },
    }),
    getTreatmentById: builder.query<Treatment, number>({
      query: (id) => `/${id}`,
    }),
    getTreatment: builder.query<Treatment[], void>({
      query: (_) => ``,
    }),
    getTreatmentScheduled: builder.query<AppointmentResponse[], number>({
      query: (id) => `${id}/scheduled`,
    }),
  }),
});

export const {
  useCreateTreatmentMutation,
  useUpdateTreatmentMutation,
  useGetTreatmentByIdQuery,
  useGetTreatmentQuery,
  useLazyGetTreatmentQuery,
  useDeleteTreatmentMutation,
  useGetTreatmentScheduledQuery,
  useLazyGetTreatmentScheduledQuery
} = treatmentApi;