import { useState } from "react";
import { sleep } from "../helpers/helpers";
import { useAuth } from "../hooks";
import { CreateDoctor, Doctor } from "../interfaces/doctor";
import { useAssignDoctorMutation } from "../services/clinic/clinic-service";
import { useCreateUserMutation } from "../services/cognito/cognito-service";
import { useCreateDoctorMutation } from "../services/doctor/doctor-service";
import { UserProfile } from "../store/auth";

export function useCreateDoctor(): [(newDoctor: CreateDoctor) => Promise<Doctor | undefined>, { isLoading: boolean, isError: boolean, isSuccess: boolean, data?: Doctor, isCompleted: boolean }] {
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [auth] = useAuth()
  const [createUserApi, { isLoading: createUserIsLoading, isError: createUserIsError, isSuccess: createUserIsSuccess }] = useCreateUserMutation();
  const [assignDoctorApi, { isLoading: assignDoctorIsLoading, isError: assignDoctorIsError, isSuccess: assignDoctorIsSuccess }] = useAssignDoctorMutation();
  const [addDoctorApi, { isLoading: addDoctorIsLoading, isError: addDoctorIsError, isSuccess: addDoctorIsSuccess, data: doctor }] =
    useCreateDoctorMutation();

  const createDoctor = async (newDoctor: CreateDoctor) => {
    setIsCompleted(false)

    try {
      const newUserRequest = {
        firstName: newDoctor.firstName,
        lastName: newDoctor.lastName,
        document: newDoctor.document,
        phone: newDoctor.phone,
        email: newDoctor.email,
        password: newDoctor.password,
        profile: UserProfile.DOCTOR,
        active: true
      }
      const user = await createUserApi(newUserRequest).unwrap()

      const createDoctorRequest = {
        user,
        specialtyIds: newDoctor.specialtyIds
      }

      await sleep(1000)

      const doctor = await addDoctorApi(createDoctorRequest).unwrap()

      await assignDoctorApi({ clinicId: auth.clinic!, doctorId: doctor.id }).unwrap()

    } catch (error) {
      console.error(error)
    } finally {
      setIsCompleted(true)
    }
    return doctor
  }

  return [
    createDoctor,
    {
      isLoading: createUserIsLoading || assignDoctorIsLoading || addDoctorIsLoading,
      isError: createUserIsError || assignDoctorIsError || addDoctorIsError,
      isSuccess: createUserIsSuccess || assignDoctorIsSuccess || addDoctorIsSuccess,
      isCompleted,
      data: doctor
    }
  ]
}