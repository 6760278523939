export enum ProcedureStatus {
  Pending = 0,
  Completed = 1,
  Absent = 2,
  Canceled = 3,
}

export const StatusLabelMap = {
  [ProcedureStatus.Pending]: 'Aguardando',
  [ProcedureStatus.Completed]: 'Concluído',
  [ProcedureStatus.Absent]: 'Faltante',
  [ProcedureStatus.Canceled]: 'Desistente',
};
