import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import moment from 'moment-timezone';
import { defaultTimeZone } from '../../helpers/helpers';
import { AppointmentResponse } from '../../interfaces/appointment';
import { Patient, PatientResponse } from '../../interfaces/patient';
import { baseUrl } from '../base-service';

export const patientApi = createApi({
  reducerPath: 'patientApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${baseUrl}/patients` }),
  endpoints: (builder) => ({
    createPatient: builder.mutation<Patient, Omit<Patient, 'id'>>({
      query(body) {
        const { medicalInsurance, doctorId: doctor, ...userData } = body
        const request = {
          medicalInsurance,
          doctor,
          user: {
            ...userData,
            birthdate: moment(body.birthdate).tz(defaultTimeZone).toISOString(true),
          }
        }
        return {
          url: '/',
          method: 'POST',
          body: request,
        };
      },
    }),
    updatePatient: builder.mutation<
      void,
      Partial<Patient> & Pick<Patient, 'id'>
    >({
      query(body) {
        const { medicalInsurance, doctorId: doctor, userId, ...userData } = body
        const request = {
          medicalInsurance,
          doctor,
          user: {
            ...userData,
            id: userId,
            birthdate: moment(body.birthdate).toISOString(),
          }
        }
        return {
          url: `/${body.id}`,
          method: 'PATCH',
          body: request,
        };
      },
    }),
    deletePatient: builder.mutation<void, number>({
      query(id) {
        return {
          url: `/${id}`,
          method: 'DELETE',
        };
      },
    }),
    getPatientById: builder.query<Patient, number>({
      query: (id) => `/${id}`,
      transformResponse: (response: PatientResponse) => {
        const { user, doctor, ...otherData } = response
        return {
          ...user,
          ...otherData,
          doctorId: doctor?.id,
        } as Patient
      }
    }),
    getPatient: builder.query<Patient[], void>({
      query: (_) => ``,
      transformResponse: (response: PatientResponse[]) => {
        const mapped = response.map(x => {
          const { user, doctor, ...otherData } = x
          return {
            ...user,
            ...otherData,
            doctorId: doctor?.id
          } as Patient
        })
        return mapped
      }
    }),
    getPatientAppointments: builder.query<AppointmentResponse[], number>({
      query: (id) => `${id}/appointments`,
    }),
  }),
});

export const {
  useCreatePatientMutation,
  useUpdatePatientMutation,
  useGetPatientByIdQuery,
  useGetPatientQuery,
  useLazyGetPatientQuery,
  useDeletePatientMutation,
  useLazyGetPatientByIdQuery,
  useGetPatientAppointmentsQuery
} = patientApi;
