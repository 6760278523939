import { GridColumns } from '@mui/x-data-grid';
import { CrudDataTable } from '../../../../components/crud-data-table';
import Dialog from '../../../../components/dialog/dialog';
import { fullName } from '../../../../helpers/helpers';
import { CrudPageView } from '../../../../interfaces/crud';
import { DoctorResponse } from '../../../../interfaces/doctor';
import { useGetSpecialtiesQuery } from '../../../../services/options/options-service';
import { doctorPageTitle } from '../../doctor-page';

export default function DoctorCrudPageView({
  isLoading,
  data,
  onDeleteIconClick,
  onEditIconClick,
  onRowClick,
  showDialog,
  dialogVariant,
  onCloseDialog,
}: CrudPageView<DoctorResponse>) {
  const { isLoading: cachedSpecialtiesIsLoading } = useGetSpecialtiesQuery();

  const columns: GridColumns = [
    {
      field: 'firstName',
      headerName: 'Nome',
      flex: 1,
      renderCell: ({ row }) => fullName(row as any),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'document',
      headerName: 'Documento',
      flex: 1,
    },
    {
      field: 'specialties',
      headerName: 'Especialidades',
      flex: 2,
      renderCell: ({ row }) =>
        row.specialties.map((x: any) => x.description).join(', '),
    },
  ];

  return (
    <>
      <Dialog
        open={showDialog}
        variant={dialogVariant}
        onClose={onCloseDialog}
      />
      <CrudDataTable
        title={doctorPageTitle}
        data={data.map((x) => {
          const { user, ...otherData } = x;
          return {
            ...user,
            ...otherData,
          };
        })}
        isLoading={isLoading && cachedSpecialtiesIsLoading}
        columns={columns}
        pageSize={15}
        handleRowClick={onRowClick}
        handleDeleteIconClick={onDeleteIconClick}
        handleEditIconClick={onEditIconClick}
        showActions
      />
    </>
  );
}
