import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import moment from 'moment-timezone';
import { defaultTimeZone } from '../../helpers/helpers';
import { AppointmentRequest as Consult, AppointmentResponse, AppointmentScheduleRequest } from '../../interfaces/appointment';
import { baseUrl } from '../base-service';

export const consultApi = createApi({
  reducerPath: 'consultApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${baseUrl}/appointments` }),
  endpoints: (builder) => ({
    createConsult: builder.mutation<Consult, any>({
      query(body) {
        if (!body.doctor) delete body.doctor
        const { patientName, ...requestBody } = body;
        return {
          url: '/create-batch',
          method: 'POST',
          body: requestBody,
        };
      },
    }),
    updateConsult: builder.mutation<void, any>({
      query(body) {

        body.date = moment(body.date).tz(defaultTimeZone).toISOString(true);
        body.releaseDate = moment(body.releaseDate).tz(defaultTimeZone).toISOString(true);

        const { patientName, ...requestBody } = body
        return {
          url: `${body.id}`,
          method: 'PATCH',
          body: requestBody,
        };
      },
    }),
    deleteConsult: builder.mutation<void, number>({
      query(id) {
        return {
          url: `/${id}`,
          method: 'DELETE',
        };
      },
    }),
    getConsultById: builder.query<AppointmentResponse, number>({
      query: (id) => `/${id}`,
    }),
    getConsult: builder.query<AppointmentResponse[], void>({
      query: (_) => ``,
    }),
    getPendingConsult: builder.query<AppointmentResponse[], { appointmentTypes: number[] }>({
      query: (params) => {
        const array = 'appointmentTypes[]=' + params.appointmentTypes.join('&appointmentTypes[]=')
        return {
          url: `/not-scheduled?${array}`,
        }
      },
    }),
    getScheduled: builder.query<AppointmentResponse[], { appointmentTypes: number[] }>({
      query: (params) => {
        const array = 'appointmentTypes[]=' + params.appointmentTypes.join('&appointmentTypes[]=')
        const { appointmentTypes, ...otherParams } = params
        return {
          url: `/scheduled?${array}`,
          params: otherParams
        }
      },
    }),
    schedule: builder.mutation<void, AppointmentScheduleRequest>({
      query: (body) => ({
        url: `/schedule`,
        method: 'POST',
        body
      }),
    }),
  }),
});


export const {
  useCreateConsultMutation,
  useUpdateConsultMutation,
  useGetConsultByIdQuery,
  useGetConsultQuery,
  useDeleteConsultMutation,
  useGetScheduledQuery,
  useGetPendingConsultQuery,
  useScheduleMutation,
  useLazyGetScheduledQuery
} = consultApi;
