import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Copyright(props: any) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}>
      {'v.01 - Copyright © '}
      <Link color='inherit' href='http://redecarinho.com.br/'>
        Rede carinho
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
