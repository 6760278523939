import Grid from '@mui/material/Grid';

import doctors from '../../assets/images/home/doctors.svg';
// import history from '../../assets/images/home/history.svg';
import patients from '../../assets/images/home/patients.svg';
import procedures from '../../assets/images/home/procedures.svg';
import scheduled from '../../assets/images/home/scheduled.svg';
import reports from '../../assets/images/home/reports.svg';
import adminZone from '../../assets/images/home/admin-zone.svg';
import access from '../../assets/images/home/access.svg';
import examZone from '../../assets/images/admin/exams.svg';
import { CardAction } from '../../components/card-action';
import { CardActionProps } from '../../components/card-action/card-item';
import { useAuth } from '../../hooks';
import { UserProfile } from '../../store/auth';

export default function Home() {
  const [auth] = useAuth();

  const isLab = () => auth.profile === UserProfile.LAB;
  const isAdmin = () => auth.profile === UserProfile.ADMIN;
  const isDoctor = () => auth.profile === UserProfile.DOCTOR;

  const items: (CardActionProps & { hide?: boolean })[] = [
    // {
    //   imageSrc: laboratory,
    //   link: '/reset-password',
    //   title: 'Laboratórios',
    //   subtitle: 'ACESSO COMPLETO',
    // },
    {
      imageSrc: doctors,
      link: '/doctor',
      title: 'Médicos',
      subtitle: 'GERENCIAMENTO DE PROFISSIONAIS',
      hide: isLab() || isDoctor(),
    },
    {
      imageSrc: procedures,
      link: '/consult',
      title: 'Procedimentos',
      subtitle: 'ACESSO À REDE',
      hide: isLab(),
    },
    {
      imageSrc: patients,
      link: '/patient',
      title: 'Pacientes',
      subtitle: 'GESTÃO DE PACIENTES',
    },
    {
      imageSrc: scheduled,
      link: '/scheduled',
      title: 'Agendados',
      subtitle: 'CONSULTA/EXAMES',
    },
    {
      imageSrc: reports,
      link: '/reports',
      title: 'Relatórios',
      subtitle: 'ESTATÍSTICA E PERFORMANCE',
      hide: !isAdmin(),
    },
    {
      imageSrc: access,
      link: '/access',
      title: 'Acessos',
      subtitle: 'CADASTROS E AUTORIZAÇÕES',
      hide: !isAdmin(),
    },
    {
      imageSrc: adminZone,
      link: '/admin-zone',
      title: 'Administracao',
      subtitle: 'GESTÃO DA PLATAFORMA',
      hide: !isAdmin(),
    },
    {
      imageSrc: examZone,
      link: '/exams',
      title: 'Exames',
      subtitle: 'ACESSO COMPLETO',
      hide: !isLab(),
    },
  ];

  return (
    <Grid container spacing={3}>
      {items
        .filter((x) => !x.hide)
        .map((item, index) => {
          return (
            <Grid item xs={4} key={index}>
              <CardAction {...item}></CardAction>
            </Grid>
          );
        })}
    </Grid>
  );
}
