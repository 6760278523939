import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DialogProps,
  DialogVariantType,
} from '../../../../components/dialog/dialog';
import { isQueryCompleted } from '../../../../helpers/helpers';
import { Patient } from '../../../../interfaces/patient';
import { useCreatePatientMutation } from '../../../../services/patient/patient-service';
import { PatientModel } from '../../model/patient-model';
import PatientFormView from '../patient-form-view/patient-form-view';

function PatientAddFormContainer() {
  const model = new PatientModel();
  const history = useHistory();
  const [addApi, { isLoading, isError, isSuccess, status, data }] =
    useCreatePatientMutation();

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });

  const handleSubmit = (formObj: Omit<Patient, 'id'>) => {
    addApi(formObj);
  };

  useEffect(() => {
    if (!isQueryCompleted(status) || !data) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: isSuccess ? 'success' : ('error' as DialogVariantType),
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));

        if (isSuccess) {
          const destiny = `${history.location.pathname}`.replace(
            'new',
            data?.id?.toString() ?? ''
          );
          history.push(destiny);
        }
      },
    };

    setDialogProps(dialogProps);
  }, [isSuccess, isError, status, data, history]);

  return (
    <PatientFormView
      isLoading={isLoading}
      dialogProps={dialogProps}
      title={'Novo Paciente'}
      model={model}
      handleSubmit={handleSubmit}
    />
  );
}

export default PatientAddFormContainer;
