import { withCrudBehavior } from '../../components/crud-behavior/crud-behavior';
import { HistoryEditForm } from './components/history-edit-form';
import { HistoryList } from './components/history-list';

export const historyPageTitle = 'Histórico';

const HistoryPage = withCrudBehavior({
  entityEditFormComponent: HistoryEditForm,
  entityListComponent: HistoryList,
});

export default HistoryPage;
