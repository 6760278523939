import { QueryStatus } from '@reduxjs/toolkit/dist/query/react';
import { useEffect, useState } from 'react';
import { DialogVariantType } from '../../../../components/dialog/dialog';
import { useScheduleMutation } from '../../../../services/consult/consult-service';
import ScheduledCrudPageView from './scheduled-list';
import { useGetPatientQuery } from '../../../../services/patient/patient-service';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../../hooks';
import { useGetScheduledQueryByProfile } from '../../../../hooks/use-get-scheduled';
import { AppointmentScheduleRequest } from '../../../../interfaces/appointment';

export default function ScheduledListContainer() {
  const history = useHistory();
  const [auth] = useAuth();

  // const getAppointmentTypesByProfile = () => {
  //   if (auth.profile === UserProfile.ADMIN) {
  //     return [
  //       AppointmentEnumTypes.CONSULT,
  //       AppointmentEnumTypes.FOWARD,
  //       AppointmentEnumTypes.EXAM,
  //     ];
  //   } else if (auth.profile === UserProfile.LAB) {
  //     return [AppointmentEnumTypes.EXAM];
  //   } else {
  //     return [AppointmentEnumTypes.CONSULT, AppointmentEnumTypes.FOWARD];
  //   }
  // };

  // const getQueryState = useGetScheduledQuery({
  //   appointmentTypes: getAppointmentTypesByProfile()
  // });

  const getQueryState = useGetScheduledQueryByProfile();

  const [scheduleApi, scheduleMutationState] = useScheduleMutation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogVariant, setDialogVariant] = useState<DialogVariantType>('success');
  const [dialogSubtitle, setDialogSubtitle] = useState('');
  const [dialogOnClose, setDialogOnClose] = useState<() => void>(() => setShowDialog(false));

  const isMutationCompleted =
    scheduleMutationState.status === QueryStatus.fulfilled || scheduleMutationState.status === QueryStatus.rejected;

  const { isLoading: patientsLoading, data: patientsdata } = useGetPatientQuery();

  useEffect(() => {
    if (isMutationCompleted) {
      setDialogVariant(scheduleMutationState.isSuccess ? 'success' : 'error');
      setShowDialog(true);
      setDialogOnClose(() => setShowDialog(false));

      if (scheduleMutationState.error) {
        console.error(
          'endpointName',
          scheduleMutationState.endpointName,
          'originalArgs',
          scheduleMutationState.originalArgs,
          '',
          scheduleMutationState.error,
        );
      }
    }
  }, [
    isMutationCompleted,
    scheduleMutationState.isSuccess,
    scheduleMutationState.error,
    scheduleMutationState.endpointName,
    scheduleMutationState.originalArgs,
  ]);

  const isLoading = () => getQueryState.isLoading || scheduleMutationState.isLoading || patientsLoading;

  const onDelete = (id: number) => {
    const request = {
      id,
      scheduled: false,
    } as AppointmentScheduleRequest;
    scheduleApi(request).then(() => {
      getQueryState.refetch();
    });
  };

  const onRowClick = (rowData: any) => {
    history.push(`scheduled/${rowData.id}`);
  };

  return (
    <ScheduledCrudPageView
      onCloseDialog={dialogOnClose}
      showDialog={showDialog}
      dialogVariant={dialogVariant}
      dialogSubtitle={dialogSubtitle}
      isLoading={isLoading()}
      data={getQueryState.data ?? []}
      onDeleteIconClick={onDelete}
      patients={patientsdata || []}
      onRowClick={onRowClick}
      profile={auth.profile}
    />
  );
}
