import * as yup from 'yup';
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator';
import { CrudFormModel } from '../../../components/form-v2/models/form-model';
import { AppointmentEditResquest, AppointmentResponse } from '../../../interfaces/appointment';
import { Patient } from '../../../interfaces/patient';
import { useGetAppointmentTypesQuery, useGetModalitiesQuery, useGetSpecialtiesQuery, useGetCidsQuery, useGetExamsQuery } from '../../../services/options/options-service';
import moment from 'moment-timezone'
import { useGetDoctorsByProfileQuery } from '../../../hooks/use-get-doctors';
import { defaultTimeZone } from '../../../helpers/helpers';
export class ConsultEditModel extends CrudFormModel implements AppointmentEditResquest {
  id: number | undefined;

  @FormField({
    label: 'Nome do Paciente',
    placeholder: 'Selecione o paciente',
    schema: yup.string().required(),
    disabled: true
  })
  patientName: string = ''

  @FormField({
    label: 'Documento do Paciente',
    placeholder: 'Selecione o paciente',
    schema: yup.string().required(),
    disabled: true
  })
  patientDocument: string = '';

  @FormField({
    label: 'Data de nascimento do Paciente',
    placeholder: 'Selecione o paciente',
    schema: yup.string().required(),
    disabled: true
  })
  patientBirthdate: string = '';

  @FormField({
    label: 'Email do Paciente',
    placeholder: 'Selecione o paciente',
    schema: yup.string().required(),
    disabled: true
  })
  patientEmail: string = '';

  @FormField({
    label: 'Telefone do Paciente',
    placeholder: 'Selecione o paciente',
    schema: yup.string().required(),
    disabled: true
  })
  patientPhone: string = '';

  @FormField({
    label: 'Convênio do Paciente',
    placeholder: 'Selecione o convenio',
    schema: yup.string().required(),
    disabled: true
  })
  insuranceName: string = ''

  patient: number = 0;

  @FormField({
    label: 'Modalidade',
    placeholder: 'Seleciona uma modalidade',
    disabled: true,
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetModalitiesQuery()

      return { data: data ?? [], isLoading, isError }
    },
    schema: yup.number().required(),
    type: 'select',
  })
  modality: number = 1

  @FormField({
    label: 'Tipo',
    placeholder: 'Seleciona um tipo',
    disabled: true,
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetAppointmentTypesQuery()

      return { data: data ?? [], isLoading, isError }
    },
    schema: yup.number().required(),
    type: 'select',
  })
  type: number = 1

  @FormField({
    label: 'Cid',
    placeholder: 'Seleciona o Cid',
    disabled: true,
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetCidsQuery()

      return { data: data ?? [], isLoading, isError }
    },
    schema: yup.number().required(),
    type: 'select',
  })
  cid: number = 1

  @FormField({
    label: 'Exames',
    placeholder: 'Selecione o exame',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetExamsQuery()

      return { data: data ?? [], isLoading, isError }
    },
    type: 'select',
    disabled: true,
    hidden: (formValues: any) => {
      if (!formValues) return false
      return formValues.type !== 2
    }
  })
  exam: number = 0;

  @FormField({
    label: 'Especialidades',
    placeholder: 'Selecione as especialidades',
    disabled: true,
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetSpecialtiesQuery()

      return { data: data ?? [], isLoading, isError }
    },
    type: 'select',
    hidden: (formValues: any) => {
      if (!formValues) return false
      return formValues.type === 2
    }
  })
  specialty: number = 0;

  user: number

  @FormField({
    label: 'Médico',
    placeholder: 'Selecione um médico para atendimento',
    schema: yup.number().required(),
    type: 'select',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetDoctorsByProfileQuery()

      const toOptions = data?.map(x => ({ id: x.id, value: x.id, description: `${x.user.firstName} ${x.user.lastName} - ${x.user.document}` })) ?? []
      return { data: toOptions, isLoading, isError }
    },
    disabled: (formValues: any) => {
      if (!formValues) return false
      // quando for encaminhamento a opçao é travada
      return formValues.type === 3
    },
    hidden: (formValues: any) => {
      if (!formValues) return false
      return formValues.type === 2 //** exame */
    }
  })
  doctor: number

  @FormField({
    label: 'Data',
    placeholder: 'Insira a data e hora de atentimento',
    type: 'date-time',
    schema: yup.string().test('dateValidation', 'A data está inválida, utilize o seletor de data para selecionar', function (value) {
      return value?.toString().toLocaleLowerCase() != 'invalid date'
    }),
  })
  date: string

  laboratory: number | undefined

  constructor(user: number, patient: Patient, props?: AppointmentResponse, laboratory?: number) {
    super()
    this.user = user

    // patient data
    this.patient = patient.id!
    this.patientName = patient.firstName! + ` ` + patient.lastName!
    this.patientEmail = patient.email!;
    this.patientPhone = patient.phone!;
    this.patientDocument = patient.document!;
    this.patientBirthdate = moment(patient.birthdate!).tz(defaultTimeZone).format('DD/MM/yyyy')

    this.insuranceName = patient.medicalInsurance

    // appointment data
    this.id = props?.id ?? 0
    this.specialty = props?.specialtyId ?? 0
    this.cid = props?.cidId ?? 0
    this.type = props?.typeId ?? 0
    this.modality = props?.modalityId ?? 0
    this.doctor = props?.doctorId ?? 0 ?? 0
    this.date = moment(props?.date ?? '').tz(defaultTimeZone).format('DD/MM/yyyy HH:mm')
    this.exam = props?.examId ?? 0
    this.laboratory = laboratory
  }

}
