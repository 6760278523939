import { CrudForm } from '../../../../components/crud-form';
import { CrudFormProps } from '../../../../components/crud-form/crud-form';

export default function DiagnosticFormView({
  model,
  title,
  handleSubmit,
  isLoading,
  dialogProps,
  backPagePath
}: Partial<CrudFormProps>) {
  return (
    <CrudForm
      title={title!}
      handleSubmit={handleSubmit!}
      model={model}
      isLoading={isLoading!}
      dialogProps={dialogProps!}
      backPagePath={backPagePath!}
      goBack={true}
    />
  );
}
