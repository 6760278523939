import { withCrudBehavior } from '../../components/crud-behavior/crud-behavior';
import { ScheduledEditForm } from './components/scheduled-edit-form';
import { ScheduledList } from './components/scheduled-list';

export const scheduledPageTitle = 'Agendados';

const ScheduledPage = withCrudBehavior({
  entityEditFormComponent: ScheduledEditForm,
  entityListComponent: ScheduledList,
});

export default ScheduledPage;
