import { Button } from '@mui/material';
import React from 'react';
import OnboardingScaffold from '../../../../components/onboarding-scaffold/onboarding-scaffold';
import { SpacerV } from '../../../../components/spacer/spacer';

interface CompleteProfileStepProps {
  handleSubmit: () => void;
}

const FinishedStep: React.FunctionComponent<CompleteProfileStepProps> = ({
  handleSubmit,
}) => {
  return (
    <OnboardingScaffold
      title={<b>Registro completo</b>}
      subtitle={'Seu registro foi enviado para análise.'}>
      <SpacerV size={10} />

      <Button
        type='submit'
        variant='contained'
        sx={{ mt: 3, mb: 2 }}
        onClick={handleSubmit}>
        Voltar para o início
      </Button>
    </OnboardingScaffold>
  );
};

export default FinishedStep;
