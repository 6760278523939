import { useEffect, useState } from 'react';
import { CrudForm } from '../../../../components/crud-form';
import {
  DialogProps,
  DialogVariantType,
} from '../../../../components/dialog/dialog';
import { isQueryCompleted } from '../../../../helpers/helpers';
import { Option } from '../../../../interfaces/options';
import { useOptions } from '../../context/OptionsContext';
import { OptionAddModel } from '../../model/option-add-model';

function OptionAddFormContainer() {
  const model = new OptionAddModel();
  const { history, title, add, addState, backPath } = useOptions();

  const { isLoading, isError, isSuccess, status, data } = addState;

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });

  const handleSubmit = (formObj: Omit<Option, 'id'>) => {
    console.log('formObj', formObj);
    add(formObj);
  };

  useEffect(() => {
    if (!isQueryCompleted(status) || !data) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: isSuccess ? 'success' : ('error' as DialogVariantType),
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));

        if (isSuccess) {
            const destiny = `${history.location.pathname}`.replace(
              'new',
              data?.id?.toString() ?? ''
            );

            history.push(destiny);
        }
      },
    };

    setDialogProps(dialogProps);
  }, [isSuccess, isError, status, data, history]);

  return (
    <CrudForm
      title={title!}
      handleSubmit={handleSubmit!}
      model={model}
      isLoading={isLoading!}
      dialogProps={dialogProps!}
      backPagePath={backPath}
    />
  );
}

export default OptionAddFormContainer;
