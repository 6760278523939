import { QueryStatus } from '@reduxjs/toolkit/dist/query/react';
import { useEffect, useState } from 'react';
import { DialogVariantType } from '../../../../components/dialog/dialog';
import OptionsCrudPageView from './options-list';
import { useOptions } from '../../context/OptionsContext';

export default function OptionsListContainer() {
  const { title, fetch, fetchState, deleteState, deleteApi } = useOptions();

  useEffect(() => {
    fetch();
  }, []);

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogVariant, setDialogVariant] =
    useState<DialogVariantType>('success');

  const isMutationCompleted =
    deleteState.status === QueryStatus.fulfilled ||
    deleteState.status === QueryStatus.rejected;

  useEffect(() => {
    if (isMutationCompleted) {
      setDialogVariant(deleteState.isSuccess ? 'success' : 'error');
      setShowDialog(true);

      if (deleteState.error) {
        console.error(
          'endpointName',
          deleteState.endpointName,
          'originalArgs',
          deleteState.originalArgs,
          '',
          deleteState.error
        );
      }
    }
  }, [
    isMutationCompleted,
    deleteState.isSuccess,
    deleteState.error,
    deleteState.endpointName,
    deleteState.originalArgs,
  ]);

  const isLoading = () => fetchState.isLoading || deleteState.isLoading;

  const onDelete = (id: number) => {
    deleteApi(id).then(() => {
      fetch();
    });
    deleteApi(id);
  };

  return (
    <OptionsCrudPageView
      onCloseDialog={() => setShowDialog(false)}
      showDialog={showDialog}
      dialogVariant={dialogVariant}
      isLoading={isLoading()}
      data={fetchState.data ?? []}
      onDeleteIconClick={onDelete}
      showActions
      title={title}
    />
  );
}
