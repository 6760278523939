import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DashboardState {
  loading: boolean;
}

// Define the initial state using that type
const initialState: DashboardState = {
  loading: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    setDashboardLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setDashboardLoading,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;

