import { GridColumns } from '@mui/x-data-grid';
import moment from 'moment';
import { CrudDataTable } from '../../../../components/crud-data-table';
import Dialog from '../../../../components/dialog/dialog';
import { CrudPageView } from '../../../../interfaces/crud';
import { Patient } from '../../../../interfaces/patient';

const columns: GridColumns = [
  {
    field: 'firstName',
    headerName: 'Nome',
    flex: 1,
    renderCell: (args) => `${args.row['firstName']} ${args.row['lastName']}`,
  },
  {
    field: 'phone',
    headerName: 'Telefone',
    flex: 1,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'medicalInsurance',
    headerName: 'Convênio',
    flex: 1,
  },
  {
    field: 'birthdate',
    headerName: 'Nascimento',
    flex: 1,
    renderCell: ({ row }) => moment(row.birthdate).format('DD/MM/YYYY'),
  },
  {
    field: 'address',
    headerName: 'Endereço',
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Data de Cadastro',
    flex: 1,
    renderCell: ({ row }) => moment(row.createdAt).format('DD/MM/YYYY'),
  },
];

export default function PatientCrudPageView({
  isLoading,
  data,
  onDeleteIconClick,
  onEditIconClick,
  onRowClick,
  showDialog,
  dialogVariant,
  onCloseDialog,
  customActions,
  showActions,
  hideAddBtn,
}: CrudPageView<Patient>) {
  return (
    <>
      <Dialog open={showDialog} variant={dialogVariant} onClose={onCloseDialog} />
      <CrudDataTable
        title="Pacientes"
        data={data}
        isLoading={isLoading}
        columns={columns}
        pageSize={15}
        handleRowClick={onRowClick}
        handleDeleteIconClick={onDeleteIconClick}
        handleEditIconClick={onEditIconClick}
        showActions={showActions}
        customActions={customActions}
        hideAddBtn={hideAddBtn}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'firstName',
                sort: 'asc',
              },
            ],
          },
        }}
      />
    </>
  );
}
