import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DialogProps } from '../../../../components/dialog/dialog';
import { useAppSelector } from '../../../../hooks';
import { AppointmentRequest as Consult } from '../../../../interfaces/appointment';
import { useCreateConsultMutation } from '../../../../services/consult/consult-service';
import { ConsultAddModel } from '../../model/consult-add-model';
import ConsultFormView from '../consult-form-view/consult-form-view';

function ConsultAddFormContainer() {
  const userId = useAppSelector(({ auth }) => auth.userId);
  const patient = useAppSelector(({ appointment }) => appointment.patient);

  const model = new ConsultAddModel(userId ?? 0, patient);
  const history = useHistory();
  const [addApi, { isLoading }] = useCreateConsultMutation();

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });

  const handleSubmit = (formObj: Omit<Consult, 'id'>) => {
    const promise = addApi(formObj);
    promise
      .unwrap()
      .then(() => {
        const dialogProps: DialogProps = {
          open: true,
          variant: 'success',
          onClose: () => {
            setDialogProps((prev) => ({ ...prev, open: false }));
            const destiny = `${history.location.pathname}`.replace('/new', '');
            history.push(destiny);
          },
        };
        setDialogProps(dialogProps);
      })
      .catch(() => {
        const dialogProps: DialogProps = {
          open: true,
          variant: 'error',
          onClose: () => {
            setDialogProps((prev) => ({ ...prev, open: false }));
          },
        };
        setDialogProps(dialogProps);
      })
      .finally();
  };

  useEffect(() => {
    if (!patient) history.push('/consult');
  }, [patient, history]);

  return (
    <ConsultFormView
      isLoading={isLoading}
      dialogProps={dialogProps}
      title={`Nova solicitação`}
      model={model}
      handleSubmit={(data) => handleSubmit(data)}
      backPagePath='/consult'
    />
  );
}

export default ConsultAddFormContainer;
