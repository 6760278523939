import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  AppointmentTypes,
  CidTypes,
  ExamTypes,
  ModalityTypes,
  SpecialtyTypes,
  TreatmentType,
} from '../../interfaces/options';
import { baseUrl } from '../base-service';

export const optionsApi = createApi({
  reducerPath: 'optionsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${baseUrl}` }),
  endpoints: (builder) => ({
    // SPECIALTIES
    getSpecialtyById: builder.query<SpecialtyTypes, number>({
      query: (id) => `specialties/${id}`,
    }),
    getSpecialties: builder.query<SpecialtyTypes[], void>({
      query: (_) => `specialties`,
    }),
    createSpecialty: builder.mutation<
      SpecialtyTypes,
      Omit<SpecialtyTypes, 'id'>
    >({
      query(body) {
        return {
          url: 'specialties/',
          method: 'POST',
          body: body,
        };
      },
    }),
    updateSpecialty: builder.mutation<void, any>({
      query(body) {
        return {
          url: `specialties/${body.id}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    deleteSpecialty: builder.mutation<void, number>({
      query(id) {
        return {
          url: `specialties/${id}`,
          method: 'DELETE',
        };
      },
    }),

    // CIDS
    getCids: builder.query<CidTypes[], void>({
      query: (_) => `cids`,
    }),
    getCidById: builder.query<CidTypes, number>({
      query: (id) => `cids/${id}`,
    }),
    createCid: builder.mutation<CidTypes, Omit<CidTypes, 'id'>>({
      query(body) {
        return {
          url: 'cids/',
          method: 'POST',
          body: body,
        };
      },
    }),
    updateCid: builder.mutation<void, any>({
      query(body) {
        return {
          url: `cids/${body.id}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    deleteCid: builder.mutation<void, number>({
      query(id) {
        return {
          url: `cids/${id}`,
          method: 'DELETE',
        };
      },
    }),
    getModalityById: builder.query<ModalityTypes, number>({
      query: (id) => `modalities/${id}`,
    }),
    getModalities: builder.query<ModalityTypes[], void>({
      query: (_) => `modalities`,
    }),

    // EXAMS
    getExamById: builder.query<ExamTypes, number>({
      query: (id) => `exams/${id}`,
    }),
    getExams: builder.query<ExamTypes[], void>({
      query: (_) => `exams`,
    }),
    createExam: builder.mutation<ExamTypes, Omit<ExamTypes, 'id'>>({
      query(body) {
        return {
          url: 'exams/',
          method: 'POST',
          body: body,
        };
      },
    }),
    updateExam: builder.mutation<void, any>({
      query(body) {
        return {
          url: `exams/${body.id}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    deleteExam: builder.mutation<void, number>({
      query(id) {
        return {
          url: `exams/${id}`,
          method: 'DELETE',
        };
      },
    }),
    getAppointmentTypeById: builder.query<AppointmentTypes, number>({
      query: (id) => `appointments-type/${id}`,
    }),
    getAppointmentTypes: builder.query<AppointmentTypes[], void>({
      query: (_) => `appointments-type`,
    }),
    getTreatmentTypeById: builder.query<TreatmentType, number>({
      query: (id) => `treatment-types/${id}`,
    }),
    getTreatmentTypes: builder.query<TreatmentType[], void>({
      query: (_) => `treatment-types`,
    }),
  }),
});

export const {
  useGetAppointmentTypeByIdQuery,
  useGetAppointmentTypesQuery,
  // cids
  useGetCidByIdQuery,
  useLazyGetCidByIdQuery,
  useGetCidsQuery,
  useLazyGetCidsQuery,
  useCreateCidMutation,
  useUpdateCidMutation,
  useDeleteCidMutation,

  // EXAMS
  useGetExamByIdQuery,
  useGetExamsQuery,
  useCreateExamMutation,
  useDeleteExamMutation,
  useLazyGetExamsQuery,
  useLazyGetExamByIdQuery,
  useUpdateExamMutation,

  useGetModalitiesQuery,
  useGetModalityByIdQuery,

  // Specialties
  useGetSpecialtiesQuery,
  useGetSpecialtyByIdQuery,
  useCreateSpecialtyMutation,
  useDeleteSpecialtyMutation,
  useLazyGetSpecialtiesQuery,
  useLazyGetSpecialtyByIdQuery,
  useUpdateSpecialtyMutation,

  useGetTreatmentTypeByIdQuery,
  useGetTreatmentTypesQuery,

  usePrefetch,
} = optionsApi;

export const usePrefetchOptionsService = () => {
  const { isLoading: useGetAppointmentTypesQueryLoading } =
    useGetAppointmentTypesQuery();
  const { isLoading: useGetCidsQueryLoading } = useGetCidsQuery();
  const { isLoading: useGetExamsQueryLoading } = useGetExamsQuery();
  const { isLoading: useGetModalitiesQueryLoading } = useGetModalitiesQuery();
  const { isLoading: useGetSpecialtiesQueryLoading } = useGetSpecialtiesQuery();

  return {
    isLoading:
      useGetAppointmentTypesQueryLoading ||
      useGetCidsQueryLoading ||
      useGetExamsQueryLoading ||
      useGetModalitiesQueryLoading ||
      useGetSpecialtiesQueryLoading,
    //isLoading: false
  };
};
