import * as yup from 'yup'
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator'
import { FormModel } from '../../../components/form-v2/models/form-model'
import { LoginRequest } from '../../../services/cognito/interfaces/login'

export interface SigninModelProps extends LoginRequest {
	email: string
	password: string
	remember: boolean
	uid: string
}

export class SigninModel extends FormModel {
	@FormField({
		label: 'Email',
		placeholder: 'Email',
		schema: yup.string().email().required(),
		type: 'email',
	})
	public email?: string

	@FormField({
		label: 'Password',
		placeholder: 'Password',
		schema: yup.string().required(),
		type: 'password',
	})
	public password?: string

	@FormField({
		type: 'checkbox',
		options: [{ description: 'Lembrar-me', value: 'remember', id: 0 }],
		schema: yup.boolean(),
	})
	public remember?: boolean = true

	constructor(initialValues?: SigninModelProps) {
		super()
		if (!initialValues) return

		this.email = initialValues.email
		this.password = initialValues.password
		this.remember = initialValues.remember
	}
}
