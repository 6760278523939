import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CrudBehaviorRouteProps } from '../../../../components/crud-behavior/crud-behavior';
import Dialog, {
  DialogProps,
  DialogVariantType,
} from '../../../../components/dialog/dialog';
import { isQueryCompleted } from '../../../../helpers/helpers';
import { useAppSelector, useAuth } from '../../../../hooks';
import { AppointmentEditResquest } from '../../../../interfaces/appointment';
import {
  useUpdateConsultMutation,
  useGetConsultByIdQuery,
  useScheduleMutation,
} from '../../../../services/consult/consult-service';
import { useLazyGetPatientByIdQuery } from '../../../../services/patient/patient-service';
import { ConsultEditModel } from '../../model/consult-edit-model';
import ConsultFormView from '../consult-form-view/consult-form-view';

function ConsultEditFormContainer(props: CrudBehaviorRouteProps) {
  const [auth] = useAuth();
  const history = useHistory();
  const { userId, laboratory, profile } = useAppSelector(({ auth }) => auth);
  const [patientsApi, { isLoading: patientIsLoading, data: patientData }] =
    useLazyGetPatientByIdQuery();
  const [
    updateApi,
    {
      isLoading: updateIsLoading,
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      status: updateStatus,
    },
  ] = useUpdateConsultMutation();

  const {
    data: fetchData,
    isLoading: fetchIsLoading,
    isError: fetchIsError,
  } = useGetConsultByIdQuery(Number(props.match.params.id));

  const [scheduleApi, { isLoading: scheduleIsLoading }] = useScheduleMutation();

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [model, setModel] = useState<ConsultEditModel | undefined>(undefined);
  const [tempFormObj, setTempFormObj] = useState<any>({});

  useEffect(() => {
    if (!isQueryCompleted(updateStatus)) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: updateIsSuccess ? 'success' : ('error' as DialogVariantType),
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));

        if (updateIsSuccess) {
          const destiny = !!auth.clinic === false ? 'exams' : 'consult';
          history.push(`/${destiny}`);
        }
      },
    };

    setDialogProps(dialogProps);
  }, [updateIsSuccess, updateIsError, updateStatus]);

  useEffect(() => {
    if (!fetchIsError) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: 'error',
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [fetchIsError]);

  useEffect(() => {
    if (fetchData) {
      patientsApi(fetchData.patientId);
    }
  }, [fetchData, userId, patientsApi]);

  useEffect(() => {
    if (patientData && fetchData && userId) {
      setModel(
        new ConsultEditModel(userId ?? 0, patientData, fetchData, laboratory)
      );
    }
  }, [patientData, patientIsLoading, fetchData, userId, laboratory]);

  const handleSubmit = (formObj: any) => {
    console.log('formObj', formObj);
    if (formObj.type === 2) {
      delete formObj.specialty;
      delete formObj.doctor;
    } else {
      delete formObj.exam;
    }
    delete formObj.user;
    formObj.scheduled = true;
    updateApi(formObj as AppointmentEditResquest);
  };

  const handleReject = (formObj: any) => {
    setTempFormObj(formObj);
    setShowAlertDialog(true);
  };

  const handleAlertSubmit = () => {
    scheduleApi({
      id: tempFormObj.id!,
      scheduled: false,
    })
      .unwrap()
      .then(() => {
        history.push(profile === 'lab' ? '/exams' : '/consult');
      });
  };

  return (
    <>
      <Dialog
        open={showAlertDialog}
        onOk={() => handleAlertSubmit()}
        onClose={() => {
          setShowAlertDialog(false);
        }}
      />
      <ConsultFormView
        isLoading={updateIsLoading || fetchIsLoading || scheduleIsLoading}
        dialogProps={dialogProps}
        title={`Aprovar solicitaçao`}
        model={model}
        handleSubmit={handleSubmit}
        backPagePath={profile === 'lab' ? '/exams' : '/consult'}
        submitLabel='Aprovar'
        rejectLabel='Recusar'
        handleReject={
          profile !== 'lab' && fetchData?.typeId === 3
            ? handleReject
            : undefined
        }
      />
    </>
  );
}

export default ConsultEditFormContainer;
