import { withCrudBehavior } from '../../components/crud-behavior/crud-behavior';
import { DiagnosticAddForm } from './components/diagnostic-add-form';
import { DiagnosticEditForm } from './components/diagnostic-edit-form';
import { DiagnosticList } from './components/diagnostic-list';

const DiagnosticPage = withCrudBehavior({
  entityCreateFormComponent: DiagnosticAddForm,
  entityEditFormComponent: DiagnosticEditForm,
  entityListComponent: DiagnosticList,
});

export default DiagnosticPage;
