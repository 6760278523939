import { QueryStatus } from '@reduxjs/toolkit/dist/query/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DialogVariantType } from '../../../../components/dialog/dialog';
import { useAuth } from '../../../../hooks';
import { useGetPatientByIdQuery } from '../../../../services/patient/patient-service';
import { useDeleteTreatmentMutation } from '../../../../services/treatment/treatment-service';
import { UserProfile } from '../../../../store/auth';
import TreatmentCrudPageView from './treatment-list';

export default function TreatmentListContainer() {
  const [auth] = useAuth();
  const { patientId } = useParams<{ patientId: string }>();
  const getQueryState = useGetPatientByIdQuery(+patientId);

  useEffect(() => {
    console.log(getQueryState);
  }, [getQueryState.isLoading, getQueryState]);

  const [deleteApi, deleteMutationState] = useDeleteTreatmentMutation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogVariant, setDialogVariant] = useState<DialogVariantType>('success');

  const isMutationCompleted = deleteMutationState.status === QueryStatus.fulfilled || deleteMutationState.status === QueryStatus.rejected;

  useEffect(() => {
    if (isMutationCompleted) {
      setDialogVariant(deleteMutationState.isSuccess ? 'success' : 'error');
      setShowDialog(true);

      if (deleteMutationState.error) {
        console.error(
          'endpointName',
          deleteMutationState.endpointName,
          'originalArgs',
          deleteMutationState.originalArgs,
          '',
          deleteMutationState.error,
        );
      }
    }
  }, [
    isMutationCompleted,
    deleteMutationState.isSuccess,
    deleteMutationState.error,
    deleteMutationState.endpointName,
    deleteMutationState.originalArgs,
  ]);

  const isLoading = () => getQueryState.isLoading || deleteMutationState.isLoading;

  const onDelete = (id: number) => {
    deleteApi(id).then(() => {
      getQueryState.refetch();
    });
  };

  const onRowClick = (row: any) => {
    // dispatch(setPatient(row.row));
    // history.push(`/history/${row.row.id}`);
    // history.push(`/patient-home/${row.row.id}`);
  };

  return (
    <TreatmentCrudPageView
      onCloseDialog={() => setShowDialog(false)}
      showDialog={showDialog}
      dialogVariant={dialogVariant}
      isLoading={isLoading()}
      data={getQueryState?.data?.treatments ?? []}
      onDeleteIconClick={onDelete}
      onRowClick={(row) => onRowClick(row)}
      hideAddBtn={auth.profile === UserProfile.LAB}
      showActions={auth.profile !== UserProfile.LAB}
      backPagePath={`/patient-home/${patientId}`}
    />
  );
}
