import { withCrudBehavior } from '../../components/crud-behavior/crud-behavior';
import { DoctorAddForm } from './components/doctor-add-form';
import { DoctorEditForm } from './components/doctor-edit-form';
import { DoctorList } from './components/doctor-list';

export const doctorPageTitle = 'Médicos';

const DoctorPage = withCrudBehavior({
  entityCreateFormComponent: DoctorAddForm,
  entityEditFormComponent: DoctorEditForm,
  entityListComponent: DoctorList,
});

export default DoctorPage;
