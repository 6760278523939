import { useEffect, useState } from 'react';
import { CrudBehaviorRouteProps } from '../../../../components/crud-behavior/crud-behavior';
import {
  DialogProps,
  DialogVariantType,
} from '../../../../components/dialog/dialog';
import { isQueryCompleted } from '../../../../helpers/helpers';
import { CreateDoctor } from '../../../../interfaces/doctor';
import {
  useUpdateDoctorMutation,
  useGetDoctorByIdQuery,
} from '../../../../services/doctor/doctor-service';
import { doctorPageTitle } from '../../doctor-page';
import { DoctorEditModel } from '../../model/doctor-edit-model';
import DoctorFormView from '../doctor-form-view/doctor-form-view';

function DoctorEditFormContainer(props: CrudBehaviorRouteProps) {
  const [
    updateApi,
    {
      isLoading: updateIsLoading,
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      status: updateStatus,
    },
  ] = useUpdateDoctorMutation();

  const {
    data: fetchData,
    isLoading: fetchIsLoading,
    isError: fetchIsError,
  } = useGetDoctorByIdQuery(Number(props.match.params.id));

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });
  const [model, setModel] = useState<DoctorEditModel | undefined>(undefined);

  const handleSubmit = (formObj: CreateDoctor) => {
    updateApi(formObj);
  };

  useEffect(() => {
    if (!isQueryCompleted(updateStatus)) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: updateIsSuccess ? 'success' : ('error' as DialogVariantType),
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [updateIsSuccess, updateIsError, updateStatus]);

  useEffect(() => {
    if (!fetchIsError) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: 'error',
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [fetchIsError]);

  useEffect(() => {
    if (fetchData) setModel(new DoctorEditModel(fetchData));
  }, [fetchData]);

  return (
    <>
      <DoctorFormView
        isLoading={updateIsLoading || fetchIsLoading}
        dialogProps={dialogProps}
        title={`Editar ${doctorPageTitle}`}
        model={model}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default DoctorEditFormContainer;
