import * as yup from 'yup';
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator';
import { CrudFormModel } from '../../../components/form-v2/models/form-model';
import { ConfirmUserRequest } from '../../../services/cognito/interfaces/create-user';


export class ConfirmUserRequestModel extends CrudFormModel implements Readonly<ConfirmUserRequest>{
  id: number | undefined;

  @FormField({
    label: 'Codigo de confirmação',
    placeholder: 'Insira o código de confirmação',
    schema: yup.string().required().min(6),
  })
  confirmationCode: string = '';


  @FormField({
    label: 'Email',
    placeholder: 'Email',
    schema: yup.string().email().required(),
    type: 'email',
  })
  email: string = '';

  constructor(props?: Partial<ConfirmUserRequest>) {
    super()
    if (!props) return
    this.email = props.email ?? ''
  }
}
