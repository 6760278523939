import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export const OnboardingTypo = styled(Typography)(() => ({
  width: '100%',
}));

const Column = styled(Box)((_) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
}));

export const LeftSide = styled(Column)((_) => ({
  textAlign: 'center',
}));

export const RightSide = styled(Column)((_) => ({
  textAlign: 'left',
  marginTop: '-50px',
}));

export const ImgWrapper = styled(Box)(({ height }) => ({
  display: 'flex',
  flexDirection: 'column-reverse',
  height: `${height}`,
}));
