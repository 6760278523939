import { withCrudBehavior } from '../../components/crud-behavior/crud-behavior';
import { TreatmentAddForm } from './components/treatment-add-form';
import { TreatmentEditForm } from './components/treatment-edit-form';
import { TreatmentList } from './components/treatment-list';

const TreatmentPage = withCrudBehavior({
  entityCreateFormComponent: TreatmentAddForm,
  entityEditFormComponent: TreatmentEditForm,
  entityListComponent: TreatmentList,
});

export default TreatmentPage;
