import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import _ from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks';
import { Option } from '../../../interfaces/options';
import { AuthState } from '../../../store/auth';

type ApiReturn = any;
interface OptionsContextData {
  title: string;
  backPath: string;
  fetch: () => void;
  fetchState: ApiReturn;
  deleteApi: (id: number) => any;
  deleteState: ApiReturn;
  fetchById: (id: number) => void;
  fetchByIdState: ApiReturn;
  add: (option: Omit<Option, 'id'>) => void;
  addState: ApiReturn;
  update: (option: Option) => void;
  updateState: ApiReturn;
  auth: AuthState;
  history: any;
}

interface OptionsProviderProps {
  children: React.ReactNode;
  settings: {
    title: string;
    backPath: string;
    fetch: () => void;
    fetchState: ApiReturn;
    deleteApi: (id: number) => any;
    deleteState: ApiReturn;
    fetchById: (id: number) => void;
    fetchByIdState: ApiReturn;
    add: (option: Omit<Option, 'id'>) => void;
    addState: ApiReturn;
    update: (option: Option) => void;
    updateState: ApiReturn;
  };
}

const OptionsContext = React.createContext<OptionsContextData>(
  {} as OptionsContextData
);

export function OptionsProvider({ children, settings }: OptionsProviderProps) {
  const [auth] = useAuth();
  const history = useHistory();

  return (
    <OptionsContext.Provider value={{ auth, history, ...settings }}>
      {children}
    </OptionsContext.Provider>
  );
}

export function useOptions() {
  const context = useContext(OptionsContext);
  return context;
}
