import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { TextFieldProps } from '@mui/material/TextField';
import React, { useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { IFormField, SetFormState } from '../../form';

const ReactInputMask = require('react-input-mask');

export type IFormTextProps = TextFieldProps & {
  name: string;
  mask?: string;
  validators?: any[];
  errorMessages?: any[];
};

type IFormTextComponentProps = IFormTextProps & {
  setFormState: SetFormState;
};

function FormTextComponent(props: IFormTextComponentProps) {
  const [value, setValue] = useState('');
  const [showPassword, togglePassword] = useState(false);

  const handleChange = (fieldName: string, value: string) => {
    props.setFormState(fieldName, value);
    setValue(value);
  };

  let textFieldProps = {
    ...props,
  } as any;

  if (props.required) {
    if (props.validators) {
      textFieldProps.validators = ['required', ...props.validators];
    } else {
      textFieldProps.validators = ['required'];
    }

    const requiredMessage = `O campo é obrigatório`;
    if (props.errorMessages) {
      textFieldProps.errorMessages = [requiredMessage, ...props.errorMessages];
    } else {
      textFieldProps.errorMessages = [requiredMessage];
    }

    // this is set to prevent form tag to validate in html5
    textFieldProps.required = false;
  }

  if (props.type === 'password') {
    textFieldProps.InputProps = {
      endAdornment: (
        <InputAdornment position='end'>
          <IconButton
            aria-label='toggle password visibility'
            onClick={() => togglePassword(!showPassword)}
            onMouseDown={() => {
              togglePassword(!showPassword);
            }}>
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      ),
    };
  }

  delete textFieldProps.setFormState;

  if (props.mask) {
    return (
      <ReactInputMask
        mask={props.mask}
        maskChar={''}
        value={value}
        onChange={(evt: any) => handleChange(props.name, evt.target.value)}>
        {() => <TextValidator {...textFieldProps} />}
      </ReactInputMask>
    );
  }

  return (
    <TextValidator
      {...textFieldProps}
      value={value}
      onChange={(evt: any) => {
        handleChange(props.name, evt.target.value);
      }}
    />
  );
}

export default function FormText(props: IFormTextProps): IFormField {
  const renderFunc = function (key: string, setFormState: SetFormState): any {
    return (
      <FormTextComponent key={key} {...props} setFormState={setFormState} />
    );
  };
  return {
    renderFunc: renderFunc,
  } as IFormField;
}
