import { Clear, Search } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import theme from '../../../../theme';
import { makeStyles } from '@mui/styles';
import { ChangeEventHandler, MouseEventHandler } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      // padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    textField: {
      // [theme.breakpoints.down('xs')]: {
      //   width: '100%',
      // },
      margin: theme.spacing(1, 0.5, 1.5),
      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.5),
      },
      '& .MuiInput-underline:before': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },
    },
  }),
  { defaultTheme: theme }
);

export interface QuickSearchToolbarProps {
  title: string;
  value: string;
  onChange:
    | ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
  clearSearch: MouseEventHandler<HTMLButtonElement> | undefined;
}

export default function QuickSearchToolbar(props: QuickSearchToolbarProps) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Typography
        variant='h4'
        component={Box}
        sx={{ pt: 2, color: (theme) => theme.palette.primary.main }}>
        <b>{props.title}</b>
      </Typography>
      <TextField
        className={styles.textField}
        variant='outlined'
        value={props.value}
        onChange={props.onChange}
        placeholder='Search…'
        InputProps={{
          startAdornment: (
            <Search
              fontSize='small'
              sx={{ color: (theme) => theme.palette.primary.main }}
            />
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='clear search term'
                onClick={props.clearSearch}
                style={{ visibility: props.value ? 'visible' : 'hidden' }}>
                <Clear fontSize='small' />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
