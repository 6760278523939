import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Copyright from '../../components/copyright/copyright'
import FormV2 from '../../components/form-v2/form-v2'
import OnboardingScaffold from '../../components/onboarding-scaffold/onboarding-scaffold'
import { SpacerV } from '../../components/spacer/spacer'
import { LoginRequest } from '../../services/cognito/interfaces/login'
import { SigninModel } from './model/signin-model'

type SigninForm = LoginRequest & { remember: boolean; uid: string }

interface SigninViewProps {
	isLoading: boolean
	onSubmit: (data: SigninForm) => void
	model: SigninModel
}

export default function signinView({
	isLoading,
	onSubmit,
	model,
}: SigninViewProps) {
	return (
		<OnboardingScaffold
			isLoading={isLoading}
			title={
				<>
					Entrar na <b>Rede Carinho</b>
				</>
			}
		>
			<SpacerV size={4} />

			<FormV2 model={model} submitLabel={'Entrar'} submit={onSubmit} />

			<Grid
				container
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
			>
				<Grid item>
					<Box
						sx={{
							mt: 4,
						}}
					>
						Ainda não tem uma conta?
						<Link href='/onboarding' variant='body1'>
							Criar conta
						</Link>
					</Box>
				</Grid>
				<Grid item>
					<Box
						sx={{
							mt: 4,
						}}
					>
						<Link href='/reset-password' variant='body2'>
							Esqueceu sua senha?
						</Link>
					</Box>
				</Grid>
			</Grid>
			<Copyright sx={{ mt: 5 }} />
		</OnboardingScaffold>
	)
}
