import { GridColumns } from '@mui/x-data-grid';
import { CrudDataTable } from '../../../../components/crud-data-table';
import Dialog from '../../../../components/dialog/dialog';
import { CrudPageView } from '../../../../interfaces/crud';
import { Option } from '../../../../interfaces/options';

const columns: GridColumns = [
  {
    field: 'description',
    headerName: 'Descrição',
    flex: 1,
  },
];

export default function OptionsCrudPageView({
  title,
  isLoading,
  data,
  onDeleteIconClick,
  onEditIconClick,
  onRowClick,
  showDialog,
  dialogVariant,
  onCloseDialog,
  customActions,
  showActions,
  hideAddBtn,
}: CrudPageView<Option>) {
  return (
    <>
      <Dialog
        open={showDialog}
        variant={dialogVariant}
        onClose={onCloseDialog}
      />
      <CrudDataTable
        title={title ?? 'Options'}
        data={data}
        isLoading={isLoading}
        columns={columns}
        pageSize={15}
        handleRowClick={onRowClick}
        handleDeleteIconClick={onDeleteIconClick}
        handleEditIconClick={onEditIconClick}
        showActions={showActions}
        customActions={customActions}
        hideAddBtn={hideAddBtn}
        breadcrumbs={[{ link: '/admin-zone', label: 'Voltar' }]}
      />
    </>
  );
}
