import { useMemo } from "react";
import { useAuth } from "../hooks";
import { useLazyGetClinicPatientsQuery } from "../services/clinic/clinic-service";
import { useLazyGetLaboratoryPatientsQuery } from "../services/laboratory/laboratory-service";
import { useLazyGetPatientQuery } from "../services/patient/patient-service";
import { UserProfile } from "../store/auth";

export function useGetPatientsQueryByProfile() {
  const [auth] = useAuth()
  const [labApi, labQuery] = useLazyGetLaboratoryPatientsQuery()
  const [clinicApi, clinicQuery] = useLazyGetClinicPatientsQuery()
  const [adminApi, adminQuery] = useLazyGetPatientQuery()

  const memoizedCallback = useMemo(() => {
    if (auth.profile === UserProfile.LAB) {
      if (labQuery.isUninitialized) labApi(auth.laboratory!)
      return labQuery
    }
    if (auth.profile === UserProfile.CLINIC || auth.profile === UserProfile.DOCTOR) {
      if (clinicQuery.isUninitialized) clinicApi(auth.clinic!)
      return clinicQuery
    }

    if (adminQuery.isUninitialized) adminApi()
    return adminQuery
  }, [auth.profile, labQuery, clinicQuery, adminQuery])

  return memoizedCallback
}