import { useMemo, useState } from "react";
import { useAuth } from "../hooks";
import { useLazyGetClinicAppointmentsQuery } from "../services/clinic/clinic-service";
import { useLazyGetScheduledQuery } from "../services/consult/consult-service";
import { useLazyGetDoctorScheduledQuery } from "../services/doctor/doctor-service";
import { useLazyGetLaboratoryAppointmentsQuery } from "../services/laboratory/laboratory-service";
import { UserProfile } from "../store/auth";

export function useGetScheduledQueryByProfile() {
  const [auth] = useAuth()
  const [labApi, labQuery] = useLazyGetLaboratoryAppointmentsQuery()
  const [clinicApi, clinicQuery] = useLazyGetClinicAppointmentsQuery()
  const [adminApi, adminQuery] = useLazyGetScheduledQuery()
  const [doctorApi, doctorQuery] = useLazyGetDoctorScheduledQuery()
  const [needRefetch, setNeedRefetch] = useState(false)


  const memoizedCallback = useMemo(() => {
    if (auth.profile === 'lab') {
      if (labQuery.isUninitialized || needRefetch) {
        labApi({ labId: auth.laboratory!, scheduled: true })
        setNeedRefetch(false)
      }
      return labQuery
    }
    if (auth.profile === UserProfile.CLINIC) {
      if (clinicQuery.isUninitialized || needRefetch) {
        clinicApi({ clinicId: auth.clinic!, scheduled: true })
        setNeedRefetch(false)
      }
      return clinicQuery
    }

    if (auth.profile === UserProfile.DOCTOR) {
      if (doctorQuery.isUninitialized || needRefetch) {
        doctorApi(auth.doctor!)
        setNeedRefetch(false)
      }
      return doctorQuery
    }

    if (adminQuery.isUninitialized || needRefetch) {
      adminApi({ appointmentTypes: [1, 2, 3] })
      setNeedRefetch(false)
    }
    return adminQuery
  }, [auth.profile, labQuery, clinicQuery, adminQuery, doctorQuery, needRefetch])


  const refetch = () => [
    setNeedRefetch(true)
  ]

  return { ...memoizedCallback, refetch }
}