import { hot } from 'react-hot-loader';
import { Router } from 'react-router-dom';
import { useAuth } from '../hooks';
import { Dashboard } from '../pages/dashboard';
import history from '../components/routes/history';
import {
  publicRoutes,
  PublicRoutes,
} from '../components/routes/public-routes-component';
import { DashboardRoutes } from '../components/routes/dashboard-routes-component';
import { useEffect } from 'react';

function App() {
  const [auth] = useAuth();

  useEffect(() => {
    if (
      !auth.isAuthenticated &&
      !(
        publicRoutes.filter(
          (x) => x.routeProps.path === history.location.pathname
        ).length > 0
      )
    )
      history.push('/');
  }, [auth.isAuthenticated]);

  const renderApp = () => {
    if (auth.isAuthenticated) {
      return (
        <Dashboard>
          <DashboardRoutes />
        </Dashboard>
      );
    } else {
      return <PublicRoutes />;
    }
  };

  return <Router history={history}>{renderApp()}</Router>;
}

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
