import { GridRenderCellParams } from '@mui/x-data-grid';
import { CrudDataTable } from '../../../../components/crud-data-table';
import Dialog from '../../../../components/dialog/dialog';
import { CrudPageView, TypedGridColumns } from '../../../../interfaces/crud';
import { AppointmentResponse as Consult } from '../../../../interfaces/appointment';
import { historyPageTitle } from '../../history-page';
import { ModalityBadge } from '../../../../components/modality-badge';
import { AppointmentTypeBadge } from '../../../../components/appointment-type-badge';
import { formattedDateTime, fullName } from '../../../../helpers/helpers';

export default function HistoryCrudPageView({
  isLoading,
  data,
  onDeleteIconClick,
  onEditIconClick,
  onRowClick,
  showDialog,
  dialogVariant,
  onCloseDialog,
}: CrudPageView<Consult>) {
  const columns: TypedGridColumns<Consult>[] = [
    {
      field: 'specialtyId',
      headerName: 'Especialidade/Exame',
      flex: 1,
      renderCell: ({ row }) => row.specialty?.description ?? row.exam?.description,
    },
    {
      field: 'date',
      headerName: 'Data',
      flex: 1,
      renderCell: ({ row }) => formattedDateTime(row.date),
    },
    {
      field: 'typeId',
      headerName: 'Origem',
      flex: 1,
      renderCell: (cell) => <AppointmentTypeBadge appointmentTypeId={cell.row.typeId} />,
    },
    {
      field: 'modalityId',
      headerName: 'Urgência',
      flex: 1,
      renderCell: (cell: GridRenderCellParams) => {
        return <ModalityBadge modalityId={cell.row.modalityId} />;
      },
    },
    {
      field: 'doctorId',
      headerName: 'Médico',
      flex: 1,
      renderCell: ({ row }) => {
        console.log(row);
        return fullName(row?.doctor?.user);
      },
    },
    {
      field: 'completed',
      headerName: 'Concluído',
      flex: 1,
      renderCell: ({ row }) => {
        return row.completed ? 'Sim' : 'Não';
      },
    },
  ];

  return (
    <>
      <Dialog open={showDialog} variant={dialogVariant} onClose={onCloseDialog} />
      <CrudDataTable
        title={historyPageTitle}
        data={data}
        isLoading={isLoading}
        columns={columns}
        pageSize={15}
        handleRowClick={onRowClick}
        handleDeleteIconClick={onDeleteIconClick}
        handleEditIconClick={onEditIconClick}
        hideAddBtn={true}
        showActions={true}
        breadcrumbs={[{ label: 'Voltar' }]}
      />
    </>
  );
}
