import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Patient } from '../interfaces/patient';

export interface AppointmentState {
  patient?: Patient;
}

// Define the initial state using that type
const initialState: AppointmentState = {
  patient: undefined,
};

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState: initialState,
  reducers: {
    setPatient: (state, action: PayloadAction<Patient>) => {
      state.patient = action.payload;
    },
  },
});

export const {
  setPatient,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
