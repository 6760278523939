import * as yup from 'yup';
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator';
import { CrudFormModel } from '../../../components/form-v2/models/form-model';
import { DoctorResponse } from '../../../interfaces/doctor';
import { useGetSpecialtiesQuery } from '../../../services/options/options-service';

export class DoctorEditModel extends CrudFormModel {
  id: number = 0;

  @FormField({
    label: 'Nome',
    placeholder: 'Insira o primeiro nome',
    schema: yup.string().required(),
  })
  firstName: string = '';

  @FormField({
    label: 'Sobrenome',
    placeholder: 'Insira o sobrenome',
    schema: yup.string().required(),
  })
  lastName: string = '';

  @FormField({
    label: 'Documento',
    placeholder: 'Insira o documento de registro (Ex. CRM)',
    schema: yup.string().required(),
  })
  document: string = '';

  @FormField({
    label: 'Telefone',
    placeholder: 'Insira o telefone para contato',
    schema: yup.string().required(),
  })
  phone: string = '';

  @FormField({
    label: 'Email',
    placeholder: 'Email',
    schema: yup.string().email().required(),
    type: 'email',
  })
  email: string = '';

  @FormField({
    label: 'Especialidades',
    placeholder: 'Seleciona suas especialidades',
    schema: yup.array().required(),
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetSpecialtiesQuery()
      const toOptions = data ?? []
      return { data: toOptions, isLoading, isError }
    },
    type: 'select-multiple',
  })
  specialtyIds: number[] = [];

  userId: number = 0

  constructor(props?: DoctorResponse) {
    super()
    if (!props) return
    this.id = props.id
    this.firstName = props.user.firstName
    this.lastName = props.user.lastName
    this.phone = props.user.phone
    this.document = props.user.document
    this.email = props.user.email
    this.specialtyIds = props?.specialties?.map(x => x.id)
    this.userId = props.userId
  }

}
