import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getForm } from './decorators/form-field-decorator';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import Password from './components/password';
import { PasswordProvider } from './components/use-password';
import { FormModel } from './models/form-model';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import FormSelect from './components/form-select';
import FormDatePicker from './components/form-date-picker';
import FormDateTimePicker from './components/form-date-time-picker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useEffect, useState } from 'react';

const ReactInputMask = require('react-input-mask');

Yup.setLocale(ptForm);

interface FormV2Props {
  model: FormModel;
  submitLabel?: string;
  submit: (data: any) => void;
  reject?: (data: any) => void;
  rejectLabel?: string;
}

export default function FormV2(props: FormV2Props) {
  const { model, submitLabel, submit, rejectLabel, reject } = props;
  const { formFields, schema } = getForm(model);
  const defaultValues: any = { ...model };
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });
  const [formValues, setFormValues] = useState<any>(defaultValues);

  useEffect(() => {
    const subscription = watch((value) => setFormValues(value));
    return () => subscription.unsubscribe();
  }, []);

  const onSubmit = (data: any) => {
    submit(data);
  };

  const onReject = (data: any) => {
    if (reject) reject(data);
  };

  const hasError = (propName: string): boolean => {
    const checkErrors: any = { ...errors };
    return !!checkErrors[propName];
  };

  const getErrorMsg = (propName: string) => {
    const checkErrors: any = { ...errors };
    return checkErrors[propName]?.message;
  };

  const renderFields = () => {
    return (
      <>
        {formFields?.map((formField, index) => {
          if (formField.props.hidden && formField.props.hidden!(formValues)) {
            return <div key={formField.key}></div>;
          }

          const disabled =
            typeof formField.props.disabled === 'function'
              ? formField.props.disabled(formValues)
              : formField.props.disabled;

          switch (formField.props.type) {
            case 'password':
              return (
                <Controller
                  render={({ field }) => {
                    let passprops = { ...field };
                    // @ts-expect-error evitar foward ref
                    delete passprops?.ref;
                    return (
                      <Password
                        {...passprops}
                        placeholder={formField.props.placeholder}
                        label={formField.props.label}
                        fullWidth
                        error={hasError(formField.key)}
                        helperText={
                          hasError(formField.key)
                            ? getErrorMsg(formField.key)
                            : ''
                        }
                      />
                    );
                  }}
                  defaultValue=''
                  control={control}
                  name={formField.key as any}
                  key={index}
                />
              );

            case 'checkbox':
              return (
                <Controller
                  render={({ field }) => {
                    return (
                      <FormGroup
                        {...field}
                        defaultValue={field.value}
                        id={formField.key}>
                        {formField.props.label && (
                          <Typography>{formField.props.label}</Typography>
                        )}
                        {formField.props.options?.map((opt, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              name={formField.key}
                              label={opt.description}
                              control={
                                <Checkbox
                                  value={opt.value}
                                  defaultChecked={opt.default}
                                  disabled={opt.disabled}
                                />
                              }
                            />
                          );
                        })}
                      </FormGroup>
                    );
                  }}
                  defaultValue=''
                  control={control}
                  name={formField.key as any}
                  key={index}
                />
              );

            case 'select':
              return (
                <FormSelect
                  register={register}
                  multiple={false}
                  label={formField.props.label || ''}
                  placeholder={formField.props.placeholder || ''}
                  control={control}
                  name={formField.key}
                  key={formField.key + index}
                  values={formField.props.options || []}
                  error={hasError(formField.key)}
                  disabled={disabled}
                  lazyFunction={formField.props.lazyFunction}
                  helperText={
                    hasError(formField.key) ? getErrorMsg(formField.key) : ''
                  }
                />
              );

            case 'select-multiple':
              return (
                <FormSelect
                  register={register}
                  multiple={true}
                  label={formField.props.label || ''}
                  placeholder={formField.props.placeholder || ''}
                  control={control}
                  name={formField.key}
                  key={formField.key + index}
                  lazyFunction={formField.props.lazyFunction}
                  values={formField.props.options || []}
                  disabled={disabled}
                  error={hasError(formField.key)}
                  helperText={
                    hasError(formField.key) ? getErrorMsg(formField.key) : ''
                  }
                />
              );

            case 'date':
              return (
                <FormDatePicker
                  label={formField.props.label || ''}
                  control={control}
                  name={formField.key}
                  key={formField.key + index}
                  disabled={disabled}
                  error={hasError(formField.key)}
                  helperText={
                    hasError(formField.key) ? getErrorMsg(formField.key) : ''
                  }
                />
              );

            case 'date-time':
              return (
                <FormDateTimePicker
                  label={formField.props.label || ''}
                  control={control}
                  name={formField.key}
                  key={formField.key + index}
                  disabled={disabled}
                  error={hasError(formField.key)}
                  helperText={
                    hasError(formField.key) ? getErrorMsg(formField.key) : ''
                  }
                />
              );

            default:
              if (formField.props.mask) {
                return (
                  <Controller
                    render={({ field: { onChange, value } }) => {
                      return (
                        <ReactInputMask
                          mask={formField.props.mask}
                          maskChar={''}
                          disabled={disabled}
                          value={value}
                          onChange={onChange}>
                          {(inputProps: any) => (
                            <TextField
                              {...inputProps}
                              fullWidth
                              margin='normal'
                              placeholder={formField.props.placeholder}
                              label={formField.props.label}
                              error={hasError(formField.key)}
                              helperText={
                                hasError(formField.key)
                                  ? getErrorMsg(formField.key)
                                  : ''
                              }
                            />
                          )}
                        </ReactInputMask>
                      );
                    }}
                    defaultValue=''
                    control={control}
                    name={formField.key as any}
                    key={index}
                  />
                );
              }

              return (
                <TextField
                  {...register(formField.key as any)}
                  key={index}
                  fullWidth
                  margin='normal'
                  placeholder={formField.props.placeholder}
                  disabled={disabled}
                  label={formField.props.label}
                  error={hasError(formField.key)}
                  helperText={
                    hasError(formField.key) ? getErrorMsg(formField.key) : ''
                  }
                />
              );
          }
        })}
      </>
    );
  };

  return (
    <PasswordProvider>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {renderFields()}
          </LocalizationProvider>
        </Box>
        <Button type='submit' variant='contained' sx={{ mt: 3, mb: 2, px: 10 }}>
          {submitLabel ?? 'Enviar'}
        </Button>
        {rejectLabel && reject && (
          <Button
            onClick={() => onReject(formValues)}
            variant='contained'
            sx={{
              ml: 3,
              mt: 3,
              mb: 2,
              px: 10,
              backgroundColor: '#FF465C',
            }}>
            {rejectLabel}
          </Button>
        )}
      </form>
    </PasswordProvider>
  );
}
