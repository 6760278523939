import { useEffect, useState } from 'react';
import { CrudBehaviorRouteProps } from '../../../../components/crud-behavior/crud-behavior';
import { CrudForm } from '../../../../components/crud-form';
import {
  DialogProps,
  DialogVariantType,
} from '../../../../components/dialog/dialog';
import { isQueryCompleted } from '../../../../helpers/helpers';
import { Option } from '../../../../interfaces/options';
import { useOptions } from '../../context/OptionsContext';
import { OptionModel } from '../../model/option-model';

function PatientEditFormContainer(props: CrudBehaviorRouteProps) {
  const { title, update, updateState, fetchById, fetchByIdState, backPath } =
    useOptions();
  const {
    isLoading: updateIsLoading,
    isError: updateIsError,
    isSuccess: updateIsSuccess,
    status: updateStatus,
  } = updateState;

  const {
    data: fetchData,
    isLoading: fetchIsLoading,
    isError: fetchIsError,
  } = fetchByIdState;

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });
  const [model, setModel] = useState<OptionModel | undefined>(undefined);

  useEffect(() => {
    fetchById(Number(props.match.params.id));
  }, []);

  const handleSubmit = (formObj: Option) => {
    update(formObj);
  };

  useEffect(() => {
    if (!isQueryCompleted(updateStatus)) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: updateIsSuccess ? 'success' : ('error' as DialogVariantType),
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [updateIsSuccess, updateIsError, updateStatus]);

  useEffect(() => {
    if (!fetchIsError) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: 'error',
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [fetchIsError]);

  useEffect(() => {
    if (fetchData) setModel(new OptionModel(fetchData));
  }, [fetchData]);

  return (
    <CrudForm
      isLoading={updateIsLoading || fetchIsLoading}
      dialogProps={dialogProps}
      title={title}
      model={model}
      handleSubmit={handleSubmit}
      backPagePath={backPath}
    />
  );
}

export default PatientEditFormContainer;
