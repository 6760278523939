import { TextField } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { DesktopDatePicker } from '@mui/lab';
import { getDateFromISOorDate } from '../helpers/date';

export interface FormDateTimePickerProps {
  control: Control<FieldValues, object> | undefined;
  name: string;
  label: string;
  error: boolean;
  helperText: string;
  disabled?: boolean;
}

export default function FormDatePicker(props: FormDateTimePickerProps) {
  return (
    <Controller
      control={props.control}
      name={`${props.name}` as const}
      render={({ field: { onChange, value } }) => {
        const formattedValue: Date = getDateFromISOorDate(value);
        return (
          <DesktopDatePicker
            label={props.label}
            value={formattedValue}
            inputFormat='dd/MM/yyyy'
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                {...params}
                error={props.error}
                helperText={props.helperText}
                disabled={props.disabled}
                margin='normal'
                variant='outlined'
                id={`id-${props.name}`}
              />
            )}
          />
        );
      }}
    />
  );
}
