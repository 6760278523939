import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AppointmentResponse } from '../../interfaces/appointment';
import { Clinic } from '../../interfaces/clinic';
import { DoctorResponse } from '../../interfaces/doctor';
import { Patient, PatientResponse } from '../../interfaces/patient';
import { baseUrl } from '../base-service';

type ClinicId = number

export const clinicApi = createApi({
  reducerPath: 'clinicApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${baseUrl}/clinic` }),
  endpoints: (builder) => ({
    getClinics: builder.query<Clinic[], void>({
      query: () => `/`,
    }),
    getClinicsWithDoctors: builder.query<Clinic[], void>({
      query: () => `/findAllWithDoctors`,
    }),
    getClinicDoctors: builder.query<DoctorResponse[], ClinicId>({
      query: (id) => `/${id}/doctors`,
    }),
    getClinicPatients: builder.query<Patient[], ClinicId>({
      query: (id) => `/${id}/patients`,
      transformResponse: (response: PatientResponse[]) => {
        const mapped = response.map(x => {
          const { user, doctor, ...otherData } = x
          return {
            ...user,
            ...otherData,
            doctorId: doctor?.id
          } as Patient
        })
        return mapped
      }
    }),
    getClinicAppointments: builder.query<AppointmentResponse[], { clinicId: number, scheduled?: boolean }>({
      query: ({ clinicId, scheduled }) => ({ url: `/${clinicId}/appointments`, params: { scheduled: scheduled ?? false } }),
    }),
    assignDoctor: builder.mutation<Clinic, { clinicId: number, doctorId: number }>({
      query(body) {
        return {
          url: `/assign/doctor`,
          method: 'POST',
          body: body,
        };
      },
    }),
    removeDoctor: builder.mutation<Clinic, { clinicId: number, doctorId: number }>({
      query(body) {
        return {
          url: `/remove/doctor`,
          method: 'POST',
          body: body,
        };
      },
    }),
  }),
});


export const {
  useGetClinicDoctorsQuery,
  useLazyGetClinicDoctorsQuery,
  useGetClinicAppointmentsQuery,
  useGetClinicPatientsQuery,
  useLazyGetClinicPatientsQuery,
  useAssignDoctorMutation,
  useLazyGetClinicAppointmentsQuery,
  useGetClinicsQuery,
  useRemoveDoctorMutation,
  useGetClinicsWithDoctorsQuery
} = clinicApi;
