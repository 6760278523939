import Grid from '@mui/material/Grid';
import CardAction, { CardActionProps } from '../../../../components/card-action/card-item';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../hooks';
import { UserProfile } from '../../../../store/auth';
import { useGetPatientByIdQuery } from '../../../../services/patient/patient-service';
import { Box, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Breadcrumbs } from '../../../../components/breadcrumbs';
import { diagnosticCard, editPatientCard, historyCard, newRequestCard, treatmentCard } from '../../../../assets/images/patients';

export default function PatientHome() {
  const { id } = useParams<{ id: string }>();
  const { data: patientData } = useGetPatientByIdQuery(+id);
  const [auth] = useAuth();

  const appointmentLink = () => {
    const destiny = auth.profile !== UserProfile.LAB ? 'consult' : 'exams';
    return `/${destiny}/new`;
  };

  const items: CardActionProps[] = [
    {
      imageSrc: editPatientCard,
      link: `/patient/${id}`,
      title: 'Editar paciente',
      subtitle: '',
    },
    {
      imageSrc: newRequestCard,
      link: appointmentLink(),
      title: 'Nova Solicitação',
      subtitle: '',
    },
    {
      imageSrc: treatmentCard,
      link: `/patient/${id}/treatments`,
      title: 'Tratamentos',
      subtitle: '',
    },
    {
      imageSrc: diagnosticCard,
      link: `/patient/${id}/diagnostics`,
      title: 'Diagnósticos',
      subtitle: '',
    },
    {
      imageSrc: historyCard,
      link: `/history/${id}`,
      title: 'Histórico',
      subtitle: '',
    },
  ];

  return (
    <>
      <PageTitle>{`${patientData?.firstName} ${patientData?.lastName}`}</PageTitle>
      <Grid container spacing={3}>
        {items.map((item, index) => {
          return (
            <Grid item xs={4} key={index}>
              <CardAction {...item}></CardAction>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

const PageTitle: React.FC = ({ children }) => {
  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Breadcrumbs
          items={[
            {
              label: 'Voltar',
            },
          ]}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', rowGap: 1 }}>
        <Typography variant="h5" component={Box} sx={{ pt: 2, color: (theme) => theme.palette.primary.main, p: 0, cursor: 'pointer' }}>
          Pacientes
        </Typography>
        <KeyboardArrowRightIcon sx={{ color: 'gray' }} />
        <Typography variant="h5" component={Box} sx={{ pt: 2, color: (theme) => theme.palette.primary.main, fontWeight: 'bold', p: 0 }}>
          {children}
        </Typography>
      </Box>
    </>
  );
};
