import { GridRenderCellParams, GridState } from '@mui/x-data-grid';
import { CrudDataTable } from '../../../../components/crud-data-table';
import Dialog from '../../../../components/dialog/dialog';
import { CrudPageView, TypedGridColumns } from '../../../../interfaces/crud';
import { AppointmentResponse as Consult } from '../../../../interfaces/appointment';
import { scheduledPageTitle } from '../../scheduled-page';
import { Patient } from '../../../../interfaces/patient';
import { ModalityBadge } from '../../../../components/modality-badge';
import { AppointmentTypeBadge } from '../../../../components/appointment-type-badge';
import { formattedDateTime } from '../../../../helpers/helpers';
import { UserProfile } from '../../../../store/auth';
import React from 'react';

export default function ConsultCrudPageView({
  isLoading,
  data,
  onDeleteIconClick,
  onEditIconClick,
  onRowClick,
  showDialog,
  dialogVariant,
  onCloseDialog,
  dialogSubtitle,
  profile,
}: CrudPageView<Consult> & {
  patients: Patient[];
  dialogSubtitle: string;
  profile?: string;
}) {
  const columns = (): TypedGridColumns<Consult>[] => {
    const adminColumns: TypedGridColumns<Consult>[] =
      profile === UserProfile.ADMIN
        ? [
            {
              field: 'id',
              headerName: 'Clinica/Lab',
              flex: 1,
              renderCell: ({ row }) => {
                const clinic = row.doctor?.clinics && row.doctor?.clinics.length ? row.doctor?.clinics[0].user.firstName : undefined;
                return `${clinic ?? row.laboratory?.user?.firstName ?? 'Não identificado'}`;
              },
            },
          ]
        : [];
    return [
      {
        field: 'patientId',
        headerName: 'Nome',
        flex: 1,
        renderCell: ({ row }) => {
          return `${row.patient?.user?.firstName} ${row.patient?.user?.lastName}`;
        },
      },
      {
        field: profile === UserProfile.LAB ? 'examId' : 'specialtyId',
        headerName: profile === UserProfile.LAB ? 'Exame' : 'Especialidade',
        flex: 1,
        renderCell: ({ row }) => row.specialty?.description ?? row.exam?.description,
      },
      {
        field: 'date',
        headerName: 'Data',
        flex: 1,
        renderCell: ({ row }) => formattedDateTime(row.date),
      },
      {
        field: 'typeId',
        headerName: 'Origem',
        flex: 1,
        renderCell: (cell) => <AppointmentTypeBadge appointmentTypeId={cell.row.typeId} />,
      },
      {
        field: 'modalityId',
        headerName: 'Urgência',
        flex: 1,
        renderCell: (cell: GridRenderCellParams) => {
          return <ModalityBadge modalityId={cell.row.modalityId} />;
        },
      },
      {
        field: 'completed',
        headerName: 'Concluído',
        flex: 1,
        renderCell: ({ row }) => {
          return row.completed ? 'Sim' : 'Não';
        },
      },
      ...adminColumns,
    ];
  };

  return (
    <>
      <Dialog open={showDialog} variant={dialogVariant} subtitle={dialogSubtitle} onClose={onCloseDialog} />
      <CrudDataTable
        title={scheduledPageTitle}
        data={data}
        isLoading={isLoading}
        columns={columns()}
        pageSize={15}
        handleRowClick={onRowClick}
        handleDeleteIconClick={onDeleteIconClick}
        handleEditIconClick={onEditIconClick}
        hideAddBtn={true}
        showActions={true}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: 'modalityId',
                sort: 'asc',
              },
            ],
          },
        }}
      />
    </>
  );
}
