import { QueryStatus } from '@reduxjs/toolkit/dist/query/react';
import { useEffect, useState } from 'react';
import { DialogVariantType } from '../../../../components/dialog/dialog';
import { useDeleteConsultMutation } from '../../../../services/consult/consult-service';
import ConsultCrudPageView from './consult-list';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../../hooks';
import { useGetAppointments } from '../../../../hooks/use-get-appointments';
import { UserProfile } from '../../../../store/auth';

export default function ConsultListContainer() {
  const pollingInterval = 5000;
  const history = useHistory();
  const [auth] = useAuth();

  const {
    data,
    isLoading: fetchIsLoading,
    refetch,
  } = useGetAppointments(auth.clinic ?? 0, false, pollingInterval);
  const [deleteApi, deleteMutationState] = useDeleteConsultMutation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogVariant, setDialogVariant] =
    useState<DialogVariantType>('success');

  const isMutationCompleted =
    deleteMutationState.status === QueryStatus.fulfilled ||
    deleteMutationState.status === QueryStatus.rejected;

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isMutationCompleted) {
      setDialogVariant(deleteMutationState.isSuccess ? 'success' : 'error');
      setShowDialog(true);

      if (deleteMutationState.error) {
        console.error(
          'endpointName',
          deleteMutationState.endpointName,
          'originalArgs',
          deleteMutationState.originalArgs,
          '',
          deleteMutationState.error
        );
      }
    }
  }, [
    isMutationCompleted,
    deleteMutationState.isSuccess,
    deleteMutationState.error,
    deleteMutationState.endpointName,
    deleteMutationState.originalArgs,
  ]);

  const isLoading = () => fetchIsLoading || deleteMutationState.isLoading;

  const onDelete = (id: number) => {
    deleteApi(id).then(() => {
      refetch();
    });
  };

  const onRowClick = ({ row }: any) => {
    const isExam = !!row.examId;
    console.log('row', row);
    console.log('destinty', `${isExam ? 'exams' : 'consult'}/${row.id}`);
    history.push(`${isExam ? 'exams' : 'consult'}/${row.id}`);
  };

  return (
    <ConsultCrudPageView
      pageTitle={auth.profile === UserProfile.LAB ? 'Exames' : 'Procedimentos'}
      onCloseDialog={() => setShowDialog(false)}
      showDialog={showDialog}
      dialogVariant={dialogVariant}
      isLoading={isLoading()}
      data={data}
      onDeleteIconClick={onDelete}
      onRowClick={onRowClick}
      isLab={auth.profile === UserProfile.LAB}
      showActions={auth.profile === UserProfile.ADMIN}

    />
  );
}
