import { ValidatorForm } from 'react-material-ui-form-validator';

ValidatorForm.addValidationRule('isCnpj', (value: string): boolean => {
  if (!value) return false;

  return /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(value);
});

ValidatorForm.addValidationRule('isCpf', (value: string): boolean => {
  if (!value) return false;
  return /^\d{3}\.\d{3}\.\d{3}-\d{2}$/.test(value);
});

ValidatorForm.addValidationRule('isPasswordStrong', (value: string): boolean => {
  if (!value) return false;
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(value);
});
