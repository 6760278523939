import React, { useContext, useState } from "react";

interface PasswordContextData {
  showPassword: boolean;
  togglePassword: (value: boolean) => void
}

interface PasswordProviderProps {
  children: React.ReactNode
}

const PasswordContext = React.createContext<PasswordContextData>({} as PasswordContextData);

export function PasswordProvider({ children }: PasswordProviderProps) {

  const [showPassword, togglePassword] = useState<boolean>(false)

  return <PasswordContext.Provider value={{
    showPassword, togglePassword
  }}>
    {children}
  </PasswordContext.Provider>
}

export function usePassword() {
  const context = useContext(PasswordContext)
  return context
}