import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Dialog from '../../components/dialog/dialog'
import { useAppDispatch } from '../../hooks'
import { usePasswordResetMutation } from '../../services/cognito/cognito-service'
import { PasswordResetRequest } from '../../services/cognito/interfaces/password'
import { resetPasswordFirebase } from '../../services/firebase/firebaseService'
import { setEmail } from '../../store/password'
import PasswordResetView from './password-reset-view'

export default function PasswordResetContainer() {
	const [api, { isLoading }] = usePasswordResetMutation()
	const dispatch = useAppDispatch()
	const history = useHistory()
	const [openDialog, setOpenDialog] = useState(false)

	const resetPasswordWithEmailFirebase = (request: PasswordResetRequest) => {
		resetPasswordFirebase(request.email)
		setOpenDialog(true)
	}

	const resetPassword = (request: PasswordResetRequest) => {
		api(request)
			.unwrap()
			.then(() => {
				dispatch(setEmail(request.email))
				alert('Senha enviada com sucesso!')

				history.push('/reset-password/change')
			})
			.catch((e) => console.error(e))
	}

	return (
		<>
			<PasswordResetView
				handleSubmit={resetPasswordWithEmailFirebase}
				isLoading={isLoading}
			/>
			<Dialog
				variant='success'
				open={openDialog}
				subtitle='Link de recuperação enviado para caixa de entrada!'
				onOk={() => {
					setOpenDialog(!openDialog)
					history.push('/')
				}}
			/>
		</>
	)
}
