import { ModalityTypes } from "../../interfaces/options";

export function FakeModality(): ModalityTypes[] {
  return [
    { id: 1, value: 1, description: 'Alto suspeito (1 dia)' },
    { id: 2, value: 3, description: 'Suspeito (3 dias)' },
    { id: 3, value: 30, description: 'Screening (30 dias)' },
    { id: 4, value: 2, description: 'Exames e laudos (2 dias)' },
    { id: 5, value: 365, description: 'Retorno anual screening (365 dias)' },
  ]
}