import { withCrudBehavior } from '../../components/crud-behavior/crud-behavior';
import { OptionsAddForm } from './components/options-add-form';
import { OptionsEditForm } from './components/options-edit-form';
import { OptionsList } from './components/options-list';

const OptionsPage = withCrudBehavior({
  entityCreateFormComponent: OptionsAddForm,
  entityEditFormComponent: OptionsEditForm,
  entityListComponent: OptionsList,
});

export default OptionsPage;
