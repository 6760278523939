import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { filterArrayByTerm } from "../../helpers/helpers";
import { useDebounce } from "../../hooks/use-debounce";
import { useStyles } from "./crud-data-table-styles";


export const useCrudDataTable = (data: any[]) => {
  const styles = useStyles();
  const history = useHistory();

  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState<any[]>(data);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const debouncedSearchValue = useDebounce(searchText, 800);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    const filteredRows = filterArrayByTerm(data, debouncedSearchValue);
    setRows(filteredRows);
  }, [debouncedSearchValue, data]);

  return {
    styles,
    history,
    searchText,
    setSearchText,
    rows,
    showDialog, setShowDialog,
  }
}