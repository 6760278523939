import * as yup from 'yup';
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator';
import { CrudFormModel } from '../../../components/form-v2/models/form-model';
import { useGetCidsQuery, useGetTreatmentTypesQuery } from '../../../services/options/options-service';
import { defaultTimeZone } from '../../../helpers/helpers';
import moment from 'moment';
import { Diagnostic } from '../../../interfaces/diagnostic';

export class DiagnosticModel extends CrudFormModel {
  id: number | undefined;

  @FormField({
    label: 'Cid',
    placeholder: 'Seleciona um cid',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetCidsQuery()

      return { data: data ?? [], isLoading, isError }
    },
    schema: yup.number().required(),
    type: 'select',
  })
  cid: number = 1

  patient: number = 0;

  @FormField({
    label: 'Data',
    placeholder: 'Insira a data e hora de atentimento',
    type: 'date-time',
    schema: yup.string().test('dateValidation', 'A data está inválida, utilize o seletor de data para selecionar', function (value) {
      return value?.toString().toLocaleLowerCase() != 'invalid date'
    }),
  })
  date: string

  constructor(props?: Partial<Diagnostic>) {
    super()
    this.id = props?.id
    console.log('patientId', props?.patientId)
    this.patient = props?.patientId!
    this.date = moment(props?.date ?? '').tz(defaultTimeZone).format('DD/MM/yyyy HH:mm')
    this.cid = props?.cidId!
  }
}
