import { CrudDataTable } from '../../../../components/crud-data-table';
import Dialog from '../../../../components/dialog/dialog';
import { CrudPageView, TypedGridColumns } from '../../../../interfaces/crud';
import { User } from '../../../../interfaces/user';

import { accessPageTitle } from '../../access-page';

const columns: TypedGridColumns<User>[] = [
  {
    field: 'firstName',
    headerName: 'Nome',
    flex: 1,
    renderCell: (cell) =>
      `${cell.row.firstName ?? ''} ${cell.row.lastName ?? ''}`,
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
  },
  {
    field: 'document',
    headerName: 'CNPJ',
    flex: 1,
  },
  {
    field: 'active',
    headerName: 'Status',
    flex: 1,
    renderCell: ({ row }) => {
      return row.active ? 'Ativo' : 'Inativo';
    },
  },
  {
    field: 'profile',
    headerName: 'Perfil',
    flex: 1,
    renderCell: (cell) => {
      if (cell.row.profile === 'admin') return 'Administrador';
      if (cell.row.profile === 'lab') return 'Laboratório';
      if (cell.row.profile === 'clinic') return 'Clinica';
      if (cell.row.profile === 'doctor') return 'Médico';
    },
  },
];

export default function AccessCrudPageView({
  isLoading,
  data,
  onDeleteIconClick,
  onEditIconClick,
  onRowClick,
  showDialog,
  dialogVariant,
  onCloseDialog,
}: CrudPageView<User>) {
  return (
    <>
      <Dialog
        open={showDialog}
        variant={dialogVariant}
        onClose={onCloseDialog}
      />
      <CrudDataTable
        title={accessPageTitle}
        data={data}
        isLoading={isLoading}
        columns={columns}
        pageSize={15}
        handleRowClick={onRowClick}
        handleDeleteIconClick={onDeleteIconClick}
        handleEditIconClick={onEditIconClick}
        showActions
      />
    </>
  );
}
