import { QueryStatus } from '@reduxjs/toolkit/dist/query/react';
import { useEffect, useState } from 'react';
import { DialogVariantType } from '../../../../components/dialog/dialog';
import { useAppDispatch, useAuth } from '../../../../hooks';
import { setPatient } from '../../../../store/appointment';
import { useDeletePatientMutation } from '../../../../services/patient/patient-service';
import PatientCrudPageView from './patient-list';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { useStyles } from '../../../../components/crud-data-table/crud-data-table-styles';
import AddIcon from '@mui/icons-material/Add';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useGetPatientsQueryByProfile } from '../../../../hooks/use-get-patients';
import { UserProfile } from '../../../../store/auth';

export default function PatientListContainer() {
  const [auth] = useAuth();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const getQueryState = useGetPatientsQueryByProfile();
  const styles = useStyles();

  useEffect(() => {
    console.log(getQueryState);
  }, [getQueryState.isLoading]);

  const [deleteApi, deleteMutationState] = useDeletePatientMutation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogVariant, setDialogVariant] =
    useState<DialogVariantType>('success');

  const isMutationCompleted =
    deleteMutationState.status === QueryStatus.fulfilled ||
    deleteMutationState.status === QueryStatus.rejected;

  useEffect(() => {
    if (isMutationCompleted) {
      setDialogVariant(deleteMutationState.isSuccess ? 'success' : 'error');
      setShowDialog(true);

      if (deleteMutationState.error) {
        console.error(
          'endpointName',
          deleteMutationState.endpointName,
          'originalArgs',
          deleteMutationState.originalArgs,
          '',
          deleteMutationState.error
        );
      }
    }
  }, [
    isMutationCompleted,
    deleteMutationState.isSuccess,
    deleteMutationState.error,
    deleteMutationState.endpointName,
    deleteMutationState.originalArgs,
  ]);

  const isLoading = () =>
    getQueryState.isLoading || deleteMutationState.isLoading;

  const onDelete = (id: number) => {
    deleteApi(id).then(() => {
      // getQueryState();
    });
  };

  const onRowClick = (row: any) => {
    dispatch(setPatient(row.row));
    // history.push(`/history/${row.row.id}`);
    history.push(`/patient-home/${row.row.id}`);
  };

  return (
    <PatientCrudPageView
      onCloseDialog={() => setShowDialog(false)}
      showDialog={showDialog}
      dialogVariant={dialogVariant}
      isLoading={isLoading()}
      data={getQueryState.data ?? []}
      onDeleteIconClick={onDelete}
      onRowClick={(row) => onRowClick(row)}
      hideAddBtn={auth.profile === UserProfile.LAB}
      showActions={auth.profile !== UserProfile.LAB}
      onEditIconClick={(row) => onRowClick(row)}
    />
  );
}
