import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export interface DialogProps {
  open: boolean;
  onClose?: () => void;
  onOk?: () => void;
  title?: string;
  subtitle?: string;
  description?: string;
  variant?: DialogVariantType;
}

export type DialogVariantType = 'confirm' | 'alert' | 'success' | 'error';

export default function Dialog({
  open,
  title,
  subtitle,
  description,
  onClose,
  onOk,
  variant = 'alert',
}: DialogProps) {
  const renderTitle = () => {
    if (title) return title;
    switch (variant) {
      case 'confirm':
        return 'Confirmar?';
      case 'success':
        return 'Sucesso!';
      case 'error':
        return 'Ocorreu um erro!';
      default:
        return 'Aviso!';
    }
  };

  const renderSubtitle = () => {
    if (subtitle) return subtitle;
    switch (variant) {
      case 'confirm':
        return 'Deseja realmente salvar as alteraçoes?';
      case 'success':
        return 'Operação realizada com sucesso.';
      case 'error':
        return 'Ocorreu um erro ao realizar a operação, contate o administrador.';
      default:
        return 'Esta ação não pode ser desfeita, deseja prosseguir?';
    }
  };

  const renderDescription = () => {
    if (description) return description;
  };

  const renderOkText = () => {
    switch (variant) {
      case 'error':
      case 'success':
        return 'Ok';
      default:
        return 'Sim';
    }
  };

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{renderTitle()}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {renderSubtitle()}
          <br />
          {renderDescription()}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
          px: 2,
          pb: 2,
        }}>
        <Button
          variant='contained'
          onClick={() => {
            if (onOk) onOk();
            if (onClose) onClose();
          }}>
          {renderOkText()}
        </Button>
        {variant !== 'success' && variant !== 'error' && (
          <Button
            variant='contained'
            onClick={() => {
              if (onClose) onClose();
            }}>
            Não
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
}
