import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { Option } from '../../../interfaces/options';

export interface FormSelectProps {
  control: Control<FieldValues, object> | undefined;
  name: string;
  values: Option[];
  label: string;
  placeholder: string;
  multiple: boolean;
  register: any;
  error: boolean;
  helperText: string;
  disabled?: boolean;

  /**
   *
   * @lazyFunction will replace values if has one
   */
  lazyFunction?: () => () => {
    isLoading: boolean;
    data: any[];
    isError: boolean;
  };
}

function getDescriptionById(values: Option[], id: number) {
  return values.find((el) => el.id === id)?.description || '';
}

const FormSelect = (props: FormSelectProps) => {
  let lazy: any = undefined;
  if (props.lazyFunction) lazy = props.lazyFunction();
  else lazy = () => ({ isLoading: false, data: undefined, isError: false });

  const { isLoading, data, isError } = lazy();

  const options: any[] = data ?? props.values;

  return (
    <Controller
      control={props.control}
      name={`${props.name}` as const}
      defaultValue={props.multiple ? [] : 0}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            select
            id={`id-${props.name}`}
            variant='outlined'
            label={props.label}
            margin='normal'
            error={props.error || isError}
            helperText={
              isError ? 'Erro ao obter dados do servidor.' : props.helperText
            }
            disabled={props.disabled || isLoading}
            SelectProps={{
              multiple: props.multiple,
              value: value,
              placeholder: props.placeholder,
              renderValue: (selected: any) =>
                props.multiple
                  ? options
                      .filter((x) => selected.includes(x.id))
                      .map((x) => x.description)
                      .join(', ')
                  : getDescriptionById(options, selected),
              onChange: onChange,
            }}>
            {options.map((n) => {
              return (
                <MenuItem key={`id.${n.id}`} value={n.id}>
                  <ListItemText primary={n.description} />
                </MenuItem>
              );
            })}
          </TextField>
        );
      }}
    />
  );
};

export default FormSelect;
