import { AccessPage } from '../../../pages/access';
import { ConsultPage } from '../../../pages/consult';
import { DoctorPage } from '../../../pages/doctor';
import Home from '../../../pages/home/home';
import { Onboarding } from '../../../pages/onboarding';
import { PasswordChangePage } from '../../../pages/password-change';
import { PasswordReset } from '../../../pages/password-reset';
import { PatientPage } from '../../../pages/patient';
import ScheduledPage from '../../../pages/scheduled/scheduled-page';
import Signout from '../../../pages/signout/signout';
import IRoute from '../interfaces/route';
import { ReactComponent as HomeIcon } from '../../../assets/images/menu/home.svg';
import { ReactComponent as DoctorIcon } from '../../../assets/images/menu/doctor.svg';
import { ReactComponent as AppointmentIcon } from '../../../assets/images/menu/procedures.svg';
import { ReactComponent as PatientIcon } from '../../../assets/images/menu/patient.svg';
import { ReactComponent as ScheduleIcon } from '../../../assets/images/menu/schedule.svg';
import { ReactComponent as AccessIcon } from '../../../assets/images/menu/access.svg';
import { ReactComponent as ReportIcons } from '../../../assets/images/menu/report.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/images/menu/exit.svg';
import { ReactComponent as BuildIcon } from '../../../assets/images/menu/admin.svg';

import { Signin } from '../../../pages/signin';
import { UserProfile } from '../../../store/auth';
import { HistoryPage } from '../../../pages/history';
import { store } from '../../../store';
import AdminZone from '../../../pages/admin-zone/admin-zone';
import { CidPage } from '../../../pages/cid';
import { SpecialtiesPage } from '../../../pages/specialties';
import { ExamsPage } from '../../../pages/exams';
import { PatientHome } from '../../../pages/patient/components/patient-home';
import TreatmentPage from '../../../pages/treatment/treatment-page';
import DiagnosticPage from '../../../pages/diagnostic/diagnostic-page';

const publicRoutes: IRoute[] = [
  {
    isPublic: true,
    routeProps: {
      exact: true,
      path: '/',
      component: Signin,
    },
  },
  {
    isPublic: true,
    routeProps: {
      exact: true,
      path: '/reset-password',
      component: PasswordReset,
    },
  },
  {
    isPublic: true,
    routeProps: {
      exact: true,
      path: '/onboarding',
      component: Onboarding,
    },
  },
  {
    isPublic: true,
    routeProps: {
      path: '/reset-password/change',
      component: PasswordChangePage,
    },
  },
];

const privateRoutes: IRoute[] = [
  {
    panel: {
      enabled: true,
      icon: <HomeIcon />,
      name: 'Início',
    },
    routeProps: {
      exact: true,
      path: '/home',
      component: Home,
    },
  },
  {
    panel: {
      enabled: true,
      icon: <DoctorIcon />,
      name: 'Médicos',
    },
    accessControl: (profile: string) => profile === UserProfile.CLINIC,
    routeProps: {
      path: '/doctor',
      component: DoctorPage,
    },
  },
  {
    panel: {
      enabled: true,
      icon: <DoctorIcon />,
      name: 'Médicos',
    },
    accessControl: (profile: string) => profile === UserProfile.ADMIN,
    routeProps: {
      path: '/doctor',
      component: DoctorPage,
    },
  },
  {
    panel: {
      enabled: true,
      icon: <AppointmentIcon />,
      name: 'Procedimentos',
    },
    accessControl: (profile: string) => profile === UserProfile.CLINIC || profile === UserProfile.ADMIN || profile === UserProfile.DOCTOR,
    routeProps: {
      path: '/consult',
      component: ConsultPage,
    },
  },
  {
    panel: {
      lazyEnabled: () => store.getState().auth.profile !== UserProfile.ADMIN,
      icon: <AppointmentIcon />,
      name: 'Exames',
    },
    accessControl: (profile: string) => profile === UserProfile.LAB || profile === UserProfile.ADMIN,
    routeProps: {
      path: '/exams',
      component: ConsultPage,
    },
  },
  {
    panel: {
      icon: undefined,
      name: 'Tratamentos',
    },
    routeProps: {
      path: '/patient/:patientId/treatments',
      component: TreatmentPage,
    },
  },
  {
    panel: {
      icon: undefined,
      name: 'Diagnosticos',
    },
    routeProps: {
      path: '/patient/:patientId/diagnostics',
      component: DiagnosticPage,
    },
  },
  {
    panel: {
      enabled: true,
      icon: <PatientIcon />,
      name: 'Pacientes',
    },
    routeProps: {
      path: '/patient',
      component: PatientPage,
    },
  },
  {
    routeProps: {
      path: '/patient-home/:id',
      exact: true,
      component: PatientHome,
    },
  },

  {
    panel: {
      icon: undefined,
      name: 'Cids',
    },
    accessControl: (profile: string) => profile === UserProfile.ADMIN,
    routeProps: {
      path: '/cid',
      component: CidPage,
    },
  },
  {
    panel: {
      icon: undefined,
      name: 'Especialidades',
    },
    accessControl: (profile: string) => profile === UserProfile.ADMIN,
    routeProps: {
      path: '/specialties',
      component: SpecialtiesPage,
    },
  },
  {
    panel: {
      icon: undefined,
      name: 'Exames',
    },
    accessControl: (profile: string) => profile === UserProfile.ADMIN,
    routeProps: {
      path: '/exams-types',
      component: ExamsPage,
    },
  },
  {
    panel: {
      enabled: false,
      icon: undefined,
      name: 'Histórico do paciente',
    },
    routeProps: {
      path: '/history/:patientId',
      component: HistoryPage,
    },
  },
  {
    panel: {
      enabled: true,
      icon: <ReportIcons />,
      name: 'Relatórios',
    },
    accessControl: (profile: string) => profile === UserProfile.ADMIN,
    routeProps: {
      path: '/reports',
      component: () => <>Página de relatórios em construção...</>,
    },
  },
  {
    panel: {
      enabled: true,
      icon: <ScheduleIcon />,
      name: 'Agendados',
    },
    routeProps: {
      path: '/scheduled',
      component: ScheduledPage,
    },
  },
  {
    panel: {
      enabled: true,
      icon: <AccessIcon />,
      name: 'Acessos',
    },
    accessControl: (profile: string) => profile === UserProfile.ADMIN,
    routeProps: {
      path: '/access',
      component: AccessPage,
    },
  },
  {
    panel: {
      enabled: true,
      icon: <BuildIcon />,
      name: 'Administração',
    },
    accessControl: (profile: string) => profile === UserProfile.ADMIN,
    routeProps: {
      path: '/admin-zone',
      component: AdminZone,
    },
  },
  {
    panel: {
      enabled: true,
      icon: <LogoutIcon fontSize="large" />,
      name: 'Sair',
    },
    routeProps: {
      path: '/logout',
      component: Signout,
    },
  },
];

export const routes: IRoute[] = [...publicRoutes, ...privateRoutes];
