import Box from '@mui/material/Box';
import styled from 'styled-components';

const CrudDataTableBadge = styled(Box)`
  background: ${(props: any) => props.color};
  border-radius: 25px;
  color: white;
  height: 50%;
  text-align: center;
  margin-top: 12px;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 10px !important;
`;

export default CrudDataTableBadge;
