import React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Logo from '../../assets/images/logo.svg';
import LoginImage from '../../assets/images/login-img.svg';
import { SpacerV } from '../spacer/spacer';
import { Step, StepLabel, Stepper } from '@mui/material';
import {
  OnboardingTypo,
  QontoConnector,
  LeftSide,
  RightSide,
} from './onboarding-scaffold-styles';
import { Spinner } from '../spinner';
import Breadcrumbs, { BreadcrumbItem } from '../breadcrumbs/breadcrumbs';

interface Props {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  currentStep?: number;
  breadcrumb?: BreadcrumbItem[];
  isLoading?: boolean;
}

const OnboardingScaffold: React.FunctionComponent<Props> = ({
  title,
  subtitle,
  currentStep,
  children,
  breadcrumb,
  isLoading,
}) => {
  const renderStepper = () => {
    return (
      <Box
        sx={{
          mb: 3,
        }}>
        <Stepper activeStep={currentStep! - 1} connector={<QontoConnector />}>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
          <Step>
            <StepLabel></StepLabel>
          </Step>
        </Stepper>
      </Box>
    );
  };

  const renderBreadcrumb = () => {
    return (
      <Box
        sx={{
          px: 4,
        }}>
        <Breadcrumbs items={breadcrumb!} />
      </Box>
    );
  };

  return (
    <Grid container component='main' sx={{ height: '100vh' }}>
      {isLoading && <Spinner fullScreen />}
      <Grid item xs={6}>
        <LeftSide>
          <Box className='content'>
            <Box
              sx={{
                height: '126px',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}>
              <img src={Logo} alt='Logo' height={'126px'} />
            </Box>
            <SpacerV size={8}></SpacerV>
            <Typography variant='h5'>
              Gerenciando a saúde com o <br />
              <b>carinho</b> que ela merece.
            </Typography>
            <SpacerV size={8}></SpacerV>
            <Box
              sx={{
                height: '276px',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}>
              <img src={LoginImage} alt='two doctors' height={'276px'} />
            </Box>
          </Box>
        </LeftSide>
      </Grid>
      <Grid item xs={6} component={Paper} elevation={6} square>
        <RightSide>
          {breadcrumb ? renderBreadcrumb() : null}

          {currentStep ? renderStepper() : <SpacerV size={4} />}

          <Box component='div' sx={{ mt: 1, px: 4, width: '100%' }}>
            <OnboardingTypo variant='h5' gutterBottom>
              {title}
            </OnboardingTypo>
            <OnboardingTypo variant='caption' gutterBottom>
              {subtitle}
            </OnboardingTypo>
            {children}
          </Box>
        </RightSide>
      </Grid>
    </Grid>
  );
};

export default OnboardingScaffold;
