import { useEffect, useState } from 'react';
import { CrudBehaviorRouteProps } from '../../../../components/crud-behavior/crud-behavior';
import {
  DialogProps,
  DialogVariantType,
} from '../../../../components/dialog/dialog';
import {
  isQueryCompleted,
  removeEmptyProps,
} from '../../../../helpers/helpers';
import { useAppSelector } from '../../../../hooks';
import { AppointmentEditResquest } from '../../../../interfaces/appointment';
import {
  useGetConsultByIdQuery,
  useUpdateConsultMutation,
} from '../../../../services/consult/consult-service';
import { useLazyGetPatientByIdQuery } from '../../../../services/patient/patient-service';
import { ScheduledEditModel } from '../../model/scheduled-edit-model';
import ScheduledFormView from '../scheduled-form-view/scheduled-form-view';

function ScheduledEditFormContainer(props: CrudBehaviorRouteProps) {
  const userId = useAppSelector(({ auth }) => auth.userId);
  const [patientsApi, { isLoading: patientIsLoading, data: patientData }] =
    useLazyGetPatientByIdQuery();

  const [
    updateApi,
    {
      isLoading: updateIsLoading,
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      status: updateStatus,
    },
  ] = useUpdateConsultMutation();

  const {
    data: fetchData,
    isLoading: fetchIsLoading,
    isError: fetchIsError,
    refetch,
  } = useGetConsultByIdQuery(Number(props.match.params.id));

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });
  const [model, setModel] = useState<ScheduledEditModel | undefined>(undefined);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!fetchIsError) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: 'error',
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [fetchIsError]);

  useEffect(() => {
    if (!isQueryCompleted(updateStatus)) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: updateIsSuccess ? 'success' : ('error' as DialogVariantType),
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [updateIsSuccess, updateIsError, updateStatus]);

  useEffect(() => {
    if (fetchData) {
      patientsApi(fetchData.patientId);
    }
  }, [fetchData, userId, patientsApi]);

  useEffect(() => {
    if (patientData) {
      setModel(new ScheduledEditModel(userId ?? 0, patientData, fetchData));
    }
  }, [patientData, patientIsLoading, userId, fetchData]);

  const handleSubmit = (formObj: AppointmentEditResquest) => {
    const { user, clinicName, labName, ...request } = removeEmptyProps(formObj);
    updateApi(request);
  };

  return (
    <>
      <ScheduledFormView
        isLoading={fetchIsLoading || updateIsLoading}
        dialogProps={dialogProps}
        title={`Detalhes do agendamento`}
        model={model}
        handleSubmit={handleSubmit}
        backPagePath={'/scheduled'}
        submitLabel='Salvar'
      />
    </>
  );
}

export default ScheduledEditFormContainer;
