import React from 'react';
import { FormV2 } from '../../../../components/form-v2';
import OnboardingScaffold from '../../../../components/onboarding-scaffold/onboarding-scaffold';
import { SpacerV } from '../../../../components/spacer/spacer';
import { UserRole } from '../../../../interfaces/user';
import { TellUsModel } from '../../models/tell-us-model';

interface TellUsStepProps {
  handleSubmit: (value: TellUsForm) => void;
  profile: UserRole;
}

interface TellUsForm {
  name: string;
  email: string;
  password: string;
}

const TellUsStep: React.FunctionComponent<TellUsStepProps> = ({
  handleSubmit,
  profile,
}) => {
  return (
    <OnboardingScaffold
      title={<b>Conte-nos mais sobre sua empresa</b>}
      subtitle={'Falta pouco para sua empresa ser parte da Rede Carinho'}>
      <SpacerV size={10} />

      <FormV2
        submitLabel='Próxima'
        submit={handleSubmit}
        model={new TellUsModel()}
      />
    </OnboardingScaffold>
  );
};

export default TellUsStep;
