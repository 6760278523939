import { Redirect, Route, RouteProps } from 'react-router';
import { useAuth } from '../../hooks';
import { authenticationPath, inactivePath } from '../../store/auth';

export type PrivateRouteProps = RouteProps;

export default function PrivateRoute({ ...routeProps }: PrivateRouteProps) {
  const [auth] = useAuth();

  if (auth.isAuthenticated) {
    if (!auth.active && routeProps.path !== '/logout') {
      return <Redirect to={{ pathname: inactivePath }} />;
    } else {
      return <Route {...routeProps} />;
    }
  } else {
    return <Redirect to={{ pathname: authenticationPath }} />;
  }
}
