import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CrudBehaviorRouteProps } from '../../../../components/crud-behavior/crud-behavior';
import { DialogProps, DialogVariantType } from '../../../../components/dialog/dialog';
import { isQueryCompleted } from '../../../../helpers/helpers';
import { Diagnostic } from '../../../../interfaces/diagnostic';
import { useCreateDiagnosticMutation } from '../../../../services/diagnostic/diagnostic-service';
import { DiagnosticModel } from '../../model/diagnostic-model';
import DiagnosticFormView from '../diagnostic-form-view/diagnostic-form-view';

function DiagnosticAddFormContainer(props: CrudBehaviorRouteProps) {
  const model = new DiagnosticModel({ patientId: +props.match.params.patientId });
  const history = useHistory();
  const [addApi, { isLoading, isError, isSuccess, status, data }] = useCreateDiagnosticMutation();

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });

  const handleSubmit = (formObj: Omit<Diagnostic, 'id'>) => {
    addApi(formObj);
  };

  useEffect(() => {
    if (!isQueryCompleted(status) || !data) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: isSuccess ? 'success' : ('error' as DialogVariantType),
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));

        if (isSuccess) {
          const destiny = `${history.location.pathname}`.replace('new', data?.id?.toString() ?? '');
          history.push(destiny);
        }
      },
    };

    setDialogProps(dialogProps);
  }, [isSuccess, isError, status, data, history]);

  return (
    <DiagnosticFormView
      isLoading={isLoading}
      dialogProps={dialogProps}
      title={'Novo Diagnóstico'}
      model={model}
      handleSubmit={handleSubmit}
    />
  );
}

export default DiagnosticAddFormContainer;
