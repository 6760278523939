import Box from '@mui/material/Box';
import { AppointmentTypes } from '../../interfaces/options';
import { useGetAppointmentTypesQuery } from '../../services/options/options-service';
import theme from '../../theme';
import CrudDataTableBadge from '../crud-data-table-badge/crud-data-table-badge';

export interface AppointmentTypeBadgeProps {
  appointmentTypeId: number;
}

export default function AppointmentTypeBadge(props: AppointmentTypeBadgeProps) {
  const { data } = useGetAppointmentTypesQuery();

  const renderBadge = (color: string) => {
    return (
      <CrudDataTableBadge color={color}>
        {props.appointmentTypeId === 3 ? 'Encaminhamento' : 'Rede carinho'}
      </CrudDataTableBadge>
    );
  };

  const renderColor = () => {
    const defaultColor = '#3F8BB5';
    if (!data) return defaultColor;

    const option = data.filter((x) => x.id === props.appointmentTypeId);
    if (!option || !option[0]) return defaultColor;

    const selectedOption: AppointmentTypes = option[0];

    if (selectedOption.value === 3 /* encaminhamento */) {
      return defaultColor;
    }

    return theme.palette.primary.main;
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
      }}>
      {renderBadge(renderColor())}
    </Box>
  );
}
