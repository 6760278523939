import * as yup from 'yup';
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator';
import { CrudFormModel } from '../../../components/form-v2/models/form-model';
import { AppointmentResponse } from '../../../interfaces/appointment';
import { Patient } from '../../../interfaces/patient';
import { FakeAppointmentTypes } from '../../../mirage/fakes/fake-appointment-types';
import { FakeModality } from '../../../mirage/fakes/fake-modality';
import moment from 'moment';
import { useGetCidsQuery, useGetSpecialtiesQuery } from '../../../services/options/options-service';

export class HistoryEditModel extends CrudFormModel implements Partial<AppointmentResponse> {
  id: number | undefined;

  @FormField({
    label: 'Paciente',
    placeholder: 'Selecione o paciente',
    schema: yup.string().required(),
    disabled: true,
  })
  patientName: string = '';

  patientId: number = 0;

  @FormField({
    label: 'Modalidade',
    placeholder: 'Seleciona uma modalidade',
    schema: yup.number().required(),
    options: [...FakeModality()],
    type: 'select',
    disabled: true,
  })
  modalityId: number = 1;

  @FormField({
    label: 'Tipo',
    placeholder: 'Seleciona um tipo',
    schema: yup.number().required(),
    options: [...FakeAppointmentTypes()],
    type: 'select',
    disabled: true,
  })
  typeId: number = 1;

  @FormField({
    label: 'Cid',
    placeholder: 'Seleciona o Cid',
    schema: yup.number().required(),
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetCidsQuery();
      return { data: data ?? [], isLoading, isError };
    },
    type: 'select',
    disabled: true,
  })
  cidId: number = 5;

  @FormField({
    label: 'Especialidades',
    placeholder: 'Selecione a especialidade',
    schema: yup.number().required(),
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetSpecialtiesQuery();
      return { data: data ?? [], isLoading, isError };
    },
    type: 'select',
    disabled: true,
  })
  specialtyId: number = 0;

  @FormField({
    label: 'Data',
    placeholder: 'Insira a data do agendamento Exemplo: 19/04/1996',
    schema: yup.string().required(),
    disabled: true,
  })
  date: string = moment().format('DD/MM/YYYY');

  userId: number;

  constructor(userId: number, patient: Patient, props?: AppointmentResponse) {
    super();
    this.userId = userId;
    this.patientId = patient.id!;
    this.patientName = patient.firstName! + ` ` + patient.lastName!;
    this.id = props?.id ?? 0;
    this.specialtyId = props?.specialtyId ?? 0;
    this.userId = props?.userId ?? 0;
    this.cidId = props?.cidId ?? 0;
    this.typeId = props?.typeId ?? 0;
    this.modalityId = props?.modalityId ?? 0;
    this.date = moment(props?.date ?? new Date()).format('dd/MM/yyyy hh:mm');
  }
}
