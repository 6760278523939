import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface SpinnerProps {
  fullScreen?: boolean;
}

export default function Spinner({ fullScreen }: SpinnerProps) {
  return (
    <Box
      sx={{
        height: fullScreen ? '100vh' : '100%',
        width: fullScreen ? '100vw' : '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 9,
        backgroundColor: (theme) => theme.palette.grey[300],
        opacity: 0.6,
      }}>
      <CircularProgress />
    </Box>
  );
}
