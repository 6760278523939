import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  DialogProps,
  DialogVariantType,
} from '../../../../components/dialog/dialog';
import { useCreateDoctor } from '../../../../hooks/use-create-doctor';
import { CreateDoctor } from '../../../../interfaces/doctor';
import { doctorPageTitle } from '../../doctor-page';
import { DoctorModel } from '../../model/doctor-model';
import DoctorFormView from '../doctor-form-view/doctor-form-view';

function DoctorAddFormContainer() {
  const model = new DoctorModel();
  const history = useHistory();
  const [addApi, { isLoading, isError, isSuccess, data, isCompleted }] =
    useCreateDoctor();

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });

  const handleSubmit = (formObj: CreateDoctor) => {
    addApi(formObj);
  };

  useEffect(() => {
    if (!isCompleted) return;
    const dialogProps: DialogProps = {
      open: true,
      variant: isSuccess ? 'success' : ('error' as DialogVariantType),
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));

        if (isSuccess) {
          const destiny = `${history.location.pathname}`.replace(
            'new',
            data?.id?.toString() ?? ''
          );
          history.push(destiny);
        }
      },
    };

    setDialogProps(dialogProps);
  }, [isSuccess, isError, data, history, isCompleted]);

  return (
    <DoctorFormView
      isLoading={isLoading}
      dialogProps={dialogProps}
      title={`Novo ${doctorPageTitle}`}
      model={model}
      handleSubmit={handleSubmit}
    />
  );
}

export default DoctorAddFormContainer;
