import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getLocalStorage, setLocalStorage } from '../helpers/helpers'
import {
	LoginResponse,
	LoginResponseFirebase,
} from '../services/cognito/interfaces/login'

export const authenticationPath = '/'
export const inactivePath = '/inactive'

export const authLocalStorageKey = 'auth'
export interface AuthState {
	accessToken?: string
	refreshToken?: string
	isAuthenticated?: boolean
	idToken?: string
	userId?: number
	email?: string
	active?: boolean
	picture?: string
	clinic?: number
	doctor?: number
	laboratory?: number
	profile?: string
	displayName?: string
	photoURL?: string
	uid?: string
}

export enum UserProfile {
	'ADMIN' = 'admin',
	'CLINIC' = 'clinic',
	'LAB' = 'lab',
	'DOCTOR' = 'doctor',
	'PATIENT' = 'patient',
}

const initialStateFromStorage = getLocalStorage<AuthState>(authLocalStorageKey)

// Define the initial state using that type
const initialState: AuthState = {
	isAuthenticated: false,
}

export const authSlice = createSlice({
	name: 'auth',
	initialState: initialStateFromStorage ?? initialState,
	reducers: {
		setAuth: (state, action: PayloadAction<LoginResponse>) => {
			const {
				accessToken,
				refreshToken,
				idToken,
				user,
				clinic,
				doctor,
				laboratory,
				uid,
			} = action.payload
			console.log('action.payload', action.payload)
			state.accessToken = accessToken
			state.refreshToken = refreshToken
			state.isAuthenticated = true
			state.email = user.email
			state.idToken = idToken
			state.userId = user.id
			state.picture = user.picture
			state.active = user.active
			state.profile = user.profile
			state.clinic = clinic
			state.doctor = doctor
			state.laboratory = laboratory
			state.uid = uid
			setLocalStorage(authLocalStorageKey, state)
		},
		setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
			state.isAuthenticated = action.payload
			setLocalStorage(authLocalStorageKey, state)
		},
		clearAuth: (state) => {
			state.accessToken = ''
			state.refreshToken = ''
			state.isAuthenticated = false
			setLocalStorage(authLocalStorageKey, state)
		},
		setAuthFirebase: (state, action: PayloadAction<LoginResponseFirebase>) => {
			const { displayName, email, photoURL, uid, refreshToken } = action.payload
			state.displayName = displayName || ''
			state.refreshToken = refreshToken
			state.email = email || ''
			state.photoURL = photoURL || ''
			state.uid = uid
			setLocalStorage(authLocalStorageKey, state)
		},
	},
})

export const { setAuth, setIsAuthenticated, clearAuth, setAuthFirebase } =
	authSlice.actions

export default authSlice.reducer
