import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AppBar, Drawer, Content, Toolbar } from './dashboard-styles';
import Logo from '../../assets/images/logo.svg';
import List from '@mui/material/List';
import { useLocation, useHistory } from 'react-router';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { Link } from 'react-router-dom';
import { routes } from '../../components/routes/data/routes';
import { usePrefetchOptionsService } from '../../services/options/options-service';
import { Spinner } from '../../components/spinner';
import { useAuth, useDashboard } from '../../hooks';

const Dashboard: React.FunctionComponent<{}> = (props) => {
  const [auth] = useAuth();
  const [dashboardStoreState] = useDashboard();
  const [open, setOpen] = React.useState(true);
  const history = useHistory();
  const location = useLocation();
  const { isLoading } = usePrefetchOptionsService();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogoClick = () => {
    history.push('/home');
  };

  const renderMenuItems = () => {
    const items = routes
      .filter((route) => {
        const hasAccess = route.accessControl ? route.accessControl(auth.profile!) : true;

        let isEnabled = route.panel?.enabled === true;
        if (route.panel?.lazyEnabled) {
          return route.panel?.lazyEnabled() && hasAccess;
        }
        return isEnabled === true && hasAccess;
      })
      .map((route, index) => {
        return (
          <Box
            key={index}
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row-reverse',
              my: 1,
            }}
          >
            <Box
              sx={{
                width: '80%',
                borderTopLeftRadius: 50,
                borderBottomLeftRadius: 50,
                backgroundColor: `${location.pathname.includes(`${route?.routeProps?.path}`) ? '#F3F4FF' : 'none'}`,
              }}
            >
              <ListItemButton component={Link} to={`${route.routeProps.path}`}>
                <ListItemIcon>{route.panel?.icon}</ListItemIcon>
                <ListItemText primary={route.panel?.name} sx={{ color: (theme) => theme.palette.primary.main, fontWeight: 'bold' }} />
              </ListItemButton>
            </Box>
          </Box>
        );
      });
    return <List>{items}</List>;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar open={open} elevation={0}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}></Typography>
          {/* <IconButton
            sx={{
              backgroundColor: 'white',
            }}>
            <SettingsOutlinedIcon
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            />
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar>
          <Box
            sx={{
              height: '50px',
              width: '160px',
              display: 'flex',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleLogoClick();
            }}
          >
            <img src={Logo} alt="Logo" width='100%' />
          </Box>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            />
          </IconButton>
        </Toolbar>
        {renderMenuItems()}
      </Drawer>
      <Content component="main">
        <Toolbar />
        {(isLoading || dashboardStoreState.loading) && <Spinner />}
        {props.children}
      </Content>
    </Box>
  );
};

export default Dashboard;
