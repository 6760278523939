import { createTheme } from "@mui/material";
import createPalette from "@mui/material/styles/createPalette";

const mdTheme = createTheme({
  palette: createPalette({
    background: {
      default: '#F5F5F5',
    },
    primary: {
      main: '#07589B',
    },
    divider: '#07589B'
  })
});


export const theme = {
  ...mdTheme,
}

export default theme