import { FormV2 } from '../../../../components/form-v2';
import OnboardingScaffold from '../../../../components/onboarding-scaffold/onboarding-scaffold';
import { SpacerV } from '../../../../components/spacer/spacer';
import { ConfirmUserRequest } from '../../../../services/cognito/interfaces/create-user';
import { ConfirmUserRequestModel } from '../../models/confirmation-code-model';

interface ConfirmationCodeStepProps {
  handleSubmit: (value: ConfirmUserRequest) => void;
  isLoading: boolean;
  email: string;
}

export default function ConfirmationCodeStep({
  handleSubmit,
  isLoading,
  email,
}: ConfirmationCodeStepProps) {
  return (
    <OnboardingScaffold
      title={<b>Só mais um passo! Por favor, confirme seu email</b>}
      isLoading={isLoading}
      subtitle={'Insira o código de ativação enviado no seu email'}>
      <SpacerV size={10} />

      <FormV2
        model={new ConfirmUserRequestModel({ email })}
        submit={handleSubmit}
      />
    </OnboardingScaffold>
  );
}
