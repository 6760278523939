import { CrudForm } from '../../../../components/crud-form';
import { CrudFormProps } from '../../../../components/crud-form/crud-form';

export default function PatientFormView({
  model,
  title,
  handleSubmit,
  isLoading,
  dialogProps,
}: Partial<CrudFormProps>) {
  return (
    <CrudForm
      title={title!}
      handleSubmit={handleSubmit!}
      model={model}
      isLoading={isLoading!}
      dialogProps={dialogProps!}
    />
  );
}
