import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CrudBehaviorRouteProps } from '../../../../components/crud-behavior/crud-behavior';
import {
  DialogProps,
  DialogVariantType,
} from '../../../../components/dialog/dialog';
import { isQueryCompleted } from '../../../../helpers/helpers';
import { Treatment } from '../../../../interfaces/treatment';
import { useCreateTreatmentMutation } from '../../../../services/treatment/treatment-service';
import { TreatmentModel } from '../../model/treatment-model';
import TreatmentFormView from '../treatment-form-view/treatment-form-view';

function TreatmentAddFormContainer(props: CrudBehaviorRouteProps) {
  const model = new TreatmentModel({ patientId: +props.match.params.patientId });
  const history = useHistory();
  const [addApi, { isLoading, isError, isSuccess, status, data }] =
    useCreateTreatmentMutation();

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });

  const handleSubmit = (formObj: Omit<Treatment, 'id'>) => {
    addApi(formObj);
  };

  useEffect(() => {
    if (!isQueryCompleted(status) || !data) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: isSuccess ? 'success' : ('error' as DialogVariantType),
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));

        if (isSuccess) {
          const destiny = `${history.location.pathname}`.replace(
            'new',
            data?.id?.toString() ?? ''
          );
          history.push(destiny);
        }
      },
    };

    setDialogProps(dialogProps);
  }, [isSuccess, isError, status, data, history]);

  return (
    <TreatmentFormView
      isLoading={isLoading}
      dialogProps={dialogProps}
      title={'Novo Tratamento'}
      model={model}
      handleSubmit={handleSubmit}
    />
  );
}

export default TreatmentAddFormContainer;
