import { BaseCrud } from "./crud";

export interface Option extends BaseCrud {
  id: number;
  value: string | number;
  description: string;
  default?: boolean
  disabled?: boolean
}

export interface CidTypes extends Option { }

export interface SpecialtyTypes extends Option { }

export interface ModalityTypes extends Option { }

export interface ExamTypes extends Option { }

export interface AppointmentTypes extends Option { }

export interface TreatmentType extends Option {}

export enum AppointmentEnumTypes {
  CONSULT = 1,
  EXAM = 2,
  FOWARD = 3
}