import * as yup from 'yup';
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator';
import { CrudFormModel } from '../../../components/form-v2/models/form-model';
import { Patient } from '../../../interfaces/patient';
import { Option } from '../../../interfaces/options'
import { useGetDoctorsByProfileQuery } from '../../../hooks/use-get-doctors';
import { Treatment } from '../../../interfaces/treatment';
import { Diagnostic } from '../../../interfaces/diagnostic';

export class PatientModel extends CrudFormModel implements Readonly<Patient>{
  id: number | undefined;

  @FormField({
    label: 'Médico responsável',
    placeholder: 'Selecione o médico responsável',
    schema: yup.number().test('selected', 'O campo é obrigatório', function (item) {
      return (item && item > 0) || false
    }),
    type: 'select',
    lazyFunction: () => () => {
      const { data, isLoading, isError } = useGetDoctorsByProfileQuery()

      const toOptions = data?.map(x => ({ id: x.id, value: x.id, description: `${x.user.firstName} ${x.user.lastName} - ${x.user.document}` } as Option)) ?? []
      return { data: toOptions, isLoading, isError }
    }
  })
  doctorId: number = 0;
  userId: number | undefined;

  @FormField({
    label: 'Primeiro nome',
    placeholder: 'Insira o primeiro nome',
    schema: yup.string().required(),
  })
  firstName: string = '';

  @FormField({
    label: 'Sobrenome',
    placeholder: 'Insira o sobrenome',
    schema: yup.string().required(),
  })
  lastName: string = '';

  @FormField({
    label: 'Telefone',
    placeholder: 'Insira o telefone',
    schema: yup
    .string()
    .required()
    .matches(
      /^\d{11,12}$/, 
      'Por favor, insira um número de telefone válido, com DDD e apenas números, sem espaço ou travessão (-).'
    ),
  })
  phone: string = '';

  @FormField({
    label: 'Convênio',
    placeholder: 'Insira o convênio',
    schema: yup.string().required(),
  })
  medicalInsurance: string = '';

  @FormField({
    label: 'Endereço',
    placeholder: 'Insira o endereço',
    schema: yup.string().required(),
  })
  address: string = '';

  @FormField({
    label: 'Data de nascimento',
    placeholder: 'Insira a data de nascimento',
    schema: yup.string().required(),
    type: 'date'
  })
  birthdate: string = '';

  @FormField({
    label: 'Email',
    placeholder: 'Email',
    schema: yup.string().email().required(),
    type: 'email',
  })
  email: string = '';

  @FormField({
    label: 'Cpf',
    placeholder: 'Insira o CPF',
    schema: yup.string().required(),
    mask: '999.999.999-99'
  })
  document: string = '';

  picture: string = '';

  treatments: Treatment[] = [];

  diagnostics: Diagnostic[] = [];

  constructor(props?: Patient) {
    super()
    if (!props) return
    this.id = props.id
    this.firstName = props.firstName
    this.lastName = props.lastName
    this.phone = props.phone
    this.medicalInsurance = props.medicalInsurance
    this.address = props.address
    this.birthdate = props.birthdate
    this.email = props.email
    this.document = props.document
    this.doctorId = props.doctorId
    this.userId = props.userId
    this.treatments = props.treatments ?? []
    this.diagnostics = props.diagnostics ?? []
  }
}
