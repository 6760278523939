import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '../../../../components/breadcrumbs/breadcrumbs';
import { CrudFormProps } from '../../../../components/crud-form/crud-form';
import Dialog from '../../../../components/dialog/dialog';
import FormV2 from '../../../../components/form-v2/form-v2';
import Spinner from '../../../../components/spinner/spinner';

export default function ScheduledFormView({
  model,
  title,
  handleSubmit,
  isLoading,
  dialogProps,
  backPagePath,
  submitLabel = 'Salvar',
}: CrudFormProps) {
  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          height: 'calc(100% - 68px)',
          padding: '26px 46px',
          flexDirection: 'column',
          overflow: 'auto',
        }}>
        {isLoading && <Spinner />}
        <Dialog {...dialogProps} />
        <Breadcrumbs
          items={[
            {
              label: 'Voltar',
              link: backPagePath,
            },
          ]}
        />
        <Typography
          variant='h4'
          component={Box}
          sx={{ pt: 2, color: (theme) => theme.palette.primary.main }}>
          <b>{title}</b>
        </Typography>

        {model && (
          <FormV2
            model={model}
            submit={handleSubmit}
            submitLabel={submitLabel}
          />
        )}
      </Paper>
    </>
  );
}
