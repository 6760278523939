import { Box } from '@mui/material';

interface Props {
  size?: number;
}

export function SpacerV(props: Props) {
  return <Box height={props.size ? props.size * 8 : 8} />;
}

export function SpacerH(props: Props) {
  return <Box width={props.size ? props.size * 8 : 8} />;
}
