import * as yup from 'yup';
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator';
import { CrudFormModel } from '../../../components/form-v2/models/form-model';

export class TellUsModel {

  @FormField({
    label: 'Nome',
    placeholder: 'Insira o nome do estabelecimento',
    schema: yup.string().required(),
  })
  firstName: string = '';


  @FormField({
    label: 'Email',
    placeholder: 'Insira o email',
    schema: yup.string().email().required(),
    type: 'email',
  })
  email: string = '';

  @FormField({
    label: 'Senha',
    placeholder: 'Insira a senha de acesso',
    schema: yup
      .string()
      .required('Please Enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Deve conter 8 caracteres, uma maiúscula, uma minúscula, um número e um caractere especial"
      ),
    type: 'password',
  })
  password: string = '';
}
