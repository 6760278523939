import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Dialog from '../../components/dialog/dialog'
import { getLocalStorage, setLocalStorage } from '../../helpers/helpers'
import { useAppDispatch } from '../../hooks'
import { useLoginMutation } from '../../services/cognito/cognito-service'
import { LoginResponseFirebase } from '../../services/cognito/interfaces/login'
import {
	loginUserFirebase,
	signOutFirebase,
} from '../../services/firebase/firebaseService'
import { setAuth, setAuthFirebase } from '../../store/auth'
import { SigninModel, SigninModelProps } from './model/signin-model'
import SigninView from './signin-view'

export const signinLocalStorageKey = 'signin'

export default function SigninContainer() {
	const [api, { isLoading }] = useLoginMutation()
	const history = useHistory()
	const dispatch = useAppDispatch()
	const [openDialog, setOpenDialog] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | undefined>()

	const [model, setModel] = useState<SigninModel | null>(null)

	useEffect(() => {
		signOutFirebase()
		getCredentialsFromLocalStorage()
	}, [])

	const getCredentialsFromLocalStorage = () => {
		const credentials = getLocalStorage<SigninModelProps>(signinLocalStorageKey)
		setModel(new SigninModel(credentials))
	}

	const doLoginFirebase = async (request: SigninModelProps) => {
		try {
			const userCredential = await loginUserFirebase(
				request.email,
				request.password,
			)

			const { displayName, email, photoURL, uid, refreshToken } = userCredential

			const payload: LoginResponseFirebase = {
				accessToken: refreshToken,
				idToken: refreshToken,
				displayName,
				uid,
				refreshToken,
				photoURL,
				email,
				active: true,
			}
			request.uid = uid
			dispatch(setAuthFirebase(payload))
			doLogin(request)
		} catch (e: any) {
			setErrorMessage(
				'Erro ao fazer autenticação verifique seu e-mail e senha!',
			)
			setOpenDialog(true)
		}
	}

	const doLogin = (request: SigninModelProps) => {
		setLocalStorage(signinLocalStorageKey, request)
		api(request)
			.unwrap()
			.then((data) => {
				dispatch(setAuth(data))
				history.push('/home')
			})
			.catch((e) => {
				setErrorMessage(e?.data?.message || e?.message || undefined)
				setOpenDialog(true)
			})
	}

	return (
		<>
			<Dialog
				variant='error'
				open={openDialog}
				subtitle={errorMessage}
				onOk={() => {
					setErrorMessage(undefined)
					setOpenDialog(false)
				}}
			/>
			{model && (
				<SigninView
					isLoading={isLoading}
					onSubmit={doLoginFirebase}
					model={model}
				/>
			)}
		</>
	)
}
