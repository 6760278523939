import React from 'react';
import FormText from '../../../../components/form/components/form-text/form-text';
import Form, { IFormField } from '../../../../components/form/form';
import OnboardingScaffold from '../../../../components/onboarding-scaffold/onboarding-scaffold';
import { SpacerV } from '../../../../components/spacer/spacer';
import { UserRole } from '../../../../interfaces/user';
import renderSuffix from '../../utils/renderSuffix';

interface CompleteProfileStepProps {
  handleSubmit: (value: CompleteProfileForm) => void;
  profile: UserRole;
  isLoading: boolean;
}

interface CompleteProfileForm {
  document: string;
  address: string;
}

const CompleteProfileStep: React.FunctionComponent<CompleteProfileStepProps> =
  ({ handleSubmit, profile, isLoading }) => {
    const fields: IFormField[] = [
      FormText({
        name: 'document',
        label: `CNPJ ${renderSuffix(profile)}`,
        placeholder: `insira aqui o CNPJ ${renderSuffix(
          profile
        ).toLowerCase()}`,
        fullWidth: true,
        autoFocus: true,
        margin: 'normal',
        mask: '99.999.999/9999-99',
        required: true,
        validators: ['isCnpj'],
        errorMessages: ['CNPJ inválido'],
      }),
      FormText({
        name: 'address',
        label: `Localização ${renderSuffix(profile)}`,
        placeholder: `insira aqui a localização ${renderSuffix(
          profile
        ).toLowerCase()}`,
        fullWidth: true,
        margin: 'normal',
        required: true,
      }),
    ];

    return (
      <OnboardingScaffold
        title={<b>Complete o perfil da sua empresa</b>}
        isLoading={isLoading}
        subtitle={'Falta pouco para sua empresa ser parte da Rede Carinho'}>
        <SpacerV size={10} />

        <Form<CompleteProfileForm>
          formName={'onboarding'}
          fields={fields}
          submit={handleSubmit}
          submitButtonText='Confirmar'
        />
      </OnboardingScaffold>
    );
  };

export default CompleteProfileStep;
