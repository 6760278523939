import { useEffect, useState } from 'react';
import { CrudBehaviorRouteProps } from '../../../../components/crud-behavior/crud-behavior';
import {
  DialogProps,
  DialogVariantType,
} from '../../../../components/dialog/dialog';
import { isQueryCompleted } from '../../../../helpers/helpers';
import { Patient } from '../../../../interfaces/patient';
import {
  useUpdatePatientMutation,
  useGetPatientByIdQuery,
} from '../../../../services/patient/patient-service';
import { PatientModel } from '../../model/patient-model';
import PatientFormView from '../patient-form-view/patient-form-view';

function PatientEditFormContainer(props: CrudBehaviorRouteProps) {
  const [
    updateApi,
    {
      isLoading: updateIsLoading,
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      status: updateStatus,
    },
  ] = useUpdatePatientMutation();

  const {
    data: fetchData,
    isLoading: fetchIsLoading,
    isError: fetchIsError,
    refetch,
  } = useGetPatientByIdQuery(Number(props.match.params.id));

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });
  const [model, setModel] = useState<PatientModel | undefined>(undefined);
  const handleSubmit = async (formObj: Patient) => {
    // console.log('formObj', formObj);
    // const { id, medicalInsurance, userId, ...userData } = formObj;
    // const request = {
    //   id,
    //   medicalInsurance,
    //   user: {
    //     id: userId,
    //     ...userData,
    //   },
    // };
    // console.log('request', request);
    await updateApi(formObj);
    refetch()
  };

  useEffect(() => {
    if (!isQueryCompleted(updateStatus)) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: updateIsSuccess ? 'success' : ('error' as DialogVariantType),
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [updateIsSuccess, updateIsError, updateStatus]);

  useEffect(() => {
    if (!fetchIsError) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: 'error',
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [fetchIsError]);

  useEffect(() => {
    if (fetchData) setModel(new PatientModel(fetchData));
  }, [fetchData]);

  return (
    <PatientFormView
      isLoading={updateIsLoading || fetchIsLoading}
      dialogProps={dialogProps}
      title={'Editar Paciente'}
      model={model}
      handleSubmit={handleSubmit}
    />
  );
}

export default PatientEditFormContainer;
