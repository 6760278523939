import React from 'react';
import FormRadio, {
  IFormRadioOption,
} from '../../../../components/form/components/form-radio/form-radio';
import Form from '../../../../components/form/form';
import OnboardingScaffold from '../../../../components/onboarding-scaffold/onboarding-scaffold';
import { SpacerV } from '../../../../components/spacer/spacer';
import { UserRole } from '../../../../interfaces/user';

interface ProfileSelectionStepProps {
  handleSubmit: (value: ProfileSelectionForm) => void;
}

interface ProfileSelectionForm {
  profile: UserRole;
}

const ProfileSelectionStep: React.FunctionComponent<ProfileSelectionStepProps> =
  (props) => {
    const options: IFormRadioOption<UserRole>[] = [
      { label: 'Clínica', value: 'clinic' },
      { label: 'Laboratório', value: 'lab' },
    ];

    return (
      <OnboardingScaffold
        title={<b>Qual o perfil desta conta?</b>}
        subtitle={'Falta pouco para sua empresa ser parte da Rede Carinho'}>
        <SpacerV size={10} />

        <Form<ProfileSelectionForm>
          formName={'onboarding'}
          fields={[
            FormRadio({
              name: 'profile',
              options: options,
              required: true,
            }),
          ]}
          submit={props.handleSubmit}
          submitButtonText='Próxima'
        />
      </OnboardingScaffold>
    );
  };

export default ProfileSelectionStep;
