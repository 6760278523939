import { Box, Breadcrumbs as MuiBreadcrumbs, Button, Link } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useHistory } from 'react-router-dom';

export interface BreadcrumbItem {
  link?: string;
  label: string;
}

export interface BreadcrumbProps {
  items: BreadcrumbItem[];
  goBack?: boolean;
}

export default function Breadcrumbs({ items, goBack }: BreadcrumbProps) {
  const history = useHistory();

  return (
    <Box
      component="div"
      sx={{
        alignSelf: 'flex-start',
        mt: 1,
        width: '100%',
      }}
    >
      <MuiBreadcrumbs aria-label="breadcrumb">
        {items.map((item, i) => {
          return (
            <Button
              key={i}
              component={Link}
              onClick={() => {
                if (goBack || !item.link) {
                  history.goBack();
                } else {
                  history.push(item.link);
                }
              }}
            >
              <KeyboardArrowLeftIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {item.label}
            </Button>
          );
        })}
      </MuiBreadcrumbs>
    </Box>
  );
}
