import { withCrudBehavior } from '../../components/crud-behavior/crud-behavior';
import { PatientAddForm } from './components/patient-add-form';
import { PatientEditForm } from './components/patient-edit-form';
import { PatientList } from './components/patient-list';

const PatientPage = withCrudBehavior({
  entityCreateFormComponent: PatientAddForm,
  entityEditFormComponent: PatientEditForm,
  entityListComponent: PatientList,
});

export default PatientPage;
