import React from 'react';
import { IFormField, SetFormState } from '../../form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

export interface IFormRadioProps {
  name: string;
  required?: boolean;
  options: IFormRadioOption<unknown>[];
  initialValue?: string;
}

export interface IFormRadioOption<T> {
  label: string;
  value: T;
}

interface IFormRadioComponentProps extends IFormRadioProps {
  setFormState: SetFormState;
}

function FormRadioComponent(props: IFormRadioComponentProps) {
  const { name, required, setFormState, options, initialValue } = props;
  return (
    <RadioGroup
      name={name}
      value={initialValue}
      onChange={(evt) => {
        setFormState(name, evt?.target?.value);
      }}>
      {options.map((opt, i) => (
        <FormControlLabel
          key={i}
          label={opt.label}
          value={opt.value}
          control={<Radio required={required} />}
        />
      ))}
    </RadioGroup>
  );
}

export default function FormRadio(props: IFormRadioProps): IFormField {
  const renderFunc = function (key: string, setFormState: SetFormState): any {
    return (
      <FormRadioComponent key={key} {...props} setFormState={setFormState} />
    );
  };
  return {
    renderFunc: renderFunc,
  } as IFormField;
}
