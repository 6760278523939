import { initializeApp } from 'firebase/app'
import {
	createUserWithEmailAndPassword,
	getAuth,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
} from 'firebase/auth'

import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
	apiKey: 'AIzaSyDPNFYvJOpx74tNkaIVhxhJhRfsv5sh7uU',
	authDomain: 'rede-carinho-36e5e.firebaseapp.com',
	projectId: 'rede-carinho-36e5e',
	storageBucket: 'rede-carinho-36e5e.appspot.com',
	messagingSenderId: '442474065816',
	appId: '1:442474065816:web:0c2bead18f797ed05145ed',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
getAnalytics(app)

export const signOutFirebase = async () => {
	await auth.signOut()
}

export const createNewUserFirebase = async (
	email: string,
	password: string,
) => {
	const userCredential = await createUserWithEmailAndPassword(
		auth,
		email,
		password,
	)
	return userCredential.user
}

export const loginUserFirebase = async (email: string, password: string) => {
	const userCredential = await signInWithEmailAndPassword(auth, email, password)
	return userCredential.user
}

export const resetPasswordFirebase = (email: string) => {
	sendPasswordResetEmail(auth, email)
}
