import Grid from '@mui/material/Grid';

import cids from '../../assets/images/admin/cids.svg';
import specialties from '../../assets/images/admin/specialties.svg';
import exams from '../../assets/images/admin/exams.svg';
import CardAction, {
  CardActionProps,
} from '../../components/card-action/card-item';

export default function AdminZone() {
  const items: CardActionProps[] = [
    {
      imageSrc: cids,
      link: '/cid',
      title: 'Gestão de CIDS',
      subtitle: 'CADASTRAR CIDS',
    },
    {
      imageSrc: specialties,
      link: '/specialties',
      title: 'Gestão de Especialidades',
      subtitle: 'CADASTRAR ESPECIALIDADES',
    },
    {
      imageSrc: exams,
      link: '/exams-types',
      title: 'Gestão de Exames',
      subtitle: 'CADASTRAR EXAMES',
    },
  ];

  return (
    <Grid container spacing={3}>
      {items.map((item, index) => {
        return (
          <Grid item xs={4} key={index}>
            <CardAction {...item}></CardAction>
          </Grid>
        );
      })}
    </Grid>
  );
}
