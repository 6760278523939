import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { useGridApiContext } from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import Box from '@mui/material/Box/Box';
import { Typography } from '@mui/material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
});

export default function Pagination() {
  const apiRef = useGridApiContext();
  const { state } = apiRef?.current;

  const classes = useStyles();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="body2">
        Total:
        {state.rows.totalRowCount} linhas
      </Typography>
      <MuiPagination
        className={classes.root}
        color="primary"
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </Box>
  );
}
