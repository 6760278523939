import { useEffect, useState } from 'react';
import { DialogVariantType } from '../../../../components/dialog/dialog';
import HistoryCrudPageView from './history-list';
import { useGetPatientAppointmentsQuery } from '../../../../services/patient/patient-service';
import { useHistory } from 'react-router-dom';
import { extractIdFromPath } from '../../../../helpers/helpers';

export default function HistoryListContainer() {
  const history = useHistory();

  const { isLoading, data, isError, error, refetch } = useGetPatientAppointmentsQuery(
    +extractIdFromPath(history.location.pathname)
  );

  useEffect(() => { 
    refetch()
  }, [])
  
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogVariant] = useState<DialogVariantType>('error');

  useEffect(() => {
    if (isError) setShowDialog(true);
  }, [isError, error]);

  return (
    <HistoryCrudPageView
      onCloseDialog={() => setShowDialog(false)}
      showDialog={showDialog}
      dialogVariant={dialogVariant}
      isLoading={isLoading}
      data={data ?? []}
    />
  );
}
