import { makeStyles } from '@mui/styles';
import theme from '../../theme';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      height: 'calc(100% - 128px)',
      padding: '15px',
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
    grid: {
      border: 'none',
      paddingInline: '24px',
      color: theme.palette.grey[800],
      '& .MuiDataGrid-columnSeparator': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        color: `${theme.palette.primary.main}`,
        fontWeight: 'bold',
      },
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
    },
    actionBtn: {
      backgroundColor: theme.palette.grey[500],
      marginRight: '8px',
      padding: '4px',
    },
    actionBtnIcon: { color: 'white', fontSize: '16px' },
    addBtn: { alignSelf: 'flex-start', marginLeft: '16px' }
  }),
  { defaultTheme: theme }
);