import 'reflect-metadata';
import * as yup from "yup";
import { Option } from '../../../interfaces/options'

const metadataKey = Symbol("FormField")

type CustomInputTypes = 'select' | 'select-multiple' | 'date-time' | 'date'

interface FormFieldProps {
  type?: React.InputHTMLAttributes<unknown>['type'] | CustomInputTypes;
  schema?: unknown;
  placeholder?: string;
  label?: string;
  mask?: string;
  options?: Option[]
  hidden?: (formData: any) => boolean
  disabled?: boolean | ((formData: any) => boolean)
  /**
   * 
   * @lazyFunction will replace values if has one
   */
  lazyFunction?: () => () => { isLoading: boolean, data: Option[], isError: boolean };
}

const getFormMetadata = (origin: object): { key: string, props: FormFieldProps }[] => Reflect.getMetadata(metadataKey, origin)

export function FormField(props: FormFieldProps): any {
  return (target: object, key: string) => {
    let properties = getFormMetadata(target)

    if (!props) return;

    const obj = { key: key, props: props }
    if (properties) {
      properties.push(obj)
    } else {
      properties = [obj]
    }
    Reflect.defineMetadata(metadataKey, properties, target)
  }
}

function getFormSchema(origin: object) {
  const props = getFormMetadata(origin)

  let result: any = {}

  if (props) {
    props.forEach(({ key, props: { schema } }) => {
      if (schema) {
        result[key] = schema
      }
    })
  }

  return yup.object(result).required();
}

export function getForm(origin: object) {
  const formFields = getFormMetadata(origin)
  return { formFields, schema: getFormSchema(origin) }
}

