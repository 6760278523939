import React from 'react';
import { CrudBehaviorRouteProps } from '../../components/crud-behavior/crud-behavior';
import {
  useCreateCidMutation,
  useDeleteCidMutation,
  useLazyGetCidByIdQuery,
  useLazyGetCidsQuery,
  useUpdateCidMutation,
} from '../../services/options/options-service';
import { OptionsPage } from '../options';
import { OptionsProvider } from '../options/context/OptionsContext';

const CidPage: React.FC<CrudBehaviorRouteProps> = (props) => {
  const [fetch, fetchState] = useLazyGetCidsQuery();
  const [fetchById, fetchByIdState] = useLazyGetCidByIdQuery();
  const [deleteApi, deleteState] = useDeleteCidMutation();
  const [update, updateState] = useUpdateCidMutation();
  const [add, addState] = useCreateCidMutation();

  return (
    <>
      <OptionsProvider
        settings={{
          title: 'Gestão de Cids',
          backPath: '/cid',
          fetch: () => fetch(),
          fetchState: fetchState,
          fetchById: (id) => fetchById(id),
          fetchByIdState: fetchByIdState,
          deleteApi: (id) => deleteApi(id),
          deleteState: deleteState,
          add: (payload) => add(payload),
          addState: addState,
          update: (payload) => update(payload),
          updateState: updateState,
        }}
      >
        <OptionsPage {...props} />
      </OptionsProvider>
    </>
  );
};

export default CidPage;
