import * as yup from 'yup';
import { FormField } from '../../../components/form-v2/decorators/form-field-decorator';
import { CrudFormModel } from '../../../components/form-v2/models/form-model';
import { getOptionIdByValue } from '../../../helpers/helpers';
import { User } from '../../../interfaces/user';

export const adminOptions = [{ id: 1, value: 'admin', description: 'Administrador' }, { id: 2, value: 'clinic', description: 'Clinica' }, { id: 3, value: 'lab', description: 'Laboratório' }]

export class AccessModel extends CrudFormModel {
  id: number = 0;

  // TODO: adicionar picture selector
  picture: string = '';

  @FormField({
    label: 'Email',
    placeholder: 'Email',
    schema: yup.string().email().required(),
    type: 'email',
  })
  email: string = '';

  @FormField({
    label: 'CNPJ',
    placeholder: 'Insira o CNPJ',
    schema: yup.string().required(),
  })
  document: string = '';

  @FormField({
    label: 'Endereço',
    placeholder: 'Insira o Endereço',
    schema: yup.string().required(),
  })
  address: string = '';

  @FormField({
    label: 'Ativo',
    placeholder: 'Ativar ou desativar o usuário',
    schema: yup.boolean().required(),
    type: 'select',
    options: [{ id: 1, value: 'true', description: 'Ativo' }, { id: 0, value: 'false', description: 'Inativo' }]
  })
  active: number = 0;

  @FormField({
    label: 'Perfil',
    placeholder: 'seleciona o perfil',
    schema: yup.string().required(),
    type: 'select',
    options: adminOptions
  })
  profile: number = 0

  constructor(props?: User) {
    super()
    if (!props) return

    const keys = Object.keys(props)
    for (let i = 0; i < keys.length; i++) {
      (this as any)[keys[i]] = (props as any)[keys[i]]
    }

    this.active = props.active ? 1 : 0
    this.profile = getOptionIdByValue(adminOptions, props.profile)
  }
}
