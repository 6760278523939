import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Dialog from '../../components/dialog/dialog'
import {
	useConfirmUserMutation,
	useCreateUserMutation,
} from '../../services/cognito/cognito-service'
import {
	ConfirmUserRequest,
	CreateUserRequest,
} from '../../services/cognito/interfaces/create-user'
import { RootState, store } from '../../store'
import CompleteProfileStep from './components/steps/CompleteProfileStep'
import ConfirmationCodeStep from './components/steps/ConfirmationCodeStep'
import FinishedStep from './components/steps/FinishedStep'
import ProfileSelectionStep from './components/steps/ProfileSelectionStep'
import TellUsStep from './components/steps/TellUsStep'
import {
	clearOnboarding,
	setAddress,
	setDocument,
	setNameAndEmail,
	setProfile,
	setStep,
} from './onboarding-slice'

export default function OnboardingContainer() {
	const history = useHistory()
	const onboarding = useSelector((state: RootState) => state.onboarding)
	const dispatch = useDispatch()
	const [showDialog, setShowDialog] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string>()

	const [api, { isLoading }] = useCreateUserMutation()
	const [confirmUserApi, { isLoading: confirmUserIsLoading }] =
		useConfirmUserMutation()

	useEffect(() => {
		setShowDialog(!!errorMessage)
	}, [errorMessage])

	// const createUserFirebase = async () => {
	// 	const onboardingState = store.getState().onboarding
	// 	const user = await createNewUserFirebase(
	// 		onboardingState.email ?? '',
	// 		onboardingState.password ?? '',
	// 	)

	// 	console.log('userCreated', user)

	// 	createProfile()
	// }

	const createProfile = () => {
		/// nao sei pq nao estava atualizando o onboarding do hook
		const onboardingState = store.getState().onboarding
		const userRequest: CreateUserRequest = {
			address: onboardingState.address ?? '',
			document: onboardingState.document ?? '',
			email: onboardingState.email ?? '',
			firstName: onboardingState.firstName ?? '',
			picture: onboardingState.picture ?? '',
			profile: onboardingState.profile ?? '',
			password: onboardingState.password ?? '',
		}
		api(userRequest)
			.unwrap()
			.then(() => {
				dispatch(setStep('finished'))
			})
			.catch((e) => {
				setErrorMessage(e?.data?.message || e?.message || undefined)
			})
	}

	const confirmUser = (data: ConfirmUserRequest) => {
		confirmUserApi({ ...data })
			.unwrap()
			.then(() => {
				dispatch(setStep('finished'))
			})
			.catch((error) => {
				alert('Ocorreu um erro!')
				console.error(error)
			})
	}

	const renderSteps = () => {
		switch (onboarding.step) {
			case 'profile':
				return (
					<ProfileSelectionStep
						handleSubmit={(value) => {
							dispatch(setProfile(value.profile))
							dispatch(setStep('tellUs'))
						}}
					/>
				)

			case 'tellUs':
				return (
					<TellUsStep
						profile={onboarding.profile}
						handleSubmit={(value) => {
							dispatch(setNameAndEmail(value))
							dispatch(setStep('completeProfile'))
						}}
					/>
				)

			case 'completeProfile':
				return (
					<CompleteProfileStep
						profile={onboarding.profile}
						isLoading={confirmUserIsLoading}
						handleSubmit={(value) => {
							dispatch(setDocument(value.document))
							dispatch(setAddress(value.address))
							return createProfile()
						}}
					/>
				)

			case 'confirmationCode':
				return (
					<ConfirmationCodeStep
						email={
							store.getState().onboarding.email ?? 'seuEmail@exemplo.com.br'
						}
						isLoading={isLoading}
						handleSubmit={confirmUser}
					/>
				)

			case 'finished':
				return (
					<FinishedStep
						handleSubmit={() => {
							dispatch(clearOnboarding())
							history.push('/')
						}}
					/>
				)
			default:
				return <div>doesnt works for this state {onboarding.step} tururuuu</div>
		}
	}

	return (
		<>
			<Dialog
				variant='error'
				open={showDialog}
				subtitle={errorMessage}
				onClose={() => {
					setErrorMessage(undefined)
					setShowDialog(false)
				}}
			/>
			{renderSteps()}
		</>
	)
}
