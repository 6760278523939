import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import { AppointmentResponse } from '../../interfaces/appointment';
import { Diagnostic, CreateDiagnostic } from '../../interfaces/diagnostic';
import { baseUrl } from '../base-service';


export const diagnosticApi = createApi({
  reducerPath: 'diagnosticApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${baseUrl}/diagnostics` }),
  endpoints: (builder) => ({
    createDiagnostic: builder.mutation<Diagnostic, CreateDiagnostic>({
      query(body) {
        return {
          url: '/',
          method: 'POST',
          body: body,
        };
      },
    }),
    updateDiagnostic: builder.mutation<
      void,
      Partial<Diagnostic>
    >({
      query(body) {
        const request = {
          ...body,
          date: moment(body.date).toISOString()
        }
        return {
          url: `/${body.id}`,
          method: 'PATCH',
          body: request,
        };
      },
    }),
    deleteDiagnostic: builder.mutation<void, number>({
      query(id) {
        return {
          url: `/${id}`,
          method: 'DELETE',
        };
      },
    }),
    getDiagnosticById: builder.query<Diagnostic, number>({
      query: (id) => `/${id}`,
    }),
    getDiagnostic: builder.query<Diagnostic[], void>({
      query: (_) => ``,
    }),
    getDiagnosticScheduled: builder.query<AppointmentResponse[], number>({
      query: (id) => `${id}/scheduled`,
    }),
  }),
});

export const {
  useCreateDiagnosticMutation,
  useUpdateDiagnosticMutation,
  useGetDiagnosticByIdQuery,
  useGetDiagnosticQuery,
  useLazyGetDiagnosticQuery,
  useDeleteDiagnosticMutation,
  useGetDiagnosticScheduledQuery,
  useLazyGetDiagnosticScheduledQuery
} = diagnosticApi;