import diagnostic from './diagnostic.svg';
import editPatient from './edit-patient.svg';
import history from './history.svg';
import newRequest from './new-request.svg';
import treatment from './treatment.svg';

export const diagnosticCard = diagnostic;
export const editPatientCard = editPatient;
export const historyCard = history;
export const newRequestCard = newRequest;
export const treatmentCard = treatment;