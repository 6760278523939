import { useEffect, useState } from 'react';
import { CrudBehaviorRouteProps } from '../../../../components/crud-behavior/crud-behavior';
import {
  DialogProps,
  DialogVariantType
} from '../../../../components/dialog/dialog';
import { isQueryCompleted } from '../../../../helpers/helpers';
import { Patient } from '../../../../interfaces/patient';
import { useGetDiagnosticByIdQuery } from '../../../../services/diagnostic/diagnostic-service';
import { useUpdatePatientMutation } from '../../../../services/patient/patient-service';
import { DiagnosticModel } from '../../model/diagnostic-model';
import DiagnosticFormView from '../diagnostic-form-view/diagnostic-form-view';

function DiagnosticEditFormContainer(props: CrudBehaviorRouteProps) {
  const [
    updateApi,
    {
      isLoading: updateIsLoading,
      isError: updateIsError,
      isSuccess: updateIsSuccess,
      status: updateStatus,
    },
  ] = useUpdatePatientMutation();

  const {
    data: fetchData,
    isLoading: fetchIsLoading,
    isError: fetchIsError,
  } = useGetDiagnosticByIdQuery(Number(props.match.params.id));

  const [dialogProps, setDialogProps] = useState<DialogProps>({ open: false });
  const [model, setModel] = useState<DiagnosticModel | undefined>(undefined);

  const handleSubmit = (formObj: Patient) => {
    updateApi(formObj);
  };

  useEffect(() => {
    if (!isQueryCompleted(updateStatus)) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: updateIsSuccess ? 'success' : ('error' as DialogVariantType),
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [updateIsSuccess, updateIsError, updateStatus]);

  useEffect(() => {
    if (!fetchIsError) return;

    const dialogProps: DialogProps = {
      open: true,
      variant: 'error',
      onClose: () => {
        setDialogProps((prev) => ({ ...prev, open: false }));
      },
    };

    setDialogProps(dialogProps);
  }, [fetchIsError]);

  useEffect(() => {
    if (fetchData) setModel(new DiagnosticModel(fetchData));
  }, [fetchData]);

  return (
    <DiagnosticFormView
      isLoading={updateIsLoading || fetchIsLoading}
      dialogProps={dialogProps}
      title={'Editar Diagnóstico'}
      model={model}
      handleSubmit={handleSubmit}
      backPagePath={`patient/${props.match.params['patientId']}/treatments`}
    />
  );
}

export default DiagnosticEditFormContainer;
