import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserRole } from '../../interfaces/user';

export type OnboardingStep =
  | 'profile'
  | 'tellUs'
  | 'completeProfile'
  | 'confirmationCode'
  | 'finished';

export interface OnboardingNameAndEmail {
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
}

interface OnboardingState extends Partial<User> {
  profile: UserRole;
  step: OnboardingStep;
}

// Define the initial state using that type
const initialState: OnboardingState = {
  step: 'profile',
  profile: 'clinic',
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<OnboardingStep>) => {
      state.step = action.payload;
    },
    setProfile: (state, action: PayloadAction<UserRole>) => {
      state.profile = action.payload;
    },
    setNameAndEmail: (
      state,
      action: PayloadAction<OnboardingNameAndEmail>
    ) => {
      state.firstName = action.payload.firstName;
      if(action.payload?.lastName){
        state.lastName = action.payload?.lastName;
      }
      state.email = action.payload.email
      state.password = action.payload.password
      console.log(action.payload)

    },
    setDocument: (state, action: PayloadAction<string>) => {
      state.document = action.payload;
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    clearOnboarding: (state) => {
      state = initialState;
    },
  },
});

export const {
  setProfile,
  setStep,
  setNameAndEmail,
  setAddress,
  setDocument,
  clearOnboarding
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
