import { useEffect } from 'react';
import { orderByModalityThenByRequestDate } from '../helpers/helpers';
import { useAuth } from '../hooks';
import { AppointmentEnumTypes } from '../interfaces/options';
import { useGetClinicAppointmentsQuery } from '../services/clinic/clinic-service';
import { useGetPendingConsultQuery } from '../services/consult/consult-service';
import { UserProfile } from '../store/auth';

export function useGetAppointments(
  clinicId: number,
  scheduled: boolean,
  pollingInterval: number
) {
  const [auth] = useAuth()
  const {
    data: fowardConsultData,
    isLoading: fowardConsultIsLoading,
    isError: forwardConsultIsError,
    refetch: fowardConsultRefetch,
  } = useGetClinicAppointmentsQuery({
    clinicId: clinicId,
    scheduled: scheduled,
  });

  const getAppointmentTypesByProfile = () => {
    if (auth.profile === UserProfile.ADMIN) {
      return [
        AppointmentEnumTypes.CONSULT,
        AppointmentEnumTypes.FOWARD,
        AppointmentEnumTypes.EXAM,
      ];
    } else if (auth.profile === UserProfile.LAB) {
      return [AppointmentEnumTypes.EXAM];
    } else {
      return [AppointmentEnumTypes.CONSULT];
    }
  };

  const {
    data: consultData,
    isLoading: consultIsLoading,
    isError: consultIsError,
    refetch: consultRefetch,
  } = useGetPendingConsultQuery({
    appointmentTypes: getAppointmentTypesByProfile(),
  });

  const mergeData = () => {
    const consult = consultData ?? [];
    const fowardConsult = fowardConsultData ?? [];
    const merge = [...consult, ...fowardConsult];
    return orderByModalityThenByRequestDate(merge);
  };

  const refetch = () => {
    fowardConsultRefetch();
    consultRefetch();
  };

  useEffect(() => {
    setInterval(() => {
      refetch();
    }, pollingInterval);
  }, []);

  return {
    isLoading: fowardConsultIsLoading || consultIsLoading,
    isError: forwardConsultIsError || consultIsError,
    data: mergeData(),
    refetch,
  };
}
