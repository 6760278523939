import { TextField } from '@mui/material';
import { Control, Controller, FieldValues } from 'react-hook-form';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { getDateFromISOorDate } from '../helpers/date';

export interface FormDateTimePickerProps {
  control: Control<FieldValues, object> | undefined;
  name: string;
  label: string;
  error: boolean;
  helperText: string;
  disabled?: boolean;
}

export default function FormDateTimePicker(props: FormDateTimePickerProps) {
  return (
    <Controller
      control={props.control}
      name={`${props.name}` as const}
      defaultValue={''}
      render={({ field: { onChange, value } }) => {
        const formattedValue: Date = getDateFromISOorDate(value);
        return (
          <DateTimePicker
            label={props.label}
            value={formattedValue}
            onChange={onChange}
            ampm={false}
            inputFormat='dd/MM/yyyy HH:mm'
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  value={value}
                  error={props.error}
                  helperText={props.helperText}
                  disabled={props.disabled}
                  margin='normal'
                  variant='outlined'
                  id={`id-${props.name}`}
                />
              );
            }}
          />
        );
      }}
    />
  );
}
