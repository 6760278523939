import {
  configureStore,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import { onboardingSlice } from './pages/onboarding/onboarding-slice';
import { clinicApi } from './services/clinic/clinic-service';
import { cognitoApi } from './services/cognito/cognito-service';
import { consultApi } from './services/consult/consult-service';
import { diagnosticApi } from './services/diagnostic/diagnostic-service';
import { doctorApi } from './services/doctor/doctor-service';
import { laboratoryApi } from './services/laboratory/laboratory-service';
import { optionsApi } from './services/options/options-service';
import { patientApi } from './services/patient/patient-service';
import { treatmentApi } from './services/treatment/treatment-service';
import { userApi } from './services/user/user-service';
import { appointmentSlice } from './store/appointment';
import { authSlice } from './store/auth';
import { dashboardSlice } from './store/dashboard';
import { passwordSlice } from './store/password';

export const store = configureStore({
  reducer: {
    onboarding: onboardingSlice.reducer,
    auth: authSlice.reducer,
    password: passwordSlice.reducer,
    appointment: appointmentSlice.reducer,
    dashboard: dashboardSlice.reducer,
    [cognitoApi.reducerPath]: cognitoApi.reducer,
    [patientApi.reducerPath]: patientApi.reducer,
    [doctorApi.reducerPath]: doctorApi.reducer,
    [optionsApi.reducerPath]: optionsApi.reducer,
    [consultApi.reducerPath]: consultApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [clinicApi.reducerPath]: clinicApi.reducer,
    [laboratoryApi.reducerPath]: laboratoryApi.reducer,
    [treatmentApi.reducerPath]: treatmentApi.reducer,
    [diagnosticApi.reducerPath]: diagnosticApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      cognitoApi.middleware,
      patientApi.middleware,
      doctorApi.middleware,
      optionsApi.middleware,
      consultApi.middleware,
      userApi.middleware,
      clinicApi.middleware,
      diagnosticApi.middleware
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
