
import { GridColumns } from '@mui/x-data-grid';
import { Breadcrumbs } from '../../../../components/breadcrumbs';
import { CrudDataTable } from '../../../../components/crud-data-table';
import Dialog from '../../../../components/dialog/dialog';
import { formattedDateTime } from '../../../../helpers/helpers';
import { CrudPageView } from '../../../../interfaces/crud';

const columns: GridColumns = [
  {
    field: 'cid',
    headerName: 'Cid',
    flex: 1,
    renderCell: ({row}) => row.cid.description,
  },
  {
    field: 'date',
    headerName: 'Data',
    flex: 1,
    renderCell: ({ row }) => formattedDateTime(row.date)
  },
];

export default function DiagnosticCrudPageView({
  isLoading,
  data,
  onDeleteIconClick,
  onEditIconClick,
  onRowClick,
  showDialog,
  dialogVariant,
  onCloseDialog,
  customActions,
  showActions,
  hideAddBtn,
  title,
  backPagePath
}: CrudPageView<{}> & { backPagePath: string }) {
  return (
    <>
      <Dialog
        open={showDialog}
        variant={dialogVariant}
        onClose={onCloseDialog}
      />
            <Breadcrumbs
        items={[
          {
            label: 'Voltar',
            link: backPagePath,
          },
        ]}
      />
      <CrudDataTable
        title={title ?? 'Diagnósticos'}
        data={data}
        isLoading={isLoading}
        columns={columns}
        pageSize={15}
        handleRowClick={onRowClick}
        handleDeleteIconClick={onDeleteIconClick}
        handleEditIconClick={onEditIconClick}
        showActions={showActions}
        customActions={customActions}
        hideAddBtn={hideAddBtn}
      />
    </>
  );
}
